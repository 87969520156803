/* usage:
    npm i ag-grid-react ag-grid-community
    in declaration.d.ts: declare module "ag-grid-react"
    handle gridReady to store api
    to save edits:
        api.getChanges(true) -- returns changed rows with rowState column
        api.acceptChanges() after successful 
    if not a lot of data grid can be used in controlled mode:
        pass gridChanged, store entire dataSource in state
        when using this way pass useRowState=false so rowState column will not be returned
    to use custom formatting:
        pass customFormatter to grid
        grid will call customFormatter on each column that has customFormatRequired set to true
    to suppress insert/delete context menu set global contextMenuEnabled=false
*/
import React from 'react';
import styled from 'styled-components';
import { AgGridReact } from "ag-grid-react";
import { CellClickedEvent, CellMouseDownEvent, CellValueChangedEvent, ColDef, GridReadyEvent, RowNode, ValueFormatterParams } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { FormFieldRecord, FormFieldType, RowState, StyleRecord } from '../../interfaces/lib-api-interfaces';
import { ButtonsRow } from '../IconButtonV2';
import ContextMenu, { ContextMenuInfo, ContextMenuItem } from '../SamContextMenu';

const isDebugging = false;
const contextMenuEnabled = true;

const mouseButtonRight = 2;

const GridContainer = styled.div<{ width: string, height: string }>`
    width: ${props => props.width};
    height: ${props => props.height};
    margin: auto;
`
export interface AgGridApi {
    // rowData column included if insert, delete or editing allowed
    // if changesOnly passed only rows with changed data are returned, in order of deleted then modified then added
    getData: (changesOnly?: boolean) => Record<string, any>[];
    acceptChanges: () => void;
}
export interface GridCellEvent {
    name: string;
    dataRow: Record<string, any>;
}
export interface AgGridProps {
    dataSource: Record<string, any>[];
    fields: FormFieldRecord[];
    width?: string;
    height?: string;
    styles?: StyleRecord;
    allowEditing?: boolean;     // default to false
    allowInsert?: boolean;      // default false
    allowDelete?: boolean;      // default false
    newRowData?: Record<string, any>;
    cellClicked?: (cell: GridCellEvent) => void;
    gridChanged?: (dataSource: Record<string, any>[]) => void;
    customFormatter?: (cell: GridCellEvent) => string;
    gridReady?: (api: AgGridApi) => void;       // called when grid is ready
}
const AgGrid: React.FC<AgGridProps> = (props) => {
    const [rowData, setRowData] = React.useState<Record<string, any>>(); // Set rowData to Array of Objects, one Object per 
    const [columnDefs, setColumnDefs] = React.useState<ColDef[]>();
    const [contextMenuInfo, setContextMenuInfo] = React.useState<ContextMenuInfo>();

    const gridRef = React.useRef<any>() as React.MutableRefObject<any>;

    const useRowState = props.allowEditing || props.allowInsert || props.allowDelete;

    /* valueFormatter params are:
        value (actual data value),
        data (dataRow),
        node (all info regarding the row including indexes, dataRow, etc.)
        colDef (ColDef object)
        column (like node but for column)
        api, columnApi (same as available from gridRef.current)
        context: as set on gridOptions.context
    */
    const valueFormatter = (params: ValueFormatterParams): string => {
        let value = params.value;
        if (props.customFormatter) {
            value = props.customFormatter({ name: params.colDef.field!, dataRow: params.data });
            console.log("valueFormatter calling customFormatter with dataRow:", params.data, "; returning:", value)
        }
        // console.log("valueFormatter:", params);
        return value;
    }

    /* cellRenderer params are:
        all valueFormatter params PLUS:
            valueFormatted: formatted version of value?
            eGridCell: innerHTML for cell
            eParentOfValue: appears to be same as eGridCell
            formatValue: a function
            getValue: function
            refreshCell: function
            setValue: function
    */
    const cellRenderer = (params: any): JSX.Element | undefined => {
        console.log("cellRenderer:", params)
        const columnDef = props.fields.find(def => def.name === params.colDef.field)!;
        if (params.value && columnDef.type === FormFieldType.image) {
            return (
                <img src={params.valueFormatted} />
            )
        } else if (columnDef.type === FormFieldType.icon) {
            return (
                <i className={columnDef.icon} style={{ fontSize: (columnDef.iconFontSize ?? 20) + "px" }} />
            )
        }
    }

    const convertFormFieldRecords = (fields: FormFieldRecord[]): ColDef[] => {
        const defs = fields.map(colDef => {
            let maxWidth = 500;
            let minWidth = 20;
            if (colDef.size?.widthPx) {
                maxWidth = minWidth = colDef.size.widthPx;
            }
            let cellDataType = "text";
            let type: string | undefined;
            if (colDef.type === FormFieldType.fixedPoint || colDef.type === FormFieldType.decimal) {
                cellDataType = "number";
                type = "rightAligned";
            } else if (colDef.type === FormFieldType.date) {
                cellDataType = "date";
            } else if (colDef.type === FormFieldType.checkbox) {
                cellDataType = "boolean";
            }
            return {
                field: colDef.name,
                headerName: colDef["caption" as keyof FormFieldRecord] ?? colDef.label,
                editable: props.allowEditing,
                maxWidth,
                minWidth,
                cellDataType,
                type,
                valueFormatter: colDef.customFormatRequired ? valueFormatter : undefined,
                cellRenderer: colDef.type === FormFieldType.image || colDef.type === FormFieldType.icon ? cellRenderer : undefined

            }
        }) as ColDef[];
        if (useRowState) {
            defs.push({ field: "rowState", hide: true });
        }
        return defs;
    }

    React.useEffect(() => {
        setColumnDefs(convertFormFieldRecords(props.fields));
        if (useRowState) {
            setRowData(props.dataSource.map((dataRow, index) => {
                const newRow: Record<string, any> = { ...dataRow, rowState: RowState.unchanged };
                return newRow;
            }));
        } else {
            setRowData(props.dataSource);
        }
    }, []);

    const getData = (changedOnly?: boolean): Record<string, any>[] => {
        const data: Record<string, any>[] = [];
        if (changedOnly && useRowState) {
            collectData(data, RowState.deleted);
            collectData(data, RowState.modified);
            collectData(data, RowState.added);
        } else {
            collectData(data);
        }
        return data;
    }
    // omit rowState to collect all rows -- rowState column will be omitted (if it exists)
    const collectData = (data: Record<string, any>[], rowState?: RowState) => {
        gridRef.current.api.forEachNode((rowNode: RowNode, index: number) => {
            if (rowState && (rowNode.data.rowState & rowState) !== 0) {
                data.push(rowNode.data);
            } else if (!rowState) {
                const row = { ...rowNode.data };
                delete row.rowState;
                data.push(row);
            }
        });
    }
    const acceptChanges = () => {
        const newData: Record<string, any>[] = [];
        gridRef.current.api.forEachNode((rowNode: RowNode, index: number) => {
            if ((rowNode.data.rowState & RowState.deleted) === 0) {
                newData.push({ ...rowNode.data, rowState: RowState.unchanged });
            }
        });
        gridRef.current.api.setRowData(newData);
    }
    // following is for passing to parent in controlled mode; it is passed in gridChanged event
    const getUndeletedDataWithoutRowState = (): Record<string, any>[] => {
        const data: Record<string, any>[] = [];
        gridRef.current.api.forEachNode((rowNode: RowNode, index: number) => {
            if ((rowNode.data.rowState & RowState.deleted) === 0) {
                const row = { ...rowNode.data };
                delete row.rowState;
                data.push(row);
            }
        });
        return data;
    }

    // Each Column Definition results in one Column.
    // DefaultColDef sets props common to all Columns
    const defaultColDef = () => ({
        sortable: true
    });

    const gridReady = (e: GridReadyEvent) => {
        gridRef.current.columnApi.autoSizeAllColumns();
        if (props.gridReady) {
            props.gridReady({ getData, acceptChanges });
        }
    }

    const cellClicked = (e: CellClickedEvent) => {
        //    console.log("cellClicked:", e)
        if (props.cellClicked) {
            props.cellClicked({ name: e.colDef as string, dataRow: e.data as Record<string, any> });
        }
    }

    const cellValueChanged = (e: CellValueChangedEvent) => {
        // if row was previously added do not set to modified or it will be selected twice when data saved
        if ((e.data.rowState & RowState.added) === 0) {
            e.data.rowState = e.data.rowState | RowState.modified;
        }
        if (props.gridChanged) {
            props.gridChanged(getUndeletedDataWithoutRowState());
        }
    }

    /*
    export class ContextMenuItem {
        caption: string;
        onClick: (userData: any) => void;
        userData?: any;
        constructor(caption: string, onClick: (userData: any) => void, userData?: any) {
            this.caption = caption;
            this.onClick = onClick;       // userData is passed to callback
            this.userData = userData;
        }
    }
    export class ContextMenuInfo {
        location: { x: number; y: number };
        menu: ContextMenuItem[];
        constructor(location: { x: number; y: number }, menu: ContextMenuItem[]) {
            this.location = location;
            this.menu = menu;
        }
    }
    */
    const cellMouseDown = (e: CellMouseDownEvent) => {
        console.log("mouse down:", e);

        if ((e.event as MouseEvent).button === mouseButtonRight && contextMenuEnabled) {
            (e.event as MouseEvent).preventDefault();
            const contextMenu = [];
            if (props.allowInsert) {
                contextMenu.push(new ContextMenuItem("Insert new row", handleInsertRow, e.node.rowIndex));
            }
            if (props.allowDelete) {
                contextMenu.push(new ContextMenuItem("Delete this row", handleDeleteRow, e.node.data));
            }
            if (isDebugging) {
                contextMenu.push(new ContextMenuItem("Log data", () => console.log(e.data), null));
            }
            if (contextMenu.length) {
                setContextMenuInfo(new ContextMenuInfo({ x: (e.event as MouseEvent).clientX, y: (e.event as MouseEvent).clientY }, contextMenu));
            }
        }

    }

    // insert row at given index
    const handleInsertRow = (addIndex: number) => {
        const newRow = props.newRowData ?? {};
        newRow.rowState = RowState.added;
        gridRef.current.api.applyTransaction({ add: [newRow], addIndex });
        if (props.gridChanged) {
            props.gridChanged(getUndeletedDataWithoutRowState());
        }
        setContextMenuInfo(undefined);
    }
    // delete row with given data
    const handleDeleteRow = (data: Record<string, any>) => {
        data.rowState = (data.rowState ?? 0) | RowState.deleted;
        gridRef.current.api.onFilterChanged();
        if (props.gridChanged) {
            props.gridChanged(getUndeletedDataWithoutRowState());
        }
        setContextMenuInfo(undefined);
    }
    const isRowVisible = (node: RowNode): boolean => {
        return ((node.data.rowState ?? 0) & RowState.deleted) === 0;
    }
    // On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size
    //      Example using Grid's API 
    // <button onClick={buttonListener}>Push Me</button>

    return (
        <GridContainer className="ag-theme-alpine" width={props.width ?? "100%"} height={props.height ?? "600px"}>
            <ButtonsRow>
            </ButtonsRow>
            {rowData && columnDefs &&
                <AgGridReact
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={rowData} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    rowSelection='single' // Options - allows click selection of rows
                    onGridReady={gridReady}
                    onCellClicked={cellClicked}
                    pagination={true}
                    onCellValueChanged={cellValueChanged}
                    preventDefaultOnContextMenu={contextMenuEnabled}
                    isExternalFilterPresent={() => true}
                    doesExternalFilterPass={isRowVisible}
                    onCellMouseDown={props.allowInsert || props.allowDelete ? cellMouseDown : undefined}
                />
            }
            {contextMenuInfo && <ContextMenu info={contextMenuInfo} closePopup={() => setContextMenuInfo(undefined)} />}
        </GridContainer >
    )
}
export default AgGrid;