import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { getNearestParentId, iPadMinWidth, mergeOptionsRecords } from './libSupport';

import { MenuItemData } from '../interfaces/lib-api-interfaces';

import app from '../appData';
import { useSessionStore } from './SamState';
import { NavbarStylesRecord } from '../interfaces/lib-react-interfaces';

const smallNavFontWidth = 750;

export const hideNavBarKey = "hideNavBar";

const NavBarFullWidthContainer = styled.div<{ $height: number; $backColor: string; $backgroundImage?: string }>`
    width: 100%;
    display: flex;
    justify-content: center;
    height: ${props => props.$height}px;
    background-color: ${props => props.$backColor};
    background-image: ${props => props.$backgroundImage};
    background-size: ${props => props.$backgroundImage ? "cover" : null};
`
const NavBarBanner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const NavListContainer = styled.div<{ $hoverStyles: Record<string, string> }>`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-right: 8px;
    a {
        outline: none !important;
    }
    a:hover {
        text-decoration: unset !important;
        font-style: unset !important;
    }
    div:hover {
        ${props => props.$hoverStyles};
    }
`
const CaptionText = styled.div<{ $captionStyles: Record<string, string> }>`
    ${props => props.$captionStyles};
`

const defaultStyles: NavbarStylesRecord = {
    bannerStyles: {
        width: "100%",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        fontFamily: app.themes.sansFonts ?? '',
        fontSize: "16px",
        lineHeight: "18px",
        color: app.themes.backColor10 ?? '',            // reversed from normal text (e.g., light green on dark green)
        backgroundColor: app.themes["color"] ?? '',
        fontWeight: "bold"
    },
    bannerHoverStyles: {
        color: app.themes["color"] ?? '',            // normal text (e.g., dark green on light green)
        backgroundColor: app.themes.backColor10 ?? ''
    },
    popupStyles: {
        fontSize: "13px",
        lineHeight: "32px",
        color: app.themes.color ?? "black",
        backgroundColor: app.themes.backColor25 ?? "white",
        fontFamily: app.themes.sansFonts ?? '',
        zIndex: "3000",
        visibility: "hidden"
    }, 
    popupHoverStyles: {
        backgroundColor: app.themes.color ?? "black",
        color: app.themes.backColor25 ?? "white"
    }
}

// note that styling is additive; start with default styles, then add app.themes if found, then add props.navbarStyles if passed
interface NavBarProps {
    menuItems: MenuItemData[];
    navbarStyles?: NavbarStylesRecord;      // styles are taken from here; if not given, app.thems.navbarStyles is used; anything still missing is taken from defaultStyles
    isVisibleCallback?: (item: MenuItemData) => boolean;
}
const NavBar: React.FC<NavBarProps> = (props) => {
    const navbarStyles = mergeOptionsRecords([defaultStyles, app.themes.navbarStyles, props.navbarStyles]) as NavbarStylesRecord;

    const { renderPopups, showPopup, hidePopup } 
        = usePopups({ menu: props.menuItems, styles: navbarStyles.popupStyles!, hoverStyles: navbarStyles.popupHoverStyles!, isVisibleCallback: props.isVisibleCallback });

    if (window.matchMedia("(max-width: " + iPadMinWidth + "px)").matches) {
        navbarStyles.bannerStyles!.fontSize = "14px";
        navbarStyles.bannerStyles!.lineHeight = "24px";
    }

    const mouseEnter = (caption: string) => {
        showPopup(caption);
    }
    const mouseLeave = (caption: string) => {
        hidePopup(caption);
    }

    function parseStylePx(value: string): number {
        return parseInt(value.replace("px", ''));
    }
    
    return (
        <NavBarFullWidthContainer $height={parseStylePx(navbarStyles.bannerStyles!.lineHeight!) * 2} $backColor={navbarStyles.bannerStyles!.backgroundColor!}
            $backgroundImage={navbarStyles.bannerStyles!.backgroundImage}>
            <NavBarBanner style={navbarStyles.bannerStyles}>
                {props.menuItems.map(entry => {
                    return (!props.isVisibleCallback || props.isVisibleCallback(entry)) &&
                        <NavListContainer id={entry.caption} $hoverStyles={navbarStyles.bannerHoverStyles!}
                            onMouseEnter={() => mouseEnter(entry.caption)} onMouseLeave={() => mouseLeave(entry.caption)}>
                            <a href={entry.href}>
                                <CaptionText $captionStyles={navbarStyles.bannerStyles!}>
                                    {entry.caption}
                                </CaptionText>
                            </a>
                        </NavListContainer>
                })}
            </NavBarBanner>
            {renderPopups()}
        </NavBarFullWidthContainer>
    )
}
//----------------------------------------------------------------
const PopupListContainer = styled.div<{ $top: number; $left: number; $styles: Record<string, string>; $hoverStyles: Record<string, string> }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: ${props => props.$top}px;
    left: ${props => props.$left}px;
    border-left: 2px solid;
    border-right: 2px solid;
    ${props => props.$styles};
    a {
        outline: none !important;
        width: 100%;
    }
    a:hover {
        text-decoration: unset !important;
        font-style: unset !important;
    }
   div {
        border-bottom: 2px solid;
        padding-left: 8px;
        padding-right: 8px;
        text-align: left;
    }
    div:hover {
        ${props => props.$hoverStyles};
    }
`
interface UsePopupProps {
    menu: MenuItemData[];
    styles: Record<string, string>;
    hoverStyles: Record<string, string>;
    isVisibleCallback?: (item: MenuItemData) => boolean;
}
const usePopups = (props: UsePopupProps) => {
    const { getSessionStore, setSessionStore } = useSessionStore();
    // initial render of popup -- hidden and at upper left; will be shown and moved into position upon hover over main nav item
    const renderPopups = (): any => {
        return (
            props.menu.map(entry => {
                return (
                    entry.popupItems && (!props.isVisibleCallback || props.isVisibleCallback(entry)) &&
                    <PopupListContainer id={entry.caption + "popup"} $top={0} $left={0} $styles={props.styles} $hoverStyles={props.hoverStyles}
                        onMouseLeave={() => hidePopup(entry.caption)}>
                        {entry.popupItems!.map(popupEntry => {
                            return (!props.isVisibleCallback || props.isVisibleCallback(popupEntry)) &&
                                <a key={popupEntry.caption} href={popupEntry.href}>
                                    <CaptionText $captionStyles={{ ...props.styles, width: "100%" }}>
                                        {popupEntry.caption}
                                    </CaptionText>
                                </a>
                        })}
                    </PopupListContainer>
                )
            })
        )
    }

    const iterateSetVisibility = (popup: HTMLElement, setVisible: boolean) => {
        const value = setVisible ? "visible" : "hidden";
        popup.style.visibility = value;
        const iterator = document.createNodeIterator(popup!);
        while (true) {
            const node = iterator.nextNode();
            if (!node) {
                break;
            }
            const elem = node as HTMLDivElement;
            if ("style" in elem) {
                elem.style.visibility = value;
            }
        }
    }

    const showPopup = (caption: string) => {
        const navElem = document.getElementById(caption);
        if (!navElem) {
            throw "showPopup: nav item not found: " + caption;
        }
        const navRect = navElem.getBoundingClientRect();
        const popupElem = document.getElementById(caption + "popup");
        if (!popupElem) {
            return;
        }
        const popupRect = popupElem.getBoundingClientRect();
        const top = navRect.y + navRect.height + 4;
        let left = navRect.x;
        if (left + popupRect.width > window.innerWidth - 15) {
            left = window.innerWidth - popupRect.width - 15;
        }
        popupElem.style.left = left + "px";
        popupElem.style.top = top + "px";
        iterateSetVisibility(popupElem, true);
        window.addEventListener('mousemove', mouseTracker);
    }

    const isMouseInsideRectangle = (mousePosn: {x: number; y: number}, rect: DOMRect): boolean => {
        return mousePosn.x >= rect.x && mousePosn.x < rect.x + rect.width && mousePosn.y >= rect.y && mousePosn.y <= rect.y + rect.height;
    }
    const hidePopup = (caption: string) => {
        const popupElem = document.getElementById(caption + "popup");
        if (!popupElem) {
            return;
        }
        if (popupElem.style.visibility === "visible") {
            const popupRect = popupElem.getBoundingClientRect();
            setTimeout(() => {
                const mousePosn = getSessionStore("mouse");
                if (!isMouseInsideRectangle(mousePosn, popupRect)) {
                    // cursor has not moved  inside of popup so we can take it down
                    window.removeEventListener("mousemove", mouseTracker);
                    iterateSetVisibility(popupElem, false);
                }
            }, 150);
        }
    }

    function mouseTracker(e: any) {
        setSessionStore("mouse", { x: e.clientX, y: e.clientY });
    }

    return {
        renderPopups,
        showPopup,
        hidePopup
    }
}
//----------------------------------------------------
/*
export interface NavbarStylesRecord {
    bannerStyles?: Record<string, string>;
    popupStyles?: Record<string, string>;
    bannerHoverStyles?: Record<string, string>;
    popupHoverStyles?: Record<string, string>;
    [key: string]: any;
}
*/

//---------------------------------------------------------------
// parse menu and popup indexes out of event.target.id
// is is coded "bhhvvyy" where hh is horizontal menu item (or button) and (optional) vv is dropdown1 item (or menu item) and (optional) yy is dropdown2 item, all with leading 0
//      e.g.: "File->Save File->Cancel" -- File->Save is b0000 and File->cancel is b0001
// returns ParsedIndexesRecord (value -1 for undefined)
interface ButtonIndexesRecord {
    target: HTMLDivElement;
    mainIndex: number;
    sub1Index: number;
    sub2Index: number;
    timerId?: NodeJS.Timeout;
}

const menuItemLineHeight = 20;
const menuItemPadding = 7;
const SlidingMasterContainer = styled.div`
    position: relative;
`
const MenuSlider = styled.div<{ $zIndex: number }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 100vw;
    z-index: ${props => props.$zIndex};
    width: 75vw;
    border: 1px solid;
    position: fixed;
    margin: 0;
    padding: 0;
`
const SliderContainer = styled.div<{ $fontFamily: string; $backColor: string; $hoverForeColor: string; $hoverBackColor: string }>`
    font: 18px ${props => props.$fontFamily};
    background-color: ${props => props.$backColor};
    div: hover {
        color: ${props => props.$hoverForeColor};
        background-color: ${props => props.$hoverBackColor};
    }
`
const MenuItem = styled.div<{ $fontWeight: string; $justify: string; $foreColor: string; $backColor: string }>`
    display: flex;
    justify-content: ${props => props.$justify};     /* space-between or flex-start */
    align-items: center;
    font-weight: ${props => props.$fontWeight};
    color: ${props => props.$foreColor};
    background-color: ${props => props.$backColor};
    cursor: pointer;
    border-bottom: 1px solid;
    line-height: ${menuItemLineHeight}px;
    padding: ${menuItemPadding}px;
`
const slidein = keyframes`
    from {
      left: 100vw;
    }
    to {
      left: 25vw;
    }
`
const slideout = keyframes`
    from {
      left: 25vw;
    }
    to {
      left: 100vw;
    }
`
interface SlidingNavBarProps extends NavBarProps {
    socialMedia?: React.FC<{ justify?: string; backColor?: string; padding: number; lineHeight: number }>;
    vouchersLink?: string;
    navbarStyles?: NavbarStylesRecord;      // styles are taken from here; if not given, app.thems.navbarStyles is used; anything still missing is taken from defaultStyles
}
export const SlidingNavBar: React.FC<SlidingNavBarProps> = (props) => {
    const [menuTop, setMenuTop] = React.useState<number>();
    const [menusActive, setMenusActive] = React.useState(false);
    const [mainMenuOpen, setMainMenuOpen] = React.useState(false);
    const [subMenuOpen, setSubMenuOpen] = React.useState(-1);     // index of dropdown menu that is open
    const [subPopupOpen, setSubPopupOpen] = React.useState(-1);

    const navbarRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const subMenuRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    const navigate = useNavigate();

    const navbarStyles = mergeOptionsRecords([defaultStyles, app.themes.navbarStyles, props.navbarStyles]) as NavbarStylesRecord;

    React.useEffect(() => {
        const submenu = subMenuRef.current! as HTMLDivElement;
        if (submenu && subMenuOpen >= 0) {
            submenu.classList.add("slideIn");
            submenu.onanimationend = () => {
                submenu.classList.remove("slideIn");
                submenu.style.left = "25vw";
                navbarRef.current!.style.opacity = "0";
            };
        }
    }, [subMenuOpen]);

    React.useEffect(() => {
        if (mainMenuOpen) {
            const navbar = navbarRef.current as HTMLDivElement;
            navbar.style.opacity = "1";
            const className = mainMenuOpen ? "slideIn" : "slideOut";
            navbar.classList.add(className);
            navbar.onanimationend = () => {
                navbar.classList.remove(className);
                navbar.style.left = mainMenuOpen ? "25vw" : "100vw";
            };
        }
    }, [mainMenuOpen]);

    const parseIndexes = (target: HTMLDivElement): ButtonIndexesRecord => {
        target = getNearestParentId(target) as HTMLDivElement;
        return {
            target,
            mainIndex: parseInt(target.id.substring(1, 3)),
            sub1Index: target.id.length > 3 ? parseInt(target.id.substring(3, 5)) : -1,
            sub2Index: target.id.length > 5 ? parseInt(target.id.substring(6, 8)) : -1
        };
    }

    const showMenuClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLButtonElement;
        const top = target.getBoundingClientRect().y + 30;
        setMenuTop(top);
        setMainMenuOpen(!mainMenuOpen);
        setMenusActive(!menusActive);
    }
    const menuItemClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const { target, mainIndex, sub1Index, sub2Index } = parseIndexes(e.target as HTMLDivElement);
        //    console.log("indexes:", { target, mainIndex, sub1Index, sub2Index })
        let href;
        if (props.menuItems[mainIndex].popupItems) {
            setSubMenuOpen(mainIndex);
        } else {
            href = props.menuItems[mainIndex].href;
        }
        if (href) {
            setMainMenuOpen(false);
            setMenusActive(false);
            if (href.startsWith("http")) {
                window.open(href, '_blank');
            } else {
                navigate(href);
            }
        }
    }

    const submenuItemClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const indexes = parseIndexes(e.target as HTMLDivElement);
        const { target, mainIndex, sub1Index, sub2Index } = indexes;
        // see if it has another level; if so toggle the view of that
        if (sub2Index === -1 && props.menuItems[mainIndex].popupItems![sub1Index].popupItems && props.menuItems[mainIndex].popupItems![sub1Index].popupItems!.length) {
            if (subPopupOpen === sub1Index) {
                // already open so toggle closed
                setSubPopupOpen(-1);
            } else {
                setSubPopupOpen(sub1Index);
            }
        } else {
            const menuItem = sub2Index === -1 ? props.menuItems[mainIndex].popupItems![sub1Index] : props.menuItems[mainIndex].popupItems![sub1Index].popupItems![sub2Index];
            const href = menuItem.href as string;
            setMainMenuOpen(false);
            setSubMenuOpen(-1);
            setMenusActive(false);
            if (href) {
                if (href.startsWith("http")) {
                    window.open(href, '_blank');
                } else {
                    navigate(href);
                }
            }
        }
    }
    const closeSubMenuClicked = () => {
        navbarRef.current!.style.opacity = "1";
        const submenu = subMenuRef.current! as HTMLDivElement;
        submenu.classList.add("slideOut");
        submenu.onanimationend = () => {
            setSubMenuOpen(-1);
        };
    }

    return (
        <SlidingMasterContainer>
            <i style={{ fontSize: "24px", marginLeft: "8px" }} className="fas fa-bars" onClick={showMenuClicked} />
            {menusActive &&
                <React.Fragment>
                    <MenuSlider ref={navbarRef} $zIndex={5000}>
                        <SliderContainer $fontFamily={app.themes.sansFonts ?? ''}
                            $backColor={navbarStyles.bannerStyles!.backgroundColor}
                            $hoverForeColor={navbarStyles.bannerHoverStyles!.color} $hoverBackColor={navbarStyles.bannerHoverStyles!.backgroundColor}>
                            <React.Fragment>
                                {props.menuItems.map((item, menuIndex) => {
                                    return (!props.isVisibleCallback || props.isVisibleCallback(item)) &&
                                        (
                                            <React.Fragment key={item.caption}>
                                                <MenuItem $fontWeight="bold" id={formatNavButtonId(menuIndex)}
                                                    $foreColor={navbarStyles.bannerStyles!.color} $backColor={navbarStyles.bannerStyles!.backgroundColor}
                                                    $justify="space-between"
                                                    onClick={menuItemClicked}>
                                                    <span>{item.caption}</span>
                                                    {item.popupItems && <i style={{ marginRight: "16px" }} className="fas fa-chevron-right"></i>}
                                                </MenuItem>
                                            </React.Fragment>
                                        )
                                })}
                                {props.vouchersLink &&
                                    <MenuItem $fontWeight="regular" $foreColor={navbarStyles.bannerStyles!.color} $backColor={navbarStyles.bannerStyles!.backgroundColor}
                                        $justify="space-between"
                                        onClick={() => navigate(props.vouchersLink!)}>
                                        <span>Gift Certificates...</span>
                                    </MenuItem>
                                }
                            </React.Fragment>
                        </SliderContainer>
                        {props.socialMedia &&
                            <props.socialMedia justify="center" backColor={navbarStyles.bannerStyles!.backgroundColor} lineHeight={menuItemLineHeight} padding={menuItemPadding} />
                        }
                    </MenuSlider>
                    {subMenuOpen >= 0 &&
                        <MenuSlider ref={subMenuRef} $zIndex={6000}>
                            <SliderContainer $fontFamily={app.themes.sansFonts ?? ''}
                                $backColor={navbarStyles.bannerStyles!.backgroundColor}
                                $hoverForeColor={navbarStyles.bannerHoverStyles!.color} $hoverBackColor={navbarStyles.bannerHoverStyles!.backgroundColor}>
                                <MenuItem $fontWeight="bold" $foreColor={navbarStyles.bannerStyles!.color} $backColor={navbarStyles.bannerStyles!.backgroundColor}
                                    $justify="flex-start" onClick={closeSubMenuClicked}>
                                    <i className="fas fa-chevron-left"></i>
                                    <span style={{ marginLeft: "16px" }}>{props.menuItems[subMenuOpen].caption}</span>
                                </MenuItem>
                                <ListDropdownItems dropdownItems={props.menuItems[subMenuOpen].popupItems!} keyPrefix='' subPopupOpen={subPopupOpen}
                                    indentLevel={0} mainIndex={subMenuOpen} subMenuIndex={-1}
                                    foreColor={navbarStyles.popupStyles!.color} backColor={navbarStyles.popupStyles!.backgroundColor} itemClicked={submenuItemClicked} />
                            </SliderContainer>
                        </MenuSlider>
                    }
                </React.Fragment>
            }
        </SlidingMasterContainer>
    )
}
const AddLeadingZero = (index: number): string => {
    return index < 10 ? ("0" + index) : index + '';
}
// return "bxxyyzz" where yy and zz are optional
const formatNavButtonId = (mainIndex: number, sub1Index: number = -1, sub2Index: number = -1): string => {
    return "b" + AddLeadingZero(mainIndex) + (sub1Index === -1 ? '' : AddLeadingZero(sub1Index)) + (sub2Index === -1 ? '' : AddLeadingZero(sub2Index));
}

interface ListDropdownItemsProps {
    dropdownItems: MenuItemData[];
    mainIndex: number;
    subMenuIndex: number;
    subPopupOpen: number;       // -1 if none open
    keyPrefix: string;
    indentLevel: number;        // 0 or 1
    foreColor: string;
    backColor: string;
    itemClicked: (e: React.MouseEvent<HTMLDivElement>) => void;
}
const ListDropdownItems: React.FC<ListDropdownItemsProps> = (props) => {
    return (
        <React.Fragment>
            {props.dropdownItems.map((popup, popupIndex) => {
                const hasDropdown = popup.popupItems && popup.popupItems!.length;
                return (
                    <>
                        <MenuItem key={props.keyPrefix + popupIndex} id={formatNavButtonId(props.mainIndex, props.subMenuIndex, popupIndex)}
                            $foreColor={props.foreColor} $backColor={props.backColor}
                            $fontWeight="regular" $justify="flex-start" onClick={props.itemClicked}>
                            {props.indentLevel > 0 && <span>&nbsp;&nbsp;&nbsp;</span>}
                            <span>{popup.caption}</span>
                            {hasDropdown && <><span>&nbsp;</span><span>&#9660;</span></>}
                        </MenuItem>
                        {props.subPopupOpen === popupIndex &&
                            <ListDropdownItems dropdownItems={popup.popupItems!} keyPrefix="s" subPopupOpen={-1} indentLevel={1} subMenuIndex={popupIndex}
                                mainIndex={props.mainIndex} foreColor={props.foreColor} backColor={props.backColor} itemClicked={props.itemClicked} />
                        }
                    </>
                )
            })
            }
        </React.Fragment>
    )
}

export default NavBar;
