import React from 'react';
import styled from 'styled-components';

import SamModal from '../SamModalV2';
import IconButton, { ButtonsRow } from '../IconButtonV2';
import LoadFontsModal from '../FontLoader';
import PopupCombo from './PopupCombo';
import { FormFieldRecord, FormFieldType } from '../../interfaces/lib-api-interfaces';
import SamForm from './SamFormV5';
import FormMgr from './FormMgr';
import { useGlobalContext } from '../SamState';

// interface and map apply to standard (system) fonts (vs. google fonts)
export interface FontCalloutRecord {
    family: string;      // our shortcut, or google name
    callout?: string;      // if system font this is the css callout; if google font the callout if formatted from family and group
    fallback: string;      // serif/sans-serif/monospace/cursive
}

// note that family field must be the first font listed in callout; otherwise it will be treated as a Google font
const fontMap: FontCalloutRecord[] = [
    { family: "Helvetica Neue", callout: "Helvetica Neue, Helvetica, Arial", fallback: "sans-serif" },
    { family: "Arial", callout: "Arial, Helvetica Neue, Helvetica", fallback: "sans-serif" },
    { family: "Tahoma", callout: "Tahoma, Verdana, Segoe", fallback: "sans-serif" },
    { family: "Trebuchet MS", callout: "Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans", fallback: "sans-serif" },
    { family: "Verdana", callout: "Verdana, Geneva", fallback: "sans-serif" },
    { family: "Arial Black", callout: "Arial Black", fallback: "sans-serif" },
    { family: "Impact", callout: "Impact", fallback: "sans-serif" },
    { family: "Times", callout: "Times, Times New Roman, Georgia", fallback: "serif" },
    { family: "Georgia", callout: "Georgia, Times, Times New Roman", fallback: "serif" },
    { family: "Garamond", callout: "Garamond, Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman", fallback: "serif" },
    { family: "Palatino", callout: "Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia", fallback: "serif" },
    { family: "Courier New", callout: "Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter", fallback: "monospace" },
    { family: "Andale Mono", callout: "Andale Mono, Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter", fallback: "monospace" },
    { family: "Brush Script MT", callout: "Brush Script MT", fallback: "cursive" }
];

// following 2 are used with forms which require a string value
// value is comma delimited list with first string the family, last string the fallback
// NOTE: for system fonts string can be just the font name and it will be converted; for google fonts the fallback must be included
export const styleValueToCalloutRecord = (value: string): FontCalloutRecord => {
    const parts = value.split(',');
    const record = fontMap.find(rec => rec.family === parts[0]);
    const result = (record ?? { family: parts[0], fallback: parts[parts.length - 1].trim() });
 //   console.log("styleValueToCalloutRecord: " + value + " --> ", result);
    return result;
}
export const calloutRecordToFontString = (record: FontCalloutRecord): string => {
    return record.family + ", " + record.fallback;
}
// convert any font family string to its entire css callout; string could be google font with fallback, or system font with or without fallback
export const fontFamilyToStyleValue = (family: string): string => {
    const record = styleValueToCalloutRecord(family);
    return calloutRecordToStyleValue(record);
}
// next one converts callout record to style="font-family: <RESULT GOES HERE>"
export const calloutRecordToStyleValue = (record: FontCalloutRecord): string => {
    return (record.callout ?? record.family) + ", " + record.fallback;
}
export const isGoogleFont = (family: string): boolean => {
    const firstWord = family.split(',')[0].split(' ')[0];
    const found = fontMap.find(rec => rec.family.split(' ')[0] === firstWord);
   // console.log("isGoogleFont: found:", { family, firstWord, found });
    return !found;
}

const rowHeight = 36;
const fontSize = rowHeight - 4;

const FontPickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: tan;
`
interface FontPickerProps {
    id?: string;
    width?: number;
    selectedCallout?: FontCalloutRecord;       // default to Helvetica
    fontSubmitted: (callout: FontCalloutRecord | null) => void;
}
const FontPicker: React.FC<FontPickerProps> = (props) => {
    const [selectedFont, setSelectedFont] = React.useState(props.selectedCallout);
    const [showPicker, setShowPicker] = React.useState(false);

    const selectedFamily = props.selectedCallout?.family ?? "Arial";

    React.useEffect(() => {
        if (selectedFont?.family !== selectedFamily) {
            const callout = fontMap.find(entry => entry.family === selectedFamily);
    //        console.log("found callout:", callout)
            setSelectedFont(callout ?? props.selectedCallout);
        }
    }, [selectedFamily]);

    const fontSubmitted = (callout: FontCalloutRecord | null) => {
        setShowPicker(false);
        if (callout) {
            setSelectedFont(callout);
        }
        props.fontSubmitted(callout);
    }

    if (showPicker) {
        return (
            <SamModal>
                <FontPickerModal {...props} fontSubmitted={fontSubmitted} />
            </SamModal>
        )
    } else {
        return (
            <PopupCombo id={props.id ?? "fonts"} width={props.width ?? 80} value={selectedFamily} onPopupOpened={() => setShowPicker(true)} />
        )
    }
}
const FontPickerModal: React.FC<FontPickerProps> = (props) => {
    const [selectedFont, setSelectedFont] = React.useState(props.selectedCallout);

    const entryChecked = (callout: FontCalloutRecord) => {
        setSelectedFont(callout);
    }

    const fontSubmitted = () => {
        props.fontSubmitted(selectedFont ?? null);
    }
    // following assume that google font record has undefeined as callout field
    return (
        <FontPickerContainer>
            {fontMap.map(entry => {
                return (
                    <ListEntry key={entry.family} isChecked={selectedFont?.family === entry.family} callout={entry}
                        checkboxChecked={(entry) => entryChecked(entry)} />
                )
            })}
            <ListEntry key="google" isChecked={!!selectedFont && !selectedFont.callout} callout={{ family: selectedFont?.family ?? '', fallback: selectedFont?.fallback ?? '' }}
                checkboxChecked={(callout) => entryChecked(callout)} />
            <ButtonsRow marginTop={32}>
                <IconButton caption="OK" onClick={fontSubmitted} />
                <IconButton caption="Cancel" onClick={() => props.fontSubmitted(null)} />
            </ButtonsRow>
        </FontPickerContainer>
    )
}
//--------------------------------------------------------
const ListEntryContainer = styled.div<{ $fontFamily?: string }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: ${rowHeight}px;
    margin-top: 4px;
    margin-left: 32px;
    :hover {
        background-color: yellow;
    }
  p {
        margin-left: 8px;
        font-family: ${props => props.$fontFamily ?? ''};
    }
`
const Checkbox = styled.div`
      border: 2px solid black;
      background-color: white;
      height: ${fontSize}px;
      width: 32px;
      font-size: ${fontSize}px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
`
interface ListEntryProps {
    callout: FontCalloutRecord;     // if callout.callout is undefined then this must be a google font
    isChecked: boolean;
    checkboxChecked: (callout: FontCalloutRecord) => void;      // only called if box was checked
}
const ListEntry: React.FC<ListEntryProps> = (props) => {
    const [googleFont, setGoogleFont] = React.useState<FontCalloutRecord>();

    React.useEffect(() => {
        if (!props.callout.callout) {
            setGoogleFont(props.callout);
        }
    }, []);

    const checkboxClicked = () => {
        if (!props.isChecked) {
            // box was just checked so alert parent
            props.checkboxChecked(googleFont ?? props.callout);
        }
    }

    const fontChanged = (callout: FontCalloutRecord) => {
        setGoogleFont(callout);
        if (props.isChecked) {
            props.checkboxChecked(callout);
        }
    }

    const family = props.callout.callout ? (props.callout.callout + ", " + props.callout.fallback) : '';

    return (
        <ListEntryContainer $fontFamily={family}>
            <Checkbox onClick={checkboxClicked}>
                {props.isChecked ? (
                    <i className="fas fa-check" />
                ) : (
                    <span>&nbsp;</span>
                )}
            </Checkbox>
            {family ? (
                <p>{family}</p>
            ) : (
                <GoogleFontForm callout={googleFont} fontChanged={fontChanged} />
            )}
        </ListEntryContainer>
    )
}
//------------------------------------------------------
const GoogleFontFormContainer = styled.div`
    display: flex;
`
interface GoogleFontFormProps {
    callout?: FontCalloutRecord;
    fontChanged: (callout: FontCalloutRecord) => void;
}
const GoogleFontForm: React.FC<GoogleFontFormProps> = (props) => {
    const [googleFont, setGoogleFont] = React.useState<FontCalloutRecord>();
    const [showModal, setShowModal] = React.useState(false);

    React.useEffect(() => {
        if (props.callout?.callout) {
            setGoogleFont(props.callout);
        }
    }, [props.callout]);

    const fontSelected = (callout?: FontCalloutRecord) => {
        if (callout) {
            setGoogleFont(callout);
            props.fontChanged(callout);
        }
        setShowModal(false);
    }

    //   console.log("GoogleFontForm:", { propsCallout: props.callout, state: googleFont })

    return (
        <GoogleFontFormContainer>
            <p>{googleFont ? ("Current Google font is " + googleFont.family) : "No Google font has been selected"}</p>
            <IconButton caption="Get a Google font" marginLeft={8} style={{ height: "30px", marginTop: "10px" }} onClick={() => setShowModal(true)} />
            {showModal && <GoogleFontModalForm initialCallout={googleFont} fontSelected={fontSelected} />}
        </GoogleFontFormContainer>
    )
}
//------------------------------------------------------
const GoogleModalContainer = styled.div`
    width: 500px;
    height: 200px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f6f8e6;
    border: 2px solid #4a5929;
`
const SampleText = styled.p<{ $fontFamily: string }>`
    font-family: ${props => props.$fontFamily};
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 4px;
    text-align: center;
`

interface GoogleFontModalFormProps {
    initialCallout?: FontCalloutRecord;
    fontSelected: (callout?: FontCalloutRecord) => void;
}
const GoogleFontModalForm: React.FC<GoogleFontModalFormProps> = (props) => {
    const [loadFont, setLoadFont] = React.useState(false);
    const [fontToLoad, setFontToLoad] = React.useState<FontCalloutRecord>();
    const [googleFont, setGoogleFont] = React.useState<FontCalloutRecord>();

    const { setContext } = useGlobalContext();

    const formId = "googlefonts";

    React.useEffect(() => {
        if (props.initialCallout) {
            const forms = new FormMgr(setContext);
            forms.setValue(formId, "family", props.initialCallout.family);
            forms.setValue(formId, "fallback", props.initialCallout.fallback);
        }
    }, [props.initialCallout]);

    // this runs when user clicks "load and view font", which must happen before selectFont is enabled
    React.useEffect(() => {
        if (loadFont) {
            const forms = new FormMgr(setContext);
            const values = forms.getFormValues(formId);
            setFontToLoad({ family: values.family, fallback: values.fallback });
            setLoadFont(false);
        }
    }, [loadFont]);

    // runs when loader has returned with success or fail
    const loaderDone = (errors: string[]) => {
        if (!errors.length) {
            // load was successful
            setGoogleFont(fontToLoad);
        }
        setFontToLoad(undefined);
    }

    const fields: FormFieldRecord[] = [
        { name: "family", label: "Font family name (e.g., Roboto):", size: { widthPx: 200, marginLeft: 0 }, initialValue: props.initialCallout?.family ?? '' },
        {
            name: "fallback", label: "Fallback font:", fixedWidth: 150, type: FormFieldType.combo, initialValue: props.initialCallout?.fallback ?? '',
            comboSource: [
                { value: "serif" },
                { value: "sans-serif" },
                { value: "monospace" },
                { value: "cursive" },
                { value: "fantasy" }
            ]
        }
    ];

    return (
        <GoogleModalContainer>
            <SamForm id={formId} fields={fields} maxWidth={380} minWidth={380} />
            {googleFont?.family &&
                <SampleText $fontFamily={googleFont.family}>{"Here is some sample text in " + googleFont.family}</SampleText>
            }
            <ButtonsRow>
                <IconButton caption="Load font and view sample" onClick={() => setLoadFont(true)} />
                <IconButton caption="Choose this font" isDisabled={!googleFont} onClick={() => props.fontSelected(googleFont)} />
                <IconButton caption="Cancel" onClick={() => props.fontSelected()} />
            </ButtonsRow>
            {fontToLoad && <LoadFontsModal fonts={[fontToLoad.family]} onDone={loaderDone} />}
        </GoogleModalContainer>
    )
}

export default FontPicker;
