import React from 'react';
import styled from 'styled-components';

import { useGlobalContext } from './SamState';

interface TipRecord {
    tip: string;
    tipX: number;
    tipY: number;
    nipY: number;
    nipPosn: string;        // "top" or "bottom"
}
const useTooltips = () => {
    const { setContext } = useGlobalContext();

    // this must be called in useEffect([])
    const registerTooltip = (elem: HTMLElement, tip: string) => {
        const enterListener = function (e: MouseEvent) {
            const target = e.target as HTMLElement;
            const rect = target.getBoundingClientRect();
            // top of element is rect.y + window.scrollY
            // bottom of element is rect.y + window.scrollY + rect.height
            let tipY: number;
            let nipY: number;
            let nipPosn: string;
            if (rect.y < 30) {
                // tip needs to display under the element
                nipPosn = "top";
                nipY = rect.y + window.scrollY + rect.height;
                tipY = nipY + 16;
            } else {
                // tip goes above the element
                nipPosn = "bottom";
                tipY = rect.y + window.scrollY - 30;
                nipY = tipY + 10;
            }
            setContext("activetip", { tip: target.getAttribute("data-tip"), tipX: rect.x, tipY, nipY, nipPosn });
        }
        if (!elem.getAttribute("data-tip")) {
            elem.setAttribute("data-tip", tip);
        }
        elem.onmouseenter = enterListener;
        elem.onmouseleave = () => setContext("activetip", undefined);
        // elem.onmouseleave = function (e: any) {
        //     setTimeout(() => setContext("activetip", undefined), 100);
        // }
    }

    return {
        registerTooltip
    }
}

const TooltipContainer = styled.div<{ $x: number; $y: number }>`
    position: absolute;
    border-radius: 5px;
    top: ${props => props.$y}px;
    left: ${props => props.$x}px;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 20px;
    background-color: blue;
    color: white;
    padding-left: 8px;
    padding-right: 8px;
`
const Nipple = styled.div<{ $x: number; $y: number }>`
    position: absolute;
    top: ${props => props.$y}px;
    left: ${props => props.$x}px;
    font-size: 24px;
    color: blue;
`
// this must be in main render
export const ShowTooltips: React.FC = () => {
    const { getContext } = useGlobalContext();

    const activeTip = getContext("activetip") as TipRecord;
    if (activeTip) {
        return (
            <>
                <TooltipContainer $x={activeTip.tipX} $y={activeTip.tipY}>
                    {activeTip.tip}
                </TooltipContainer>
                <Nipple $x={activeTip.tipX + 8} $y={activeTip.nipY}><i className={"glyphicon glyphicon-triangle-" + activeTip.nipPosn}></i></Nipple>
            </>
        )
    }
    return null;
}

export default useTooltips;