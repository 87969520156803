import React from 'react';
import styled from 'styled-components';

import IconButton, { ButtonsRow } from '../../IconButtonV2';

const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
`
const InputContainer = styled.div`
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    input {
        margin-left: 8px;
        width: 250px;
    }
`

// pass element to edit existing link
// pass linkRemoved to display "Remove" button
interface LinkDialogProps {
    element?: HTMLAnchorElement;
    onSubmit: (url: string | null) => void;
    onRemove?: () => void;
}
const LinkDialog: React.FC<LinkDialogProps> = (props) => {
    const inputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    let linkNode: HTMLElement | undefined | null = props.element;
    while (linkNode && linkNode.nodeName != 'A') {
        linkNode = linkNode.parentElement;
    }
    const initialValue = linkNode ? (linkNode.getAttribute("href") ?? linkNode.getAttribute("data-href") ?? '') : '';
    // console.log("props.element:", props.element, "; url=" + initialValue)

    React.useEffect(() => {
        inputRef.current.focus();
    }, []);

    const linkSubmitted = () => {
        const url = inputRef.current!.value;
        if (url) {
            props.onSubmit(url);
        }
    }

    const keyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            linkSubmitted();
        } else if (e.key === "Escape") {
            e.preventDefault();
            e.stopPropagation();
            props.onSubmit(null);
        }
    }

    return (
        <LinkContainer>
            <InputContainer>
                <label>{"Type or paste the " + (linkNode ? "new " : '') + "url (ESC to cancel):"}</label>
                <input ref={inputRef} onKeyDown={keyDown} defaultValue={initialValue} />
            </InputContainer>
            <ButtonsRow>
                <IconButton caption="OK" icon="fas fa-check" onClick={linkSubmitted} />
                {props.onRemove && <IconButton caption="Remove link" icon="fas fa-ban" onClick={props.onRemove} />}
                <IconButton caption="Cancel" icon="fas fa-ban" onClick={() => props.onSubmit(null)} />
            </ButtonsRow>
        </LinkContainer>
    )
}
export default LinkDialog;