import { DivisionEnum, MenuItemData } from './interfaces/lib-api-interfaces';
import { AppThemesRecord } from './interfaces/lib-react-interfaces';

const menuItems: MenuItemData[] = [
    { caption: "Home",  href: "/dashboard/home" },
    { caption: "The Story",  href: "/dashboard/story" },
    { caption: "Photo Gallery",  href: "/dashboard/photo-gallery" },
    { caption: "Press",  href: "/dashboard/press" },
    { caption: "Wholesale",  href: "/dashboard/wholesale" },
    { caption: "Store Near You",  href: "/dashboard/stores" },
    { caption: "Admin", popupItems: [
        { caption: "Edit settings",  href: "/dashboard/settings" },
        { caption: "Change password",  href: "/dashboard/password" },
        { caption: "Fix media values",  href: "/dashboard/fix-media-temp" },
    ] }
];

const isDashboard = true;

export const domain = "laurainbocrystals.com";      // for dashboard the domain is the target domain
export const division = DivisionEnum.none;

const bannerBgImageAsSolid = "#29228f";
export const themes: AppThemesRecord = {
    sansFonts: "Palatino Linotype",
    serifFonts: "Palatino Linotype",
    color: "#29228f",
    backColor25: "#bebbef",  //  at 25%
    backColor10: "#e4e3f8", // at 10%
};
export const rainboThemes = {
    bannerBackgroundImage: "other/banner_bg.jpg",
    bannerBgImageAsSolid,
    imageBorder: "solid 14px " + bannerBgImageAsSolid,
    pageBackgroundImage: "other/cloud_bg2.jpg",
}
export const logoUrl = "other/laurainbo_mosaic_logo.jpg";

export const altLogo = "Laurainbo Crystals";

export default {
    menuItems,
    isDashboard,
    domain,
    division,
    bannerBgImageAsSolid,
    themes,
    logoUrl,
    altLogo
}
