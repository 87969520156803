import { activateImagesAndVideos } from "../../ImageFormatter";
import StyleMgr, { ToolCodeEnum, ToolMapRecord, getToolMapRecord, initStyleRecord } from "./StyleMgr";
import DomFormatter, { insertMarker } from "./DomFormatter";
import { ImageMgr } from "../SamImageV3";

import { ImageRecord, InfoPageApiRecord, InfoPageSettingsRecord, StyleRecord } from "../../../interfaces/lib-api-interfaces";

export type LinkClickedCallback = (url: string, linkNode: HTMLAnchorElement) => void;

export enum NodeTypeEnum { block = 1, text = 3 }

// setInfoPage() must be called after instantiation
interface DomEditorProps {
    editorDiv: HTMLDivElement;
    onAfterRender?: (ed: DomEditor) => void;
    onSelectionChanged?: (ed: DomEditor) => void;
}
class DomEditor {
    editorDiv: HTMLDivElement;                  // content is innerHTML
    settingsName: string;

    formatter: DomFormatter;
    imageMgr: ImageMgr;
    styleMgr: StyleMgr;
    selectedStyles: StyleRecord = {};

    constructor(props: DomEditorProps) {
        //   console.log("DOMEDITOR:", props)
        //   this.id = props.id;
        this.editorDiv = props.editorDiv;
        this.styleMgr = new StyleMgr(this.editorDiv);
        this.imageMgr = new ImageMgr();
        this.settingsName = '';
        this.formatter = new DomFormatter({ domEditor: this, onAfterRender: props.onAfterRender ?? (() => { }), onSelectionChanged: props.onSelectionChanged ?? (() => { }) });
        //      console.log("HTML LOADED")
        //       console.log("updating selected styles")
        //       this._updateSelectedStyles(this.selectionMgr.getCursorStyles());
        //   this.selectionMgr.cursorToTop();
        //      console.log("focusing")
        this.editorDiv.focus();
        this.editorDiv.addEventListener("keydown", (e: KeyboardEvent) => {
            //     console.log("keyDown: lastSelection:", this.selectionMgr.getSelection());
            //     console.log("keydown: " + e.key + ", e.ctrlKey=" + e.ctrlKey + ", isalpha=" + (e.key.length === 1 && e.key >= ' ' && e.key <= 'z'));
            if (!["Control", "Shift", "Escape"].includes(e.key)) {
                const isCommand = e.ctrlKey && "iuzbw ".includes(e.key);
                //  || (isCommand && e.key !== 'z')
                if (isCommand) {
                    e.preventDefault();
                    if ("ibu".includes(e.key)) {
                        const toolRecord = this.styleMgr.emphasisTagToMapRecord(e.key)!;
                        const style = toolRecord.styles![0];
                        this.toolClicked(initStyleRecord(style, this.selectedStyles[style] === toolRecord.setValue ? toolRecord.clearValue! : toolRecord.setValue!),
                            toolRecord);
                    } else if (e.key === ' ') {
                        const toolRecord = getToolMapRecord(ToolCodeEnum.cleanAll)!;
                        this.toolClicked(initStyleRecord("clean", ''), toolRecord);
                    } else if (e.key === 'z') {
                        this.formatter.rollbackUndo();
                    }
                }
            }
        });

    }

    // #region GETTERS AND SETTERS
    getHtml = (): string => {
        return this.editorDiv.innerHTML.replaceAll(insertMarker, '');
    }
    setInfoPage = (page: InfoPageApiRecord) => {
        console.log("domEditor.setInfoPage:", page)
        this.setSettings(page.settings);
        this.setHtml(page.content);
    }
    setHtml = (text: string) => {
        // console.log("domEditor.setHtml: imageFileOptions:", this.imageMgr.imageOptions?.fileOptions)
        text = text.replaceAll('\r', '').replaceAll('\n', '');
        let isEmpty = (text === '');
        if (isEmpty) {
            text = "<p>" + insertMarker + "</p>";
        }
        //    const oldText = this.editorDiv.innerHTML.substring(0, 500);
        this.editorDiv.innerHTML = text;
        //   console.log("domEditor.setHtml: FROM: " + oldText + "\r\nTO: " + this.editorDiv.innerHTML.substring(0, 100));
        // if (this.imageMgr.imageOptions?.fileOptions) {
        //     activateImagesAndVideos(this.editorDiv, this.imageMgr.imageOptions.fileOptions, true);    // true to skip videos (they are active only in read only mode from viewer)
        // }
        this._applySettings();
        console.log("setHtml: about to init selection")
        this.formatter.initSelection();
        console.log("setHtml: about to generate map")
        this.formatter.bookmarkAndMapSelection();
        console.log("setHtml: about to render")
        this.formatter.renderFromTextMap();
    }
    // this should have nothing to do with current html content
    // this stores settings, and apply settings makes them effective on current content
    setSettings = (settings: InfoPageSettingsRecord) => {
        this.settingsName = settings.name!;
        this.styleMgr.infoPageStyles.setGlobalStyles(settings.styles);
        this.imageMgr.allowImages = settings.allowImages;
        this.imageMgr.setImageEditorOptions(settings.image_options);
    }
    _applySettings = () => {
        this.styleMgr.infoPageStyles.applyGlobalStyles();
        if (this.imageMgr.allowImages && this.imageMgr.imageOptions.fileOptions) {
            activateImagesAndVideos(this.editorDiv, this.imageMgr.imageOptions.fileOptions, true);  // update src fields in images
        }
    }
    getSettings = (): InfoPageSettingsRecord => {
        return { name: this.settingsName, allowImages: this.imageMgr.allowImages, styles: this.styleMgr.infoPageStyles.currentStyles, image_options: this.imageMgr.imageOptions };
    }

    setCursorStyles = (node: Node) => {
        this.selectedStyles = this.styleMgr.getCursorStyles(node);
    }

    toolClicked = (styles: StyleRecord, toolRecord: ToolMapRecord) => {
        this.formatter.applyFormatting(styles, toolRecord);
    }
    // #endregion GETTERS AND SETTERS

    // #region LINKS
    applyLink = (url: string): HTMLAnchorElement => {
        const linkElem = this.formatter.insertLink(url);
        return linkElem;
    }
    updateLink = (linkElem: HTMLAnchorElement, url: string) => {
        linkElem.setAttribute("data-href", url);
    }
    removeLink = (linkElem: HTMLAnchorElement) => {
        this.formatter.removeLink(linkElem);
    }
    // #endregion LINKS

    // #region INSERT/DELETE TEXT OR IMAGE
    updateImageOrVideo = (image: ImageRecord, fig: HTMLElement): HTMLElement => {
        const updatedFig = this.imageMgr.createFigureElementFromImage(image);
        fig.parentElement!.replaceChild(updatedFig, fig);
        return updatedFig;
    }
    // image src will be formatted to point to final domain (or cdn) and modified by InfoPage viewer to omit full domain name, or point to cdn if appropriate
    // if video it must be shown as thumbnail here and modified by InfoPage viewer to insert iframe so video will be viewable
    insertImageOrVideo = (image: ImageRecord): HTMLElement => {
        const fig = this.imageMgr.createFigureElementFromImage(image);    // if video make sure only thumbnail is inserted
        //    console.log("insertImage: fig:", fig)
        this.formatter.insertElementAtSelection(fig);
        return fig;
    }
    deleteImageOrVideo = (fig: HTMLElement) => {
        this.imageMgr.deleteImageOrVideo(fig);
    }
    insertElement = (element: HTMLElement) => {
        this.formatter.insertElementAtSelection(element);
    }
    deleteSelectedText = () => {
        this.formatter.deleteSelection();
    }
    // #endregion INSERT/DELETE TEXT OR IMAGE

}
export default DomEditor;