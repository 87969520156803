import React from 'react';
import styled from 'styled-components';
import deepEqual from 'deep-equal';

import { capitalize, getNearestParentId, isObject } from '../libSupport';
import IconButton, { ButtonsRow } from '../IconButtonV2';
import { usePostApi } from '../useDataApiV2';
import { useTokens } from '../SamState';
import Spinner from '../Spinner';
import CloseDialogBar from '../CloseDialogBar';
import SamModal, { ModalMessageBox, ModalMessageResponseFlags } from '../SamModalV2';
import RifmNumeric from '../forms/RifmNumeric';
import {
    createIframeFromImageRecord,
    deformatImageUrl, formatImageOrVideoSrcWithFile, formatImageUrl, formatVimeoApiUrl, formatYoutubeThumbUrl, videoHeightFactor
} from '../ImageFormatter';
import HtmlDomEditor from './HtmlDomEditor/HtmlDomEditor';
import { ToolCodeEnum } from './HtmlDomEditor/StyleMgr';

import {
    CaptionOptionsEnum, FormFieldRecord, FormFieldType, GraphicDimensionType, GraphicFloatType, ImageAttributesEditOptions, ImageDragType,
    ImageEditorOptions, ImageFileOptions, ImageRecord, ImageUploadOptions, StyleRecord, VideoStreamSource
} from '../../interfaces/lib-api-interfaces';

import app from '../../appData';
import FormMgr from '../forms/FormMgr';

const logUpload = true;

/* from lib-api-interfaces.ts:
export interface ImageRecord {
    caption?: string;
    filename?: string;
    youtube_id?: string;
    blob?: string;
    url?: string;       // link
    tag?: string;       // displayed in bold (useful for SKU #)
    // following valid if image has been dragged or chosen from local filesystem
    file?: any;     // any is for Node; should be cast to File for React code 
    display_order?: number;
    size?: number; 
    dimension?: GraphicDimensionType; 
    float?: GraphicFloatType;   // currently used only for main image in info pages (about us needs to be wrapped right)
    rowState?: RowState;    // for ImageListHandler; caller should pass this as unchanged
    size_pct?: number;      // percentage to display image in apps; used for small items in fernsgarden.com
    embed_text?: string;    // youtube output text; for editing videos in dashboard
    alt?: string;
}
export enum CaptionOptionsEnum { allow = 'A', disallow = 'D', readOnly = 'R' }
export interface ImageEditorOptions {
    allowVideos?: boolean;
    uploadOptions?: ImageUploadOptions;            // images are disallowed if this is not given; next 2 also required
    editOptions?: ImageAttributesEditOptions;      // ditto
    fileOptions?: ImageFileOptions;                // ditto
}
export interface ImageAttributesEditOptions {
    allowLink?: boolean;
    verifyUrlApiUrl?: string; 
    captions: CaptionOptionsEnum;
    //   captionFormat?: CaptionFormatEnum;
    allowResize?: boolean;
    allowFloat?: boolean;       // aka "wrap"
}
// following used to display images, also for uploading
export interface ImageFileOptions {
    graphicsSubfolder?: string;      // folder under "graphics": "other", "blog", etc.
    isCdn?: boolean;                // overrides target domain to save file on https://artthisdesign.com/cdn; domain becomes the folder under cdn
    sizeDesignator?: ImageSizeEnum;     // full or magnified if image is stored in 2 versions
    size?: number;                   // default; overridden by size prop in ImageRecord; default to 500
    dimension?: GraphicDimensionType;    // ditto; default to width
}
export interface ImageUploadOptions {
    // following must be passed to enable choosing new image from file system; if not passed new images will not be allowed
    targetDomain: string;           // if isCdn true this is the directory below artthisdesign.com/cdn; else "/graphics" is appended to this domain name
    uploadImageApiUrl: string;
}
*/

/*
How to show/edit the following attributes:
(to track any editing changes pass onAttributesChanged)
(to track deletes pass onDelete)
    Delete button: pass onDelete()
    Tag (e.g., SKU #): pass image.tag
    Dimension priority (size or height): imageOptions.editOptions.allowDimensionChange (careful using with float; float sets dimension to width)
    Captions: imageOptions.editOptions.captions determines whether they are shown and are editable
    Float dropdown: imageOptions.editOptions.allowFloat
    Width % and maximum width: imageOptions.editorOptions.allowResize
    Link url input box: imageOptions.editorOptions.allowLink
    "OK" and "Cancel" buttons at bottom: pass onSubmit and/or onCancel

    videos are always dimension width, links not allowed
*/

// following is passed to callback passed as props.imageLoaded
export interface ImageRect {
    width: number;
    height: number;
    parentTop: number;
    parentLeft: number;
}
// caller can store the setSize calls and use them to change sizes of all images in a grid, e.g. to make them consistent heights or widths
export type ImageLoadedCallback = (imageId: string | undefined, imageRect: ImageRect, setSize: (newWidth: number, newHeight: number) => void) => void;

// #region VIEW AND EDIT ONE IMAGE
// viewer can be part of a grid; when "Edit" button is pressed a modal is shown to allow editing of all attributes
// the modal editor is also called when a new image is chosen or dropped from Chooser
export enum AttributeLocationEnum { below = 'B', right = 'R' }
// note that width OR height will be "auto"
const ImageWithAttributesContainer = styled.div<{ $attributeLocation: AttributeLocationEnum; $marginLeft: number; $width: number }>`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: ${props => props.$attributeLocation === AttributeLocationEnum.below ? "column" : "row"};
    margin-left: ${props => props.$marginLeft}px;
    width: ${props => props.$width}px;
    margin-top: 8px;
    padding: 8px;
    border: 1px solid;
`
const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
interface SamImageProps {
    image: ImageRecord;
    imageOptions: ImageEditorOptions;       // supports uploading, editing and displaying
    imageId?: string;         // used when shown from grid
    marginLeft?: number;    // px
    suppressContextMenu?: boolean;      // for use with right click to edit image
    attributeLocation?: AttributeLocationEnum;   // default to below
    imageDisplaySize?: number;     // fit to longest image dimension; default to 150px
    editorWidth?: number;        // default to 600px
    onCloseModal?: () => void;      // if passed the component is shown as modal and this is called when close bar (X) is clicked
    onDelete?: (imageId?: string) => void;      // pass to show "Delete" button
    onSubmit?: (image: ImageRecord, imageId?: string) => void;     // pass to show "OK" button
    onCancel?: (imageId?: string) => void;      // pass to show "Cancel" button
    onAttributesChanged?: (image: ImageRecord, imageId?: string) => void;     // pass to monitor when any attribute changes; use with onSubmit or not
    handleDrop?: (sourceIndex: number, targetIndex: number) => void;       // used only by SamImageGrid; if not given drag/drop not supported
    imageLoaded?: ImageLoadedCallback;          // for getting image size and changing it if desired
}
const SamImage: React.FC<SamImageProps> = (props) => {
    if (props.onCloseModal) {
        return (
            <SamModal>
                <SamImageNonModal {...props} />
            </SamModal>
        )
    }
    return (
        <SamImageNonModal {...props} />
    )
}
const SamImageNonModal: React.FC<SamImageProps> = (props) => {
    const attributeLocation = props.attributeLocation ?? AttributeLocationEnum.below;

    const imageDisplaySize = props.imageDisplaySize ?? 150;
    const width = props.editorWidth ?? 400;

    const editorRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    React.useEffect(() => {
        if (props.image.stream_id) {
            // this is video so create an iframe for it
            const video = { ...props.image, size: width * 0.9 };
            const editor = editorRef!.current;
            const iframe = createIframeFromImageRecord(video);
            editor!.parentElement!.insertBefore(iframe, editor);
        }
    }, []);

    const handleContextMenu = (e: React.MouseEvent) => {
        if (props.suppressContextMenu) {
            e.preventDefault();
        }
    }

    return (
        <ImageWithAttributesContainer $attributeLocation={attributeLocation} $marginLeft={props.marginLeft ?? 0} $width={width} onContextMenu={handleContextMenu}>
            {props.onCloseModal && <CloseDialogBar onClose={props.onCloseModal} />}
            {!props.image.stream_id &&
                <ImageContainer>
                    <SizedImage imageId={props.imageId} imageDisplaySize={imageDisplaySize} handleDrop={props.handleDrop} imageLoaded={props.imageLoaded}
                        image={props.image} fileOptions={props.imageOptions.fileOptions} />
                </ImageContainer>
            }
            <ViewAndEditAttributes editorRef={editorRef} imageId={props.imageId} image={props.image} imageOptions={props.imageOptions} marginLeft={attributeLocation === AttributeLocationEnum.right ? 16 : 0}
                onAttributesChanged={props.onAttributesChanged} onSubmit={props.onSubmit} onCancel={props.onCancel} onDelete={props.onDelete} />
        </ImageWithAttributesContainer>
    )
}

//--------- ViewAndEditAttributes --------------------------
// does NOT show image; only attributes

// attributeLocation determines whether margin is allowed on top or left
const ViewAndEditAttributesContainer = styled.div<{ $marginLeft: number }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: ${props => props.$marginLeft}px;
`
const TagText = styled.p`
    font-weight: bold;
    margin: 0;
`
const TextRow = styled.div`
    text-align: left;
    margin-bottom: 12px;
    width: 100%;
`
const LabelText = styled.span<{ $marginLeft?: number }>`
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
    margin-left: ${props => props.$marginLeft ?? 0}px;
    margin-right: 4px;
`
const UnitsText = styled.span`
    margin-left: 4px;
`
const CaptionBox = styled.div`
`
// for attributes to be editable, attributesChanged OR onSubmit must be passed (or both)
interface ViewAndEditAttributesProps {
    image: ImageRecord;
    imageId?: string;       // used as element id so parent can locate this component (used to insert video iframe)
    imageOptions: ImageEditorOptions;       // supports uploading, editing and displaying
    marginLeft?: number;
    editorRef?: React.MutableRefObject<HTMLDivElement>;
    onDelete?: (imageId?: string) => void;
    // note: if neither onAttributesChanged nor onSubmit is passed, the image is displayed as read only
    onAttributesChanged?: (newImage: ImageRecord, imageId?: string) => void;        // for use as controlled component (e.g., from SamImageGrid)
    onSubmit?: (image: ImageRecord, imageId?: string) => void;          // displays "OK" button; for use as non-controlled component
    onCancel?: (imageId?: string) => void;                          // displays "Cancel" button
}
const ViewAndEditAttributes: React.FC<ViewAndEditAttributesProps> = (props) => {
    const [editingCaption, setEditingCaption] = React.useState(false);
    const [confirmingDelete, setConfirmingDelete] = React.useState(false);
    const [image, setImage] = React.useState<ImageRecord>();

    const linkRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const readOnly = !props.onAttributesChanged && !props.onSubmit;

    React.useEffect(() => {
        setImage(props.image);
    }, []);

    const updateAttributes = (newImage: ImageRecord) => {
        console.log("updateAttributes:", newImage)
        setImage(newImage);
        props.onAttributesChanged && props.onAttributesChanged(newImage, props.imageId);
    }

    const linkChanged = () => {
        updateAttributes({ ...image, url: linkRef.current.value });
    }
    const sizeChanged = (newSize: number | string) => {
        updateAttributes({ ...image, size: newSize as number });
    }
    const floatChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newImage = { ...image, float: e.target.value as GraphicFloatType };
        if (newImage.float !== GraphicFloatType.none) {
            newImage.dimension = GraphicDimensionType.width;
        }
        updateAttributes(newImage);
    }
    const dimensionChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newImage = { ...image, dimension: e.target.value as GraphicDimensionType };
        if (newImage.dimension === GraphicDimensionType.height) {
            newImage.float = GraphicFloatType.none;
        }
        updateAttributes(newImage);
    }
    const sizePctChanged = (newSize: number | string) => {
        updateAttributes({ ...image, size_pct: newSize as number });
    }
    const captionChanged = (text: string) => {
        updateAttributes({ ...image, caption: text });
    }
    /*
    const captionSubmitted = (caption: string | null) => {
        if (caption !== null) {
            //      console.log("captionSubmitted: " + caption)
            updateAttributes({ ...image, caption: caption ?? undefined });
        }
        setEditingCaption(false);
    }
    */
    const confirmDeleteSubmitted = (confirmed: boolean) => {
        setConfirmingDelete(false);
        if (confirmed) {
            props.onDelete!(props.imageId);
        }
    }

    const numWidth = 30;

    // console.log("rendering ViewAndEditAttributes with image:", image)

    return (
        <ViewAndEditAttributesContainer ref={props.editorRef} id={props.imageId} $marginLeft={props.marginLeft ?? 0}>
            {image &&
                <>
                    {props.onDelete && (
                        <ButtonsRow>
                            <IconButton caption={"Delete " + (image.stream_id ? "video" : "image")} onClick={() => setConfirmingDelete(true)} />
                        </ButtonsRow>
                    )}
                    {props.image.tag && <TagText>{props.image.tag}</TagText>}
                    {props.imageOptions.editOptions?.captions !== CaptionOptionsEnum.disallow &&
                        <TextRow>
                            <CaptionBox>
                                <LabelText>Caption:<br /></LabelText>
                                {editingCaption ? (
                                    <HtmlDomEditor infoPage={{ content: image.caption ?? '', settings: { allowImages: false } }} widthPct={90}
                                        includedTools={[ToolCodeEnum.bold, ToolCodeEnum.italic, ToolCodeEnum.underline, ToolCodeEnum.link]}
                                        contentChanged={captionChanged} />
                                ) : (
                                    <>
                                        <span dangerouslySetInnerHTML={{ __html: image.caption ?? "(none)" }} />
                                        {props.imageOptions.editOptions?.captions !== CaptionOptionsEnum.readOnly &&
                                            <i style={{ color: "green", fontSize: "18px", marginLeft: "4px" }} className="fa fa-pencil-square-o" onClick={() => setEditingCaption(true)} />}
                                    </>
                                )}
                            </CaptionBox>
                            { /*
                                <i style={{ color: "green", fontSize: "18px", marginLeft: "4px" }} className="fa fa-pencil-square-o" onClick={() => setEditingCaption(true)} />
                                    {props.imageOptions.editOptions?.captions === CaptionOptionsEnum.readOnly ? (
                                <p>{image.caption}</p>
                            ) : (
                                <CaptionTextInput value={image.caption ?? ''} onChange={captionChanged} />
                            )}
                            */}
                        </TextRow>
                    }
                    {props.imageOptions.editOptions?.allowDimensionChange && !image.stream_id &&
                        <TextRow>
                            <LabelText>Size image using its</LabelText>
                            <select style={{ width: "60px" }} onChange={dimensionChanged} value={image.dimension ?? GraphicDimensionType.width}>
                                <option value={GraphicDimensionType.width}>Width</option>
                                <option value={GraphicDimensionType.height}>Height</option>
                            </select>
                            <UnitsText>Caution: Using height may cause cropping on smaller screens</UnitsText>
                        </TextRow>
                    }
                    {props.imageOptions.editOptions?.allowLink && !image.stream_id &&
                        <>
                            {props.onAttributesChanged ? (
                                <TextRow>
                                    <input ref={linkRef} value={image.url} placeholder="Enter link here (http...)" onChange={linkChanged} />
                                </TextRow>
                            ) : (
                                <TextRow>
                                    <LabelText>Link:&nbsp;</LabelText>
                                    <span>{image.url ?? "(none)"}&nbsp;</span>
                                </TextRow>
                            )}
                        </>
                    }
                    {props.imageOptions.editOptions?.allowFloat &&
                        <TextRow>
                            <LabelText>Wrap:&nbsp;</LabelText>
                            {!readOnly ? (
                                <>
                                    <select style={{ width: "60px" }} onChange={floatChanged} value={image.float ?? GraphicFloatType.none}>
                                        <option value={GraphicFloatType.left}>Left</option>
                                        <option value={GraphicFloatType.right}>Right</option>
                                        <option value={GraphicFloatType.none}>None</option>
                                    </select>
                                </>
                            ) : (
                                <span>{capitalize(ImageMgr.graphicFloatTypeToString(image.float))}</span>
                            )}
                        </TextRow>
                    }
                    {image.float !== GraphicFloatType.none || image.dimension === GraphicDimensionType.width || image.stream_id ? (
                        <TextRow>
                            <LabelText>Width:</LabelText>
                            <RifmNumeric name="widthPct" fieldType={FormFieldType.digitsOnly} numericLength={3} initialValue={image.size_pct ?? 100} width={numWidth} height={20}
                                showChevrons={true} onChange={sizePctChanged} />
                            <UnitsText>%</UnitsText>
                            <LabelText $marginLeft={12}>Maximum width:</LabelText>
                            <RifmNumeric name="size" fieldType={FormFieldType.digitsOnly} numericLength={4} initialValue={image.size ?? 500} width={numWidth} height={20}
                                showChevrons={true} onChange={sizeChanged} />
                            <UnitsText>pixels</UnitsText>
                        </TextRow>
                    ) : (
                        <TextRow>
                            <LabelText>Height (pixels):</LabelText>
                            <RifmNumeric name="height" fieldType={FormFieldType.digitsOnly} numericLength={3} initialValue={image.size ?? 300} width={numWidth} height={20}
                                showChevrons={true} onChange={sizeChanged} />
                        </TextRow>
                    )}
                    {image.filename &&
                        <TextRow>
                            <LabelText>Name:&nbsp;</LabelText>
                            <span>{image.filename}&nbsp;</span>
                        </TextRow>
                    }
                    {image.stream_id &&
                        <TextRow>
                            <LabelText>Video:&nbsp;</LabelText>
                            <span>{(image.stream_source === VideoStreamSource.vimeo ? "Vimeo #" : "YouTube #") + image.stream_id}</span>
                        </TextRow>
                    }
                    {!confirmingDelete && (props.onSubmit || props.onCancel) &&
                        <ButtonsRow>
                            {props.onSubmit &&
                                <IconButton caption="OK" icon="fas fa-check" onClick={() => props.onSubmit!(image, props.imageId)} />
                            }
                            {props.onCancel &&
                                <IconButton caption="Cancel" icon="fas fa-ban" onClick={() => props.onCancel!(props.imageId)} />
                            }
                        </ButtonsRow>
                    }
                    {confirmingDelete && <ConfirmDeleteDlg filename={image.filename ?? "this image"} onSubmit={confirmDeleteSubmitted} />}
                </>}
        </ViewAndEditAttributesContainer>
    )
}
//                     {editingCaption && <CaptionEditor caption={image.caption ?? ''} onSubmit={captionSubmitted} />}
// {(props.imageOptions.editOptions?.captions === CaptionOptionsEnum.allow || props.imageOptions.editOptions?.captions === CaptionOptionsEnum.readOnly) &&
//     <TextRow>
//         <LabelText>Caption:&nbsp;</LabelText>
//         <span dangerouslySetInnerHTML={{ __html: image.caption ?? "(none)" }} />
//         {!readOnly && props.imageOptions.editOptions?.captions !== CaptionOptionsEnum.readOnly &&
//             <i style={{ color: "green", fontSize: "18px", marginLeft: "4px" }} className="fa fa-pencil-square-o" onClick={() => setEditingCaption(true)} />
//         }
//     </TextRow>
// }


//-------------------------------------------------------------
/*
const CaptionEditorContainer = styled.div`
    padding: 16px;
`
interface CaptionEditorProps {
    caption: string;
    onSubmit: (caption: string | null) => void;
}
const CaptionEditor: React.FC<CaptionEditorProps> = (props) => {
    const [editor, setEditor] = React.useState<HtmlDomEditorApi>();
    return (
        <SamModal>
            <CaptionEditorContainer>
                <HtmlDomEditor id="figCaption" infoPage={{ content: props.caption, name: 'caption', domain: app.targetDomain }}
                        domainDefaults={{ domain: app.targetDomain, styles: globalStylesValues() }} 
                        width={600} height={200} editorInitialized={setEditor} />
                <ButtonsRow>
                    <IconButton caption="Submit" onClick={() => props.onSubmit(editor!.getHtml())} />
                    <IconButton caption="Cancel" onClick={() => props.onSubmit(null)} />
                </ButtonsRow>
            </CaptionEditorContainer>
        </SamModal>
    )
}
*/
//-------------------------------------------------------------
/* following was removed on 11/1/23:
const ImageSizeFormContainer = styled.div`
    display: flex;
    margin-bottom: 12px;
`
const SizeInput = styled.input`
    width: 50px;
    height: 20px;
`
interface ImageSizeFormProps {
    image: ImageRecord;             // passed to sizer (not shown in local form)
    fileOptions?: ImageFileOptions;
    size: number;                   // size in fileOptions and image are ignored; this is the SOT
    sizeChanged: (newSize: number) => void;
}
const ImageSizeForm: React.FC<ImageSizeFormProps> = (props) => {
    const [showSizer, setShowSizer] = React.useState(false);
    const [rifmValueCallback, setRifmValueCallback] = React.useState<(value: string) => void>();

    const sizeInputChanged = (newSize: number | string) => {
        props.sizeChanged(newSize as number);
    }
    const onSizerSubmit = (newSize: number | null) => {
        setShowSizer(false);
        if (newSize) {
            rifmValueCallback!(newSize + '');
            props.sizeChanged(newSize);
        }
    }

    return (
        <>
            <ImageSizeFormContainer>
                <LabelText>{props.image.dimension === GraphicDimensionType.height ? "Height" : "Width"}:&nbsp;</LabelText>
                <RifmNumeric name="width" fieldType={FormFieldType.digitsOnly} numericLength={3} initialValue={props.size} width={50} height={20} showChevrons={true}
                    onChange={sizeInputChanged} setValueCallback={callback => setRifmValueCallback(() => callback)} />
                <i style={{ color: "green", fontSize: "24px", marginLeft: "4px", marginTop: "-4px" }} className="fa fa-pencil-square-o" onClick={() => setShowSizer(true)} />
            </ImageSizeFormContainer>
            {showSizer && <ImageSizer image={props.image} fileOptions={props.fileOptions} size={props.size} onSubmit={onSizerSubmit} />}
        </>
    )
}
*/
//                 <SizeInput type="number" value={props.size} onChange={sizeInputChanged} />

/*
  fieldType: FormFieldType;      // int = 'I', money = '$', fixedPoint, phone
  name: string;
  initialValue?: string | number;   // string for phone, else number
  fontSize?: number;     // defaults to 14
  width?: number;     // omit for 100%
  height?: number;      // default to 28
  allowNegative?: boolean;
  allowZero?: boolean;
  decimalPlaces?: number;
  showChevrons?: boolean;
  borderColor?: string;
  placeholder?: string;
  textAlign?: string;   // defaults to "right" on numbers, "left" on phone, but could be passed as "center"
  numericLength?: number;   // required for type=digitsOnly
  onChange?: (value: string | number, name: string) => void;    // value is number except phone is string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
*/
const ImageSizerContainer = styled.div<{ $width: number }>`
    width: ${props => props.$width}px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
`
const ImageSizerImage = styled.img<{ $width: number }>`
    width: ${props => props.$width}px;
    height: auto;
`
const SizeLabel = styled.p`
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
`
/* input value is 1-100 --> % of max image size
    max image size is size coming in plus 20% (minimum max size is 400px)
    image is displayed as pixel width so if dimension is height the display width is multiplied by factor
*/
interface ImageSizerProps {
    image: ImageRecord;
    fileOptions?: ImageFileOptions;
    size: number;                   // size in fileOptions and image are ignored; this is the SOT
    maxDisplaySize?: number;        // default to 800px
    onSubmit: (newSize: number | null) => void;
}
const ImageSizer: React.FC<ImageSizerProps> = (props) => {
    const [size, setSize] = React.useState(props.size);
    const [widthFactor, setWidthFactor] = React.useState(1.0);      // multiply size by this to get width; if dimension is height this will not be 1.0

    const displayWidth = props.maxDisplaySize ?? 800;
    const dimension = props.fileOptions?.dimension ?? GraphicDimensionType.width;
    const maxSize = Math.max(props.size * 1.2, 400);

    const imageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const target = e.target as HTMLImageElement;
        if (dimension === GraphicDimensionType.height) {
            // factor is no longer 1; need to calculate
            setWidthFactor(target.naturalWidth / target.naturalHeight);
        }
    }

    const sizeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        // value is 0-100 so convert to image size, with range extending up to original plus 20%
        setSize(Math.round(parseInt(e.target.value) / 100 * maxSize));
    }
    return (
        <SamModal>
            <ImageSizerContainer $width={displayWidth}>
                <ImageSizerImage src={formatImageOrVideoSrcWithFile(props.image, props.fileOptions)} $width={Math.max(size * widthFactor, 100)} onLoad={imageLoaded} />
                <input type="range" style={{ width: (displayWidth - 50) + "px" }} value={size / maxSize * 100} onChange={sizeChanged} />
                <SizeLabel>{(dimension === GraphicDimensionType.width ? "Width" : "Height") + " " + size + "px"}</SizeLabel>
                <ButtonsRow>
                    <IconButton caption="Submit" onClick={() => props.onSubmit(size)} />
                    <IconButton caption="Cancel" onClick={() => props.onSubmit(null)} />
                </ButtonsRow>

            </ImageSizerContainer>
        </SamModal>
    )
}
//-------------------------------------------------------------
interface ConfirmDeleteDlgProps {
    filename: string;
    onSubmit: (confirmed: boolean) => void;
}
const ConfirmDeleteDlg: React.FC<ConfirmDeleteDlgProps> = (props) => {
    return (
        <ModalMessageBox caption={"Are you sure you want to remove " + props.filename + "?"}
            responseFlags={ModalMessageResponseFlags.yes | ModalMessageResponseFlags.no}
            onSubmit={result => props.onSubmit(result === ModalMessageResponseFlags.yes)}
        />
    )
}
// #endregion DISPLAY AND EDIT ONE IMAGE

// #region SizedImage: SINGLE IMAGE SUPPORTING DRAG/DROP
// cursor is "pointer" if drag/drop allowed, else "default"
// used to show image in grid or when user clicks "edit" from image viewer or when new image is selected
const Image = styled.img<{ $width: number; $allowDragDrop?: boolean }>`
    width: ${props => props.$width}px;
    height: auto;
    cursor: ${props => props.$allowDragDrop ? "pointer" : "default"};
`
interface SizedImageProps {
    image: ImageRecord;
    fileOptions?: ImageFileOptions;
    imageDisplaySize: number;           // image is resized so this is longest dimension; image takes 90% of box
    imageId?: string;
    handleDrop?: (sourceIndex: number, targetIndex: number) => void;       // used only by SamImageGrid; if not given drag/drop not supported
    // following allows parent to query actual image size after resizing per props.size, and then parent can reset the size after evaluating all images in its row
    imageLoaded?: ImageLoadedCallback;
}
const SizedImage: React.FC<SizedImageProps> = (props) => {
    //   const maxImageSize = props.size ?? 300;
    const [maxHeight, setMaxHeight] = React.useState(0);
    const [maxWidth, setMaxWidth] = React.useState(0);

    // console.log("SizedImage:", {propsSize: props.size, maxWidth, maxHeight });

    // this is called by parent to optionally change size of displayed image (e.g., to make consistent with others in grid)
    const setSize = (newWidth: number, newHeight: number) => {
        //   console.log("SizedImage.setSize:", { propsSize: props.size, newWidth, newHeight });
        setMaxWidth(newWidth);
        setMaxHeight(newHeight);
    }

    React.useEffect(() => {
        if (props.image.stream_id) {
            setMaxWidth(props.imageDisplaySize);
            setMaxHeight(props.imageDisplaySize * videoHeightFactor);
        }
    }, []);

    const imageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (!maxWidth) {
            const target = e.target as HTMLImageElement;
            const maxSize = props.imageDisplaySize;
            const longestDim = Math.max(target.naturalWidth, target.naturalHeight);
            const ratio = maxSize / longestDim;
            const height = ratio * target.naturalHeight;
            const width = ratio * target.naturalWidth;
            setMaxHeight(height);
            setMaxWidth(width);
            if (props.imageLoaded) {
                const parentNode = target.parentElement;
                const parentRect = parentNode!.getBoundingClientRect();
                props.imageLoaded(props.imageId, { width, height: height, parentTop: parentRect.top, parentLeft: parentRect.left }, setSize);
            }
        }
    }

    //-------- DRAG AND DROP (parent must pass handleDrop for this to be active) --------------
    const handleDragStart = (e: React.DragEvent<HTMLImageElement>) => {
        const id = getNearestParentId(e.target as HTMLImageElement).id;
        e.dataTransfer.setData(ImageDragType, id);
    }
    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let valid = false;
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
            if (e.dataTransfer.items[i].type === ImageDragType) {
                valid = true;
            }
        }
        e.dataTransfer.dropEffect = valid ? "copy" : "none";
    }
    const handleDrop = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const target = parseInt(getNearestParentId(e.target as HTMLImageElement).id);
        {
            props.handleDrop!(parseInt(e.dataTransfer.getData(ImageDragType)), target);
        }
    }
    //-------- END DRAG AND DROP --------------

    return (
        <Image id={props.imageId} src={formatImageOrVideoSrcWithFile(props.image, props.fileOptions)} $width={maxWidth} height={maxHeight}
            draggable={!!props.handleDrop}
            onLoad={imageLoaded}
            onDragStart={props.handleDrop ? handleDragStart : undefined}
            onDragOver={props.handleDrop ? handleDragOver : undefined}
            onDrop={props.handleDrop ? handleDrop : undefined}
        />
    )
}
// #endregion SizedImage: SINGLE IMAGE SUPPORTING DRAG/DROP

// #region DROP OR CHOOSE IMAGE FROM FILE SYSTEM
/* this supports:
        choose image from file system
        drop image from file system
        drop image from grid
*/
const ImageInputContainer = styled.div`
    display: flex;
    margin-left: 8px;
    margin-top: 8px;
    `
// square container holds image "chooser" and video "chooser"
const ImageOrVideoChooserContainer = styled.div<{ $size: number }>`
    display: flex;
    flex-direction: column;
    border: 1px solid;
    width: ${props => props.$size}px;
    justify-content: center;
`
// width is props.size; height is half that if videos allowed
const ImageChooserContainer = styled.div<{ $width: number }>`
    width: 100%;
    height: 100px;
    border: 1px #ccc solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
        margin - left: 8px;
        margin-right: 8px;
    }
`
const VideoChooserContainer = styled.div`
    width 100%;
`
interface ImageChooserProps {
    imageOptions: ImageEditorOptions;       // do not included editOptions here to make attributes read only
    uploadOptions: ImageUploadOptions;
    displaySize: number;       // for display, NOT uploading (see fileOptions for uploading); default to fileOptions
    onImageSelected: (image: ImageRecord | null) => void;       // called after user has had chance to edit the attributes (if imageOptions.editOptions passed)
    // to set data using the following, use e.dataTransfer.setData(ImageDragType, id)
    // the following is to allow moving a grid image to the end of the list
    onImageDropped?: (data: string) => void;       // if this is passed, items can be dropped if they have a type of "Imagelist"; the data is the dataTransfer.item.data field
    renameOnUpload?: (file: File) => string;        // allows parent to suggest a filename when image is uploaded to server (api may modify for uniqueness)
    forceFilename?: (file: File) => string;        // allows parent to choose filename when image is uploaded to server (api does not modify; good for testing)
}
export const ImageChooser: React.FC<ImageChooserProps> = (props) => {
    const [imageToUpload, setImageToUpload] = React.useState<ImageRecord>();
    const [imageToEdit, setImageToEdit] = React.useState<ImageRecord>();    // set after image uploaded if editOptions have been passed in props

    const fileInputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const { post, isPostLoading } = usePostApi();
    const { getToken } = useTokens();

    const imageUploaded = (filename: string) => {
        const blob = URL.createObjectURL(imageToUpload!.file!);
        const newImage = { ...imageToUpload, filename, blob };
        setImageToUpload(undefined);
        if (props.imageOptions.editOptions) {
            setImageToEdit(newImage);
        } else {
            //     console.log("calling props.onImageSelected with:", newImage);
            props.onImageSelected(newImage);
        }
    }
    const videoSelected = (image: ImageRecord) => {
        console.log("video selected:", image)
        if (props.imageOptions.editOptions) {
            setImageToEdit(image);
        } else {
            props.onImageSelected(image);
        }
    }

    React.useEffect(() => {
        // FOLLOWING MUST AGREE WITH api file graphics.ts, interface FormFields:
        if (imageToUpload) {
            const form = new FormData();
            form.append("file", imageToUpload.file!);
            form.append('size', imageToUpload.size + ' ');
            form.append('dimension', imageToUpload.dimension!);
            form.append('isCdn', props.imageOptions.fileOptions!.isCdn ? 'Y' : 'N');
            form.append('domain', props.uploadOptions!.targetDomain);
            form.append('outputMagnified', props.imageOptions.fileOptions?.sizeDesignator ? 'Y' : 'N');
            if (props.imageOptions.fileOptions?.graphicsSubfolder) {
                form.append('graphics_subfolder', props.imageOptions.fileOptions.graphicsSubfolder);
            }
            if (props.renameOnUpload) {
                form.append("suggested_name", props.renameOnUpload(imageToUpload.file!));
            }
            if (props.forceFilename) {
                form.append("override_name", props.forceFilename(imageToUpload.file!));
            }
            if (logUpload) {
                console.log("posting form:", form);
                // {
                //     filename: imageToUpload.file!.name,
                //     size: imageToUpload.size,
                //     dimension: imageToUpload.dimension!,
                //     isCdn: props.imageOptions.fileOptions!.isCdn ? 'Y' : 'N',
                //     domain: props.imageOptions.uploadOptions!.targetDomain,
                //     graphics_subfolder: props.imageOptions.fileOptions?.graphicsSubfolder,
                //     outputMagnified: props.imageOptions.fileOptions?.sizeDesignator ? 'Y' : 'N'
                // });
            }
            post(props.uploadOptions!.uploadImageApiUrl, form, imageUploaded,
                () => {
                    setImageToUpload(undefined);
                    alert("Server error trying to upload image");
                }, getToken()!.token, true);

            //            post("/api/userIsAlive", form, imageUploaded, () => alert("Server error trying to upload image"), getToken()!.token, true);
        }
    }, [imageToUpload]);

    const editImageSubmitted = (image: ImageRecord | null, imageId?: string) => {
        props.onImageSelected(image);
        setImageToEdit(undefined);
    }
    //-------- DRAG AND DROP ------------
    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let valid = false;
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
            if ((e.dataTransfer.items[i].type.startsWith("image/")) || (props.onImageDropped && e.dataTransfer.items[i].type === ImageDragType)) {
                valid = true;
            }
        }
        e.dataTransfer.dropEffect = valid ? "copy" : "none";
    }
    const handleDrop = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer.files.length) {
            // dropping from file system
            if (e.dataTransfer.files.length > 1) {
                alert("Please drag only one photo at a time");
            } else {
                loadImageToUpload(e.dataTransfer.files[0]);
            }
        } else if (e.dataTransfer.getData(ImageDragType) !== '') {
            if (props.onImageDropped) {
                props.onImageDropped(e.dataTransfer.getData(ImageDragType));
            }
        }
    }
    //-------- END DRAG AND DROP ------------

    //-------- BROWSE FOR FILE ------------
    const browseClicked = (e: React.MouseEvent<HTMLElement>) => {
        fileInputRef.current.click();
    }
    const handleFileChosen = () => {
        //    console.log("got file: " + fileInputRef.current.files[0].name);
        const elem = fileInputRef.current;
        if (!isImageFile(elem.files![0])) {
            alert("File must be an image (.jpg .png .gif .tif)");
        } else {
            loadImageToUpload(elem.files![0]);
        }
    }
    // create image record for image just selected from file system
    const loadImageToUpload = (file: File) => {
        setImageToUpload({
            file,
            size: props.imageOptions.fileOptions?.size ? props.imageOptions.fileOptions.size : 500,
            dimension: props.imageOptions.fileOptions?.dimension ? props.imageOptions.fileOptions?.dimension : GraphicDimensionType.width
        });
    }
    //-------- END BROWSE FOR FILE ------------

    if (isPostLoading()) {
        return null;
    } else {
        return (
            <ImageInputContainer>
                <CloseDialogBar onClose={() => props.onImageSelected(null)} />
                <ImageOrVideoChooserContainer $size={props.displaySize} onDragOver={handleDragOver} onDrop={handleDrop}>
                    <ImageChooserContainer $width={props.displaySize} onClick={browseClicked}>
                        <p>Drop new image here or click to browse for file</p>
                    </ImageChooserContainer>
                    {props.imageOptions.editOptions?.allowVideo &&
                        <VideoChooserContainer>
                            <AddVideoBox videoAdded={videoSelected} />
                        </VideoChooserContainer>
                    }
                </ImageOrVideoChooserContainer>
                <input style={{ display: "none" }} ref={fileInputRef} accept=".jpg,.jpeg,.png,.gif,.tif" onChange={handleFileChosen} type="file" />
                {imageToEdit && props.imageOptions.editOptions && <SamImage image={imageToEdit} imageOptions={props.imageOptions}
                    onSubmit={image => editImageSubmitted(image)} onCloseModal={() => editImageSubmitted(null)} />
                }
                {imageToUpload && <Spinner />}
            </ImageInputContainer>
        )
    }
}
//---------------------------------------------------
const AddVideoBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px #ccc solid;
    width: 100%;
`
const AddVideoInput = styled.textarea`
    margin-left: 3%;
    width: 90%;
    border: none;
    height: 90px;
`
interface AddVideoBoxProps {
    videoAdded: (image: ImageRecord) => void;
}
const AddVideoBox: React.FC<AddVideoBoxProps> = (props) => {
    const videoInputRef = React.useRef<HTMLTextAreaElement>() as React.MutableRefObject<HTMLTextAreaElement>;

    const saveClicked = () => {
        //   console.log("got video: " + videoInputRef.current.value);
        if (!videoInputRef.current.value) {
            alert('Please enter the YouTube/Vimeo "share" and "embed" text');
        } else {
            const sourceAndId = parseSourceAndIdFromVideo(videoInputRef.current.value);
            if (!sourceAndId) {
                alert('Embed text is incorrect. Please go to youtube.com or vimeo.com and re-copy');
            } else {
                videoInputRef.current.value = '';
                const image: ImageRecord = { stream_id: sourceAndId.streamId, stream_source: sourceAndId.streamSource };
                if (sourceAndId.streamSource === VideoStreamSource.youtube) {
                    image.stream_thumb_src = formatYoutubeThumbUrl(sourceAndId.streamId);
                }
                props.videoAdded(image);
            }
        }
    }
    return (
        <AddVideoBoxContainer>
            <AddVideoInput ref={videoInputRef}
                placeholder='Find video on YouTube or Vimeo, click "share." Copy and paste the provided "Link" or "Embed" text here, then click "Verify video" below to add.' />
            <ButtonsRow>
                <IconButton style={{ fontSize: "12px", height: "26px", marginBottom: "8px" }} caption="Verify video" icon="fas fa-video" onClick={saveClicked} />
            </ButtonsRow>
        </AddVideoBoxContainer>
    )
}
// #endregion DROP OR CHOOSE IMAGE FROM FILE SYSTEM

// #region IMAGE URL FORMATTING
/*
note that width is defined by the size field and height=width*.56 (width=height*1.78)
VIMEO EMBED:
<iframe src="https://player.vimeo.com/video/795195674?h=5f1796465d" width="640" height="320" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
allowfullscreen data-stream-id=ID data-stream-source=SOURCE></iframe>
stream_id field would contain "795195674?h=5f1796465d"
To convert embeded to thumbnail, use vimeo api

YOUTUBE EMBED:
<iframe width="560" height="315" src="https://www.youtube.com/embed/zaR3sVpTB98" title="YouTube video player" frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen data-stream-id=ID data-stream-source=SOURCE></iframe>
stream_id field would contain "zaR3sVpTB98"
YOUTUBE LINK for thumnail:
https://youtu.be/zaR3sVpTB98


images appear in DOM standardized as follows:
    <figure contenteditable="true" oncut="return false" onpaste="return false" 
        style="width: NNpx; margin-left: NNpx; margin-right: NNpx; margin-top: 0; float: FLOAT;">
        IMG_OR_IFRAME
        <figcaption>CAPTION</figcaption>
    </figure>

    IMG_OR_IFRAME: 
    <img style="width: 100%; height: HEIGHT; src="SRC" />
    OR (see above for vimeo/youtube)

    margin-left and margin-right depend on float 
    float is "left" "right" or "none"
    HEIGHT is "auto" for image, "NNpx" for video where NN is width * 1.76
    ID and SOURCE are image.stream_id and image.stream_source
*/

// drag and drop support for images
export const isImageFile = (file: File): boolean => {
    const filename = file.name.toLowerCase();
    const posn = filename.lastIndexOf('.');
    const ext = filename.substring(posn + 1, filename.length);
    return (ext === "jpg" || ext === "png" || ext === "gif" || ext === "jpeg");
}
/*
// optional todo:
// pass embedInFigure true to output iframe inside <figure> element; if false or not passed float and caption are ignored
// , embedInFigure: boolean = false, float: GraphicFloatType = GraphicFloatType.none, caption: string = ''
const formatIframeHtml = (streamSource: VideoStreamSource, streamId: string, width: number): string => {
    const widthStr = width + '';
    const height = width * videoHeightFactor;
    const heightStr = height + '';
    if (streamSource === VideoStreamSource.vimeo) {
        return `<iframe src="https://player.vimeo.com/video/${streamId}" width="${widthStr}" height="${heightStr}" frameborder="0" 
        allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
    } else if (streamSource === VideoStreamSource.youtube) {
        return `<iframe width="${widthStr}" height="${heightStr}" src="https://www.youtube.com/embed/${streamId}" title="YouTube video player" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
    }
    return '';
}
*/
/* return null if invalid html or link
    example embeds:
    (VIMEO):
    <iframe src="https://player.vimeo.com/video/799664919?h=2fe89a8c52" width="640" height="360" frameborder="0" 
    allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <p><a href="https://vimeo.com/799664919">BABY WHAT YOU WANT ME TO DO - BIG ELVIS</a> from 
    <a href="https://vimeo.com/user104338686">Marianne Foppiano</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
    (YOUTUBE):
    <iframe width="560" height="315" src="https://www.youtube.com/embed/zaR3sVpTB98" title="YouTube video player" frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    -------------------------------------------------------
    example links:
    (VIMEO):
    https://vimeo.com/799664919
    (YOUTUBE):
    https://youtu.be/zaR3sVpTB98
*/
export const parseSourceAndIdFromVideo = (embedOrLink: string): { streamSource: VideoStreamSource; streamId: string } | null => {
    let streamSource: VideoStreamSource;
    let startPosn = -1;
    let endPosn = -1;
    let streamId: string | null = null;
    if (embedOrLink.startsWith("https")) {
        // this is a link
        startPosn = embedOrLink.lastIndexOf('/');
        if (startPosn !== -1) {
            startPosn++;
        }
        endPosn = embedOrLink.length;
        streamSource = embedOrLink.startsWith("https://vimeo") ? VideoStreamSource.vimeo : VideoStreamSource.youtube;
        streamId = embedOrLink.substring(startPosn, endPosn);
    } else {
        if (embedOrLink.includes(".vimeo.com")) {
            streamSource = VideoStreamSource.vimeo;
            streamId = parseVideoId(embedOrLink, "vimeo.com/video/");
        } else if (embedOrLink.includes(".youtube.com")) {
            streamSource = VideoStreamSource.youtube;
            streamId = parseVideoId(embedOrLink, "/embed/");
        } else {
            return null;
        }
    }
    if (streamId === null) {
        return null;
    }
    return { streamSource, streamId };
}
const parseVideoId = (embedText: string, key: string,): string | null => {
    let startPosn = embedText.indexOf(key);
    if (startPosn === -1) {
        return null;
    }
    startPosn += key.length;
    if (embedText.length > startPosn) {
        // skip args such as ?h=xyzabc
        const quotePosn = embedText.indexOf('"', startPosn);
        let endPosn = embedText.indexOf('?', startPosn);
        if (endPosn === -1) {
            endPosn = quotePosn;
        }
        if (endPosn > -1) {
            return embedText.substring(startPosn, endPosn);
        }
    }
    return null;
}
// #endregion IMAGE URL FORMATTING

async function fetchVimeoThumbSrc(id: string): Promise<string> {
    const promise = new Promise<string>(function (resolve, reject) {
        fetch(formatVimeoApiUrl(id)).then(response => {
            if (response.ok) {
                response.json().then(json => resolve(json[0].thumbnail_large));
            } else {
                return '';
            }
        })
    });
    return promise;
}
export const loadVimeoThumbs = (streamIds: string[], onDone: (result: Record<string, string>) => void) => {
    const requests = streamIds.map(id => fetchVimeoThumbSrc(id));
    Promise.all(requests)
        .then(responses => {
            const result: Record<string, string> = {};
            for (let i = 0; i < responses.length; i++) {
                result[streamIds[i]] = responses[i];
            }
            onDone(result);
        });
}

// #region IMAGEMGR
/* COMMENTS HERE NEED UPDATING!
    the following can be used to standardize html from external sources, or to parse internally created html for html editor
 * it is called on ALL <img> and <figure> elements by HtmlFormatter class when creating map which is then rendered by the MapRenderer class
    * for external html, all images are assumed to be in <img> elements and videos are not handled:
        float is kept (default to "none")
        if width style is valid it is used as dimension
        if height style is valid it is used as dimension
        data-file is parsed from src attribute; if src starts with "blob" it is respected (this should not happen with external HTML)
    * for internal html, images are children of <figure> elements
    * figure element includes following attributes/styles for images (not necessarily videos):
        height and width styles are used to determine default dimension; width takes priority
        float style is kept, defaults to "none"
        data-file-filename (no domain or subfolders)
        <img> as child of figure is checked for src attribute; it may start with "blob" if the image was inserted from file system
        if <img> contains "alt" attribute it is also kept
    * for videos figure element includes (internal html only):
        data-stream-id=youtube or vimeo id string
        data-stream-source=VideoStreamSource enum ({ youtube = 'Y', vimeo = 'V', none = 'N' })
        data-stream-thumb-src=formatted or fetched src for video thumb (if missing, the src is fetched from vimeo, hence ImageRecord returned as promise
        width style (height is set using standard video aspect ratio)
    * on internally generated html the caption (if any) is formatted as a <figcaption> element child of figure element
    * createImageFromElement takes an <img> or <figure> element and returns its ImageRecord
        if passed <img> it is assumed to be externally generated
        if passed <figure> it is assumed to internally generated and returns image or video
        useful for converting external to internal by storing image record in map and then rendering into standard format
    * IMPORTANT NOTE: Caller must check to see if stream_thumb_src field must be fetched from Vimeo:
            if (image.stream_source === VideoStreamSource.vimeo && !image.stream_thumb_src)
                -- must be fetched in async function --
*/

// for use with pages that display HTML compatible with HTMLDomEditorV2
// to preprocess DOM, call imageMgr.processDom({ options })
// must call setFileOptions() after instantiation or images not supported
export class ImageMgr {
    allowImages: boolean;
    imageOptions: ImageEditorOptions;       // only file options are used here but we keep everything here as source of truth

    constructor() {
        this.imageOptions = {} as ImageEditorOptions;
        this.allowImages = true;
    }
    // if imageOptions undefined, allowImages set to false
    setImageEditorOptions = (imageOptions?: ImageEditorOptions) => {
        if (!deepEqual(this.imageOptions, imageOptions)) {
            console.log("ImageMgr.setImageEditorOptions:", imageOptions)
            this.imageOptions = imageOptions ?? {};
        }
        this.allowImages = !!imageOptions;
    }

    _floatEnumToString = (float: GraphicFloatType): string => {
        if (float === GraphicFloatType.left) {
            return "left";
        }
        if (float === GraphicFloatType.right) {
            return "right";
        }
        return "none";
    }
    _floatToMargins = (float: GraphicFloatType): string => {
        let marginLeft: string;
        let marginRight: string;
        if (float === GraphicFloatType.left) {
            marginLeft = "0";
            marginRight = "8px";
        } else if (float === GraphicFloatType.right) {
            marginLeft = "8px";
            marginRight = "0";
        } else {
            marginLeft = marginRight = "auto";
        }
        return "8px " + marginRight + " 8px " + marginLeft;
    }

    // note that this is used only in dashboards
    // video handling:
    //      videos are rendered as their youtube/vimeo thumbnail
    //      the data-stream-src attribute in figure element contains the thumbnail url
    //      the data-stream-src attribute is initialized when text first loaded; on vimeos it must be fetched async
    //      file is saved out of dashboard without the data-stream-src attribute; when loaded into viewer all videos are rendered as iframes (use ImageFormatter.activateImagesAndVideos)
    // images are rendered with src as formatted from options passed to constructor
    // when file is saved out of dashboard, videos will remain inactive and img src will be removed; use ImageFormatter.activateImagesAndVideos to fix
    // this is called when rendering from map

    /* following styles set on figure:
            float
            margin
            text-align
            width (%, default to 100)
            height (pixels, set if default dimension is height)
            max-width (pixels, default to 600)
     * attributes set on figure:
            contenteditable=false
            data-file (set to img filename)
            data-stream-id (video only)
            data-stream-source (video only)
            data-stream-thumb-src (video only)
     * following styles set on img:
            width (100% or auto)
            height (100% or auto)
     * attributes set on img:
            src
     */
    _imagePropsToStyles = (dimension?: GraphicDimensionType, size?: number, float?: GraphicFloatType, size_pct?: number): { figStyles: StyleRecord, imgStyles: StyleRecord } => {
        const figStyles: StyleRecord = {};
        const imgStyles: StyleRecord = { width: "100%", height: "auto" };   // default to any float OR dimension is width
        figStyles.float = ImageMgr.graphicFloatTypeToString(float);
        figStyles.margin = this._floatToMargins(float ?? GraphicFloatType.none);
        if (float === GraphicFloatType.none) {
            figStyles["text-align"] = "center";
        }
        if (dimension === GraphicDimensionType.height && float === GraphicFloatType.none) {
            imgStyles.width = "auto";
            imgStyles.height = (size ?? 200) + "px";
        } else {
            figStyles.width = (size_pct ?? 100) + "%";
            figStyles["max-width"] = size ? (size + "px") : "100%";
        }
        return { figStyles, imgStyles };
    }

    createFigureElementFromImage = (image: ImageRecord): HTMLElement => {
        const fig = document.createElement('figure');
        fig.setAttribute("contenteditable", "false");
        const img = document.createElement('img');
        if (image.stream_id) {
            // this is a video
            fig.setAttribute("data-stream-id", image.stream_id);
            fig.setAttribute("data-stream-source", image.stream_source + '');
            if (image.stream_thumb_src) {
                // this should have been initialized when text first loaded
                fig.setAttribute("data-stream-thumb-src", image.stream_thumb_src);
                img.setAttribute("src", image.stream_thumb_src);
            }
        } else {
            fig.setAttribute("data-file", image.filename!);
            img.setAttribute("src", formatImageUrl(image.filename!, this.imageOptions.fileOptions));
        }
        const { figStyles, imgStyles } = this._imagePropsToStyles(image.dimension, image.size, image.float, image.size_pct);
        this._setElementStyles(fig, figStyles);
        this._setElementStyles(img, imgStyles);
        fig.appendChild(img);
        if (image.caption) {
            const capElem = document.createElement('figcaption');
            capElem.innerHTML = image.caption;
            const elems = capElem.getElementsByTagName("*");
            for (let i = 0; i < elems.length; i++) {
                (elems[i] as HTMLElement).style.removeProperty("text-align");
            }
            fig.appendChild(capElem);
        }
        return fig;
    }

    _setElementStyles = (elem: HTMLElement, styles: StyleRecord) => {
        for (const style in styles) {
            elem.style.setProperty(style, styles[style]);
        }
    }

    deleteImageOrVideo = (figElem: HTMLElement) => {
        figElem.parentElement?.removeChild(figElem);
    }

    /* map of elements to image record:
            fig.float --> image.float
            fig.margin set on the fly depending on float
            fig.text-align set to center if no float
            fig.width (%, default to 100) --> image.size_pct IF dimension is width
            fig.height (pixels, set if default dimension is height) --> image.size IF dimension is height
            fig.max-width (pixels, default to 600) --> image.size IF dimension is width
            img.width and img.height are set to 100% or auto in img element depending on dimension
            ** attributes **
            fig.data-file --> image.filename IF not video
            fig.data-stream-id --> image.stream_id IF video
            fig.data-stream-source --> image.stream_source IF video (REQUIRED if video)
            fig.data-stream-thumb-src --> image.stream_thumb_src IF video
            img.src is set from fig.data-file
     */
    // fileOptions needed only for default dimension if element is img (if element is figure fileOptions not used)
    createImageFromElement = (elem: HTMLElement, fileOptions?: ImageFileOptions): ImageRecord | null => {
        const fig = elem.nodeName === "FIGURE" ? elem : null;
        const img = elem.nodeName === "IMG" ? elem : elem.getElementsByTagName("img")[0];
        const calcWidthPct = (): number => {
            const width = fig?.style.width ? fig.style.width : img.style.width;
            if (!width) {
                return 100;         // no width specified anywhere
            }
            if (width.endsWith("px")) {
                return Math.min(parseInt(width.replace("px", '')) / elem.parentElement!.clientWidth, 1) * 100;
            } else {
                const widthPct = parseInt(width.replace("%", ''));
                return isNaN(widthPct) ? 100 : widthPct;
            }
        }
        // existing styles:
        const image = { dimension: GraphicDimensionType.width } as ImageRecord;
        image.float = this._floatToGraphicFloatType(fig?.style.float ? fig.style.float : img.style.float);
        const height = fig?.style.height ? fig.style.height : img.style.height;
        // if any height is specified we assume dimension is height
        image.dimension = height !== "100%" && height !== "auto" ? GraphicDimensionType.height : GraphicDimensionType.width;
        if (image.dimension === GraphicDimensionType.height) {
            // image.size will be height and size_pct ignored
            image.size = parseInt(height.replace("px", ''));
        } else {
            image.size_pct = calcWidthPct();
            const figMaxWidth = fig?.style.getPropertyValue("max-width");
            image.size = figMaxWidth ? parseInt(figMaxWidth.replace("px", '')) : elem.parentElement!.clientWidth;
        }
        image.alt = img.getAttribute("alt") ?? undefined;
        const captionElems = elem.getElementsByTagName("figcaption");
        if (captionElems.length) {
            image.caption = captionElems[0].innerHTML;
        }
        // if this is a video load video fields
        if (fig?.getAttribute("data-stream-id")) {
            image.stream_id = fig.getAttribute("data-stream-id") ?? undefined;
            image.stream_source = (fig.getAttribute("data-stream-source") as VideoStreamSource) ?? undefined;
            image.stream_thumb_src = fig.getAttribute("data-stream-thumb-src") ?? undefined;
        } else {
            // finish the image-only fields
            const dataFile = fig?.getAttribute("data-file");
            if (dataFile) {
                image.filename = dataFile;
            } else {
                const src = img.getAttribute("src")!;
                if (src.startsWith("blob")) {
                    image.blob = src;
                }
                image.filename = deformatImageUrl(src, false);
            }
        }
        //   console.log("createImageFromElement returning image:", image)
        return image;
    }
    _floatToGraphicFloatType = (float?: string): GraphicFloatType => {
        if (float === "left") {
            return GraphicFloatType.left;
        }
        if (float === "right") {
            return GraphicFloatType.right;
        }
        return GraphicFloatType.none;
    }
    static graphicFloatTypeToString = (floatType?: GraphicFloatType): string => {
        let float = '';
        if (floatType === GraphicFloatType.left) {
            float = "left";
        } else if (floatType === GraphicFloatType.right) {
            float = "right";
        } else {
            float = "none";
        }
        return float;
    }
}
// #endregion IMAGEMGR

// #region IMAGE OPTIONS EDITOR SUPPORT
// return true if filename has extension supported by Sharp
export const isValidImageFilename = (filename: string): boolean => {
    const posn = filename.lastIndexOf('.');
    if (posn === -1) {
        return false;
    }
    const ext = filename.substring(posn + 1).toLowerCase();
    return ["jpg", "jpeg", "gif", "png", "webp", "avif", "tiff", "tif", "svg"].includes(ext);
}

const sizeWidth = 30;
export const imageEditorOptionsFields: FormFieldRecord[] = [
    { type: FormFieldType.header, label: "Image Attributes Options" },
    {
        name: "captions", type: FormFieldType.combo, label: "Captions", initialValue: CaptionOptionsEnum.allow, comboSource:
            [
                { caption: "Allow", value: CaptionOptionsEnum.allow },
                { caption: "Disallow", value: CaptionOptionsEnum.disallow },
                { caption: "Read only", value: CaptionOptionsEnum.readOnly }
            ]
    },
    { name: "allowResize", type: FormFieldType.checkbox, label: "Allow image resize", initialValue: true },
    { name: "allowFloat", type: FormFieldType.checkbox, label: "Allow text to wrap around image", initialValue: true },

    { type: FormFieldType.header, label: "Image File Options" },
    { name: "graphicsSubfolder", type: FormFieldType.text, label: "Image folder name (under graphics)", initialValue: '' },
    { name: "isCdn", type: FormFieldType.checkbox, label: "Images are on CDN", initialValue: false },
    { type: FormFieldType.break },

    { name: "sizeDesignator", type: FormFieldType.checkbox, label: "Images have _f or _m size designator in file name", initialValue: false },
    { name: "size", type: FormFieldType.int, label: "Maximum width OR default height (pixels)", initialValue: 500, fixedWidth: 40 },
    {
        name: "dimension", type: FormFieldType.combo, label: "Default dimension for pixel sizes", initialValue: GraphicDimensionType.width, comboSource:
            [
                { caption: "Width (up to max)", value: GraphicDimensionType.width },
                { caption: "Height (exact)", value: GraphicDimensionType.height }
            ]
    },

    { type: FormFieldType.header, label: "Captions" },
    { name: "captionFontSize", type: FormFieldType.int, label: "Caption font size", initialValue: 14, fixedWidth: sizeWidth, boldLabel: true },
    { name: "captionFontSize-ipad", type: FormFieldType.int, label: "Ipad", initialValue: 13, fixedWidth: sizeWidth },
    { name: "captionFontSize-mobile", type: FormFieldType.int, label: "Mobile", initialValue: 11, fixedWidth: sizeWidth },
    { name: "captionItalics", type: FormFieldType.checkbox, label: "Italicize captions", initialValue: true },
    {
        name: "captionAlign", type: FormFieldType.combo, label: "Align captions", initialValue: "center", comboSource: [
            { caption: "Left", value: "left" },
            { caption: "Center", value: "center" },
            { caption: "Right", value: "right" }
        ]
    }
    // NOTE: ImageUploadOptions (targetDomain and uploadImageApiUrl are always taken from project files like appData.ts and apiUrl.ts)
];
export const imageEditorOptionsValues = (): Record<string, any> => {
    return FormMgr.extractValuesFromFields(imageEditorOptionsFields);
}

// take ImageEditorOptions (consisting of 2 objects) and flatten into one Record<string, any> suitable for passing into SamForm for editing
export const flattenImageEditorOptions = (values: ImageEditorOptions): Record<string, any> => {
    const result = {} as Record<string, any>;
    for (const prop in values) {
        if (isObject(values[prop as keyof ImageEditorOptions])) {
            for (const embedded in values[prop as keyof ImageEditorOptions]) {
                result[embedded] = (values[prop as keyof ImageEditorOptions] as any)[embedded];
            }
        } else {
            result[prop] = values[prop as keyof ImageEditorOptions];
        }
    }
    return result;
}
// split out the values after editing, into their proper embedded objects suitable for using and passing to api
// following does not include ImageUploadOptions (that is for dashboard use only and is taken from project files)
type SubrecordType = ImageAttributesEditOptions | ImageFileOptions;
export const splitImageEditorOptions = (values: Record<string, any>): ImageEditorOptions => {
    const options = { editOptions: {} as ImageAttributesEditOptions, fileOptions: {} as ImageFileOptions } as ImageEditorOptions;
    let isAttributes = true;
    for (let i = 1; i < imageEditorOptionsFields.length; i++) {

        if (imageEditorOptionsFields[i].type === FormFieldType.header) {
            isAttributes = false;
            continue;
        }
        const subrecord = options[isAttributes ? "editOptions" : "fileOptions"] as SubrecordType;
        if (imageEditorOptionsFields[i].name) {
            subrecord[imageEditorOptionsFields[i].name as keyof SubrecordType] = values[imageEditorOptionsFields[i].name!] as keyof SubrecordType;
        }
    }
    return options;
}
// #endregion IMAGE OPTIONS EDITOR SUPPORT

export default SamImage;
