const invalidUrl = "{}\\^[]`;/?:@&=+$,<>#%<>'\"";
export const fixUrl = (url: string): string => {
    let fixed = '';
    for (let i = 0; i < url.length; i++) {
        const code = url.charCodeAt(i);
        if (!(invalidUrl.includes(url[i]) || (code >= 0 && code <= 31) || code >= 127)) {
            if (url[i] === ' ') {
                fixed += '-';
            } else {
                fixed += url[i].toLowerCase();
            }
        }
    }
    return fixed;
}
export const extractFilenameFromUrl = (url: string): string => {
    const posn = url.lastIndexOf('/');
    const endPosn = url.lastIndexOf('.');
    return fixUrl(url.substring(posn + 1, endPosn));
}

export const trimLeadingSlash = (url: string) => {
    if (url.startsWith('/')) {
        url = url.slice(1);
    }
    return url;
}

export const insertLeadingSlash = (url: string) => {
    if (!url.startsWith('/')) {
        url = '/' + url;
    }
    return url;
}