import LZString from "lz-string";
import deepcopy from "deepcopy";

import { TextMapRecord, TextSelectionRecord } from "./DomFormatter";

const MaxUndo = 100;

// this is what gets compressed and saved, and return on rollback
export interface UndoRecord {
    map: TextMapRecord[];
    selection: TextSelectionRecord;
}

class UndoMgr {
    commits: string[];
    firstCommit: string;

    constructor() {
        this.commits = [];
        this.firstCommit = '';
    }

    reset = () => {
        this.commits = [];
        this.firstCommit = '';
   }

    commit = (map: TextMapRecord[], selection: TextSelectionRecord) => {
        if (!("anchorIndex" in selection)) {
            console.log("undoMgr.commit: NO ANCHORINDEX in selection");
            return;
        }
        const commitSelection = deepcopy(selection);
        delete commitSelection.anchorNode;
        delete commitSelection.focusNode;
        commitSelection.scrollX = window.scrollX;
        commitSelection.scrollY = window.scrollY;
    //    console.log("committing:", { map: deepcopy(map), commitSelection });
        const commitText = LZString.compress(JSON.stringify({ map, selection: commitSelection }));
        if (this.commits.length && this.commits[this.commits.length - 1] === commitText) {
            return;
        }
        if (this.commits.length === 0) {
            this.firstCommit = commitText;
        }
        this.commits.push(commitText);
        if (this.commits.length > MaxUndo) {
            this.commits.shift();
        }
    }
    rollback = (): UndoRecord | null => {
        let commitText = this.commits.pop();
        if (!commitText) {
            commitText = this.firstCommit;
        }
        let result = JSON.parse(LZString.decompress(commitText)!);
  //      console.log("rollback returning:", result);
        return result;
    }
}

export default UndoMgr;
