const login = "/api/dashboardLogin/";       // + app.domain
const changePassword = "/api/changePassword";

const fetchContent = "/api/getContent/";    // + domain/page/isDashboard
const postContent = "/api/setContent";
const approveContent = "/api/dashboard/push/";      // + page;       // push changes to web
const cancelChanges = "/api/dashboard/clear-dashboard/";    // + page;
const uploadImage = "/api/uploadImage";

// following not used
const getInfoPageSettings = '';
const uploadImagesFromUrls = '';

export default {
    login,
    changePassword,
    fetchContent,
    postContent,
    approveContent,
    cancelChanges,
    uploadImage,

    getInfoPageSettings,
    uploadImagesFromUrls

}

