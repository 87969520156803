// following contains style specific code to support InfoPage.tsx
// used by StyleMgr and HtmlDomEditor but not dependent on them
import deepEqual from 'deep-equal';
import deepcopy from 'deepcopy';

import FormMgr from "./forms/FormMgr";

import { FormFieldRecord, FormFieldType, InfoPageStylesRecord, MediaValueRecord, StyleRecord } from "../interfaces/lib-api-interfaces"
import { iPadMaxWidth, iPhoneWidth, mergeOptionsRecords } from "./libSupport";
import { defaultCaptionStyles } from './ImageFormatter';

const mobileMediaBreak = 414;
const ipadMediaBreak = 834;

// key=style key or css selector under "global" class name
// if value is string, key must be a style to apply globally; if value is StyleRecord, key must be the css selector (e.g., "p" or "figCaption > p")

interface GlobalStylesMapRecord {
    key: string;                // joins to InfoPageStylesRecord, or "@" if initial hardcoded style
    style: string;
    defaultValue?: string;      // applies if key is "@" (initial style, like resetting css)
    target?: string;
    isMedia?: boolean;          // all values are pixels
    valueIfTrue?: string;
    valueIfFalse?: string;
    important?: boolean;
}
export enum MediaType { default = 1, ipad = 2, mobile = 3 }

class InfoPageStyles {
    currentStyles: InfoPageStylesRecord;
    rootDiv: HTMLDivElement;
    static resizeEventActive = false;

    constructor(rootDiv: HTMLDivElement) {
        this.rootDiv = rootDiv;
        this.currentStyles = {} as InfoPageStylesRecord;
        this.setGlobalStyles(); // this sets current styles to default -- caller should call this again when real styles are available
    }

    _globalStylesMap: GlobalStylesMapRecord[] = [
        { key: "@", style: "font-weight", defaultValue: "normal" },
        { key: "@", style: "font-style", defaultValue: "normal" },
        { key: "@", style: "text-decoration", defaultValue: "none" },
        { key: "@", style: "text-align", defaultValue: "left" },
        { key: "@", style: "overflow-y", defaultValue: "auto" },
        { key: "@", style: "width", defaultValue: "100%" },
        { key: "@", style: "margin", target: "p, div", defaultValue: "0" },
        { key: "@", style: "text-align", target: "h1", defaultValue: "center" },
        { key: "@", style: "font-weight", target: "h1", defaultValue: "bold" },

        { key: "fontFamily", style: "font-family" },
        { key: "color", style: "color" },
        { key: "fontSize", style: "font-size", isMedia: true },
        { key: "lineHeight", style: "line-height", isMedia: true },
        { key: "paragraphSpacing", style: "margin-bottom", target: "p, div, h1", isMedia: true },
        { key: "captionFontSize", style: "font-size", target: "figcaption", isMedia: true },
        { key: "captionItalics", style: "font-style", target: "figcaption", valueIfTrue: "italic", valueIfFalse: "normal" },
        { key: "captionAlign", style: "text-align", target: "figcaption" },
        { key: "headerFontFamily", style: "font-family", target: "h1" },
        { key: "headerFontSize", style: "font-size", target: "h1", isMedia: true },
        { key: "linkColor", style: "color", target: "a, a:visited" },
        { key: "linkUnderline", style: "text-decoration", target: "a, a:visited", valueIfTrue: "underline", valueIfFalse: "none", important: true },
        { key: "linkItalics", style: "font-style", target: "a, a:visited", valueIfTrue: "italic", valueIfFalse: "normal" },
        { key: "linkBold", style: "font-weight", target: "a, a:visited", valueIfTrue: "bold", valueIfFalse: "normal" }
    ];

    // for use by InfoPageSettingsEditor
    static sizeWidth = 26;
    static globalStylesFields: FormFieldRecord[] = [
        { type: FormFieldType.header, label: "Fonts" },
        { name: "fontFamily", type: FormFieldType.fontFamily, label: "Font family", initialValue: "Arial" },
        { name: "color", type: FormFieldType.color, label: "Color", initialValue: "#000" },
        { name: "fontSize", type: FormFieldType.int, label: "Font size", initialValue: 16, fixedWidth: this.sizeWidth, boldLabel: true },
        { name: "fontSize-ipad", type: FormFieldType.int, label: "Ipad", initialValue: 14, fixedWidth: this.sizeWidth },
        { name: "fontSize-mobile", type: FormFieldType.int, label: "Mobile", initialValue: 12, fixedWidth: this.sizeWidth },
        { type: FormFieldType.break },

        { name: "headerFontFamily", type: FormFieldType.fontFamily, label: "Header font family", initialValue: "Arial" },
        { name: "headerFontSize", type: FormFieldType.int, label: "Header font size", initialValue: 32, fixedWidth: this.sizeWidth, boldLabel: true },
        { name: "headerFontSize-ipad", type: FormFieldType.int, label: "Ipad", initialValue: 24, fixedWidth: this.sizeWidth },
        { name: "headerFontSize-mobile", type: FormFieldType.int, label: "Mobile", initialValue: 18, fixedWidth: this.sizeWidth },

        { type: FormFieldType.header, label: "Spacing (pixels)" },
        { name: "lineHeight", type: FormFieldType.int, label: "Linespacing", initialValue: 24, fixedWidth: this.sizeWidth, boldLabel: true },
        { name: "lineHeight-ipad", type: FormFieldType.int, label: "Ipad", initialValue: 21, fixedWidth: this.sizeWidth },
        { name: "lineHeight-mobile", type: FormFieldType.int, label: "Mobile", initialValue: 16, fixedWidth: this.sizeWidth },
        { name: "paragraphSpacing", type: FormFieldType.int, label: "Paragraph extra", initialValue: 12, fixedWidth: this.sizeWidth, boldLabel: true },
        { name: "paragraphSpacing-ipad", type: FormFieldType.int, label: "Ipad", initialValue: 10, fixedWidth: this.sizeWidth },
        { name: "paragraphSpacing-mobile", type: FormFieldType.int, label: "Mobile", initialValue: 8, fixedWidth: this.sizeWidth },

        { type: FormFieldType.header, label: "Links" },
        { name: "linkColor", type: FormFieldType.color, label: "Link color", initialValue: "#00e" },
        { name: "linkUnderline", type: FormFieldType.checkbox, label: "Underline links", initialValue: true },
        { name: "linkItalics", type: FormFieldType.checkbox, label: "Italicize links", initialValue: false },
        { name: "linkBold", type: FormFieldType.checkbox, label: "Links in bold", initialValue: false },
    ];
    // returns system default global styles
    static globalStylesDefaultValues = (): InfoPageStylesRecord => {
        return mergeOptionsRecords([FormMgr.extractValuesFromFields(InfoPageStyles.globalStylesFields), defaultCaptionStyles()]) as InfoPageStylesRecord;
    }

    // convert infoPageStyles as stored with document into innerHTML for <style> element and apply it to current document which starts at root
    // use defaults if not passed
    // NOTE: globalStyles is InfoPageStylesRecord which means media values are combined into MediaValueRecord
    // call this ONCE to init (html content does not matter)
    // this stores styles; use applyGlobalStyles to make them effective on content
    setGlobalStyles = (globalStyles?: InfoPageStylesRecord) => {
        this.currentStyles = globalStyles ?? InfoPageStyles.globalStylesDefaultValues();
        if (!InfoPageStyles.resizeEventActive) {        // this should never be true as setGlobalStyles should only be called once :)
            addEventListener("resize", this.applyGlobalStyles);
            InfoPageStyles.resizeEventActive = true; 
        }
   }

    // physically apply global styles, including targeted and media
    // call this whenever styles change
    // necessary because Chrome does not respect targeted and media styles in header style element
    // NOTE: Must be called AFTER setGlobalStyles
    applyGlobalStyles = () => {
        let mediaType: MediaType;
        if (window.innerWidth < mobileMediaBreak) {
            mediaType = MediaType.mobile;
        } else if (window.innerWidth < ipadMediaBreak) {
            mediaType = MediaType.ipad;
        } else {
            mediaType = MediaType.default;
        }
        // set global styles on editor div
        let styles = this.getGlobalStylesWithTargetsAndMedia(mediaType, '');
        this._applyStylesToElement(this.rootDiv, styles);
        const allTargets = this._compileTargetList();
        const elements = this.rootDiv.getElementsByTagName("*");
        for (let i = 0; i < elements.length; i++) {
            for (const target of allTargets) {
                if (target === elements[i].tagName.toLowerCase()) {
                    styles = this.getGlobalStylesWithTargetsAndMedia(mediaType, target);
                    if (Object.keys(styles).length) {
                        this._applyStylesToElement(elements[i] as HTMLElement, styles);
                    }
                }
            }
        }
    }
    _applyStylesToElement = (element: HTMLElement, styles: StyleRecord) => {
        for (const style in styles) {
            element.style.setProperty(style, styles[style]); 
        }
    }

    // called from StyleMgr to filter out targeted styles
    getGlobalStylesWithTargetsAndMedia = (mediaType: MediaType, target: string): StyleRecord => {
        //    console.log("_getFilteredMapAsStyles: globalStyles:", globalStyles)
        const styles = {} as StyleRecord;
        for (const mapRecord of this._globalStylesMap) {
            if (this._filterMapRecord(mapRecord, mediaType, target)) {
                let value: string;
                if (mapRecord.defaultValue) {
                    // default values only apply to hard coded styles so it cannot be included in globalStyles
                    value = mapRecord.defaultValue;
                } else {
                    const globalStyleValue = this.currentStyles[mapRecord.key as keyof InfoPageStylesRecord];
                    //         console.log({record: mapRecord.key, globalStyleValue: globalStyles[mapRecord.key]})
                    if (typeof globalStyleValue === "object") {
                        // dealing with media
                        if (mediaType === MediaType.ipad) {
                            value = globalStyleValue.ipad + "px";
                        } else if (mediaType === MediaType.mobile) {
                            value = globalStyleValue.mobile + "px";
                        } else {
                            value = globalStyleValue.default + "px";
                        }
                    } else if (mapRecord.valueIfTrue) {
                        value = globalStyleValue ? mapRecord.valueIfTrue! : mapRecord.valueIfFalse!;
                    } else {
                        value = globalStyleValue as string;
                    }
                }
                // following needed only when styles are being set in page header; we don't do that since Chrome does not support
                // if (mapRecord.important) {
                //     value += " !important";
                // }
                styles[mapRecord.style] = value;
            }
        }
        return styles;
    }

    /*
        if (!deepEqual(styles, this.currentStyles)) {
            const allTargets = this._compileTargetList();
            let innerHtml = "." + this.rootDivClassname + " { ";
            innerHtml += this._formatStylesFromMap(MediaType.default, allTargets, styles);      // add nontargeted, then targeted; if value needs media call take default
            innerHtml += "@media (max-width: " + iPadMaxWidth + "px) ";
            innerHtml += "{ " + this._formatStylesFromMap(MediaType.ipad, allTargets, styles) + " }";      // add nontargeted, then targeted for media call ipad ONLY
            innerHtml += "@media (max-width: " + iPhoneWidth + "px) ";
            innerHtml += "{ " + this._formatStylesFromMap(MediaType.mobile, allTargets, styles) + " }";      // add nontargeted, then targeted for media call mobile/iphone ONLY
            innerHtml += "} ";
            //        console.log("setGlobalStyles setting innerHTML:", innerHtml)
            this.stylesElement.innerHTML = innerHtml;
            this.currentStyles = styles;
        }
    }
     _formatStylesFromMap = (mediaType: MediaType, allTargets: string[]): string => {
        // grab untargeted for this media type
        let html = '';
        let styles = this.getGlobalStylesWithTargetsAndMedia(mediaType, '');        // requested media, nontargeted
        html += this._formatStyles(styles);
        for (const target of allTargets) {
            styles = this.getGlobalStylesWithTargetsAndMedia(mediaType, target);
            if (Object.keys(styles).length) {
                html += target + " { " + this._formatStyles(styles) + " } ";
            }
        }
        return html;
    }

   */

    // return distinct list of all possible targeted elements
    _compileTargetList = (): string[] => {
        const list: string[] = [];
        for (const mapRecord of this._globalStylesMap) {
            if (mapRecord.target) {
                const targets = mapRecord.target.replaceAll(' ', '').split(',');
                for (const target of targets) {
                    if (!list.includes(target)) {
                        list.push(target);
                    }
                }
            }
        }
        return list;
    }

    _filterMapRecord = (mapRecord: GlobalStylesMapRecord, mediaType: MediaType, target: string): boolean => {
        if (mediaType !== MediaType.default && !mapRecord.isMedia) {
            return false;       // looking for media values only
        }
        if ((mapRecord.target && !target) || (!mapRecord.target && target)) {
            return false;       // looking for targeted or not looking for targeted
        }
        if (!mapRecord.target) {
            return true;        // thanks to above filter we are looking for non-targeted so keep this one
        }
        // at this point we know mapRecord is targeted so see if it agrees with passed in target
        const targets = mapRecord.target.replaceAll(' ', '').split(',');
        if (targets.includes(target)) {
            return true;
        }
        return false;
    }

    _formatStyles = (styles: StyleRecord): string => {
        let text = '';
        for (const style in styles) {
            text += style + ": " + styles[style] + "; ";
        }
        return text;
    }

    static unCamelCase = (text: string): string => {
        let uncamel = '';
        for (const c of text) {
            if (c.toUpperCase() === c) {
                uncamel += '-';
            }
            uncamel += c.toLowerCase();
        }
        return uncamel;
    }

}
export default InfoPageStyles;


/*
export interface GlobalStyleRecord {
target?: string;        // if InfoPageStylesRecord value targets specific elements this is the element tag name,/.
style: string;          // style callout for css
value?: string;                     // required unless mediaValues is valid
mediaValues?: MediaValueRecord;     // overrides value if media applies
}
interface GlobalStyleTemplateRecord {
target?: string;            // see above
style: string;              // see above
value?: string;             // the default style value (if not provided there must be a globalStylesKey used to grab value from styles as found in DB)
globalStylesKey?: string;   // joins to InfoPageStylesRecord; also the field name in globalStylesFields
isPixel?: boolean;          // value is in pixels (need to append "px")
valueIfTrue?: string;       // value if InfoPageStylesRecord value is true
valueIfFalse?: string;      // ditto if false
important?: boolean;
}
// the following is control array used to convert InfoPageStylesRecord (as stored in DB) to a css object to insert into HTML header
// NOTE: When changing below don't forget to change InfoPageStylesRecord also
_globalStylesTemplate: GlobalStyleTemplateRecord[] = [
    { style: "font-weight", value: "normal" },
    { style: "font-style", value: "normal" },
    { style: "text-decoration", value: "none" },
    { style: "text-align", target: "p", value: "left" },
    { style: "margin", target: "p", value: "0" },
    { style: "text-align", target: "div", value: "left" },
    { style: "margin", target: "div", value: "0" },
    { style: "margin", target: "figcaption > p", value: "0" },
    { style: "text-align", target: "h1", value: "center" },
    { style: "font-weight", target: "h1", value: "bold" },
];
// convert infoPageStyles as stored with document into array of targeted styles for applying to elements
// call this whenever global styles change (when loading new infoPage or user edits them in HtmlDomEditor)
// this array is stored in StyleMgr and used when mapping document
buildGlobalStyles = (globalStyles: InfoPageStylesRecord): GlobalStyleRecord[] => {
    const styles: GlobalStyleRecord[] = [];
    for (const globalStyle of this._globalStylesTemplate) {
        const record = { target: globalStyle.target, style: globalStyle.style } as GlobalStyleRecord;
        const value = globalStyle.globalStylesKey ? globalStyles[globalStyle.globalStylesKey] : globalStyle.value;
        if (globalStyle.isPixel) {
            record.value = value + "px";
        } else if (globalStyle.valueIfTrue) {
            record.value = value ? globalStyle.valueIfTrue : globalStyle.valueIfFalse!;
        } else {
            record.value = value;
        }
        if (globalStyle.important) {
            record.value += " !important";
        }
        styles.push(record);
    }
    return styles;
}
// return distinct list of target tag names
_getDistinctGlobalStyleTags = (): string[] => {
    const tags: string[] = [];
    for (const rec of this._globalStylesTemplate) {
        if (rec.target && !tags.includes(rec.target)) {
            tags.push(rec.target);
        }
    }
    return tags;
}
// always return styles not targeted; plus override these with target if any
// to NOT return styles not targeted (i.e., return ONLY tagged styles) pass isExclusive=true
getGlobalStylesByTag = (records: GlobalStyleRecord[], tag: string, isExclusive?: boolean): StyleRecord => {
    const styles = {} as StyleRecord;
    for (const record of records) {
        if (!isExclusive && (!record.target || record.target === tag)) {
            styles[record.style] = record.value;
        } else if (record.target === tag) {
            styles[record.style] = record.value;
        }
        if (record.style === "color" && styles[record.style]) {
            styles[record.style] = standardizeColorStyle(styles[record.style]);
        }
    }
    return styles;
}
*/
