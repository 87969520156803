import React from 'react';
import styled from 'styled-components';

import useTooltips from '../../useTooltips';
import ColorfulPicker, { CssColor } from '../../forms/ColorfulPicker';
import ContextMenu, { ContextMenuInfo, ContextMenuItem } from '../../SamContextMenu';
import { getToolMapRecord, initStyleRecord, parsePixelValueFromStyle, ToolbarCommandOptions, ToolbarDisplayType, toolBarMap, ToolCodeEnum, ToolMapRecord } from './StyleMgr';
import FontPicker, { FontCalloutRecord, calloutRecordToStyleValue, styleValueToCalloutRecord } from '../../forms/FontPicker';
import SamForm from '../../forms/SamFormV5';
import FormMgr from '../../forms/FormMgr';
import { useGlobalContext } from '../../SamState';
import SamModal from '../../SamModalV2';

import { FormFieldRecord, FormFieldType, StyleRecord } from '../../../interfaces/lib-api-interfaces';

import app from '../../../appData';
import InfoPageStyles from '../../InfoPageStyles';
import IconButton from '../../IconButtonV2';

const ToolbarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: white;
    margin-bottom: 16px;
`
const ToolbarRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`
const ToolContainer = styled.div<{ $selected: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.$selected ? "aqua" : "white"};
    margin-left: 6px;
    height: 34px;
    font-size: 24px;
    border: 1px solid #ccc;
    cursor: pointer;
`
const ToolOverlay = styled.div`
    position: absolute;
    top: 8px;
    left: 6px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    cursor: pointer;
    z-index: 10;
`
export type SetToolbarSelectedStylesFunction = (styles: StyleRecord) => void;
export type InitToolbarCallback = (setToolbarSelectedStyles: SetToolbarSelectedStylesFunction) => void;

export interface VerticalSpacingRecord {
    // all values in pixels; for defaults all must specified; after submission only changed ones are specified
    fontSize?: number;
    lineHeight?: number;
    paragraphSpacing?: number;       // this is EXTRA spacing (margin-bottom value)
    [key: string]: any;
}
// source of truth for selected styles is in DomFormatter
export interface HtmlDomEditorToolbarProps {
    excluded?: ToolCodeEnum[];
    selectedStyles: StyleRecord;
    pasteType: ToolCodeEnum;
    settingsNames?: string[];       // if passed user can change the current template and choose to edit settings
    selectedSettings?: string;      // required if settingsNames passed
    // value applies to fontSize, color, link, options; if passed as ToolCodeEnum (int) then it must be a submenu selection
    getOrSetPasteTool: (tool?: ToolCodeEnum) => ToolCodeEnum;       // call into parent; if tool passed it is being set
    toolClicked: (styles: StyleRecord, mapRecord: ToolMapRecord) => void;     // value applies to fontSize, color, link, options
}
const HtmlDomEditorToolbar = (props: HtmlDomEditorToolbarProps) => {
    const [showSubmenu, setShowSubmenu] = React.useState<ContextMenuInfo>();
    const [showModalDialog, setShowModalDialog] = React.useState<ToolCodeEnum>();
    const [showSettingsRow, setShowSettingsRow] = React.useState(false);

    const toolbarRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const settingsDropdownRef = React.useRef<HTMLSelectElement>() as React.MutableRefObject<HTMLSelectElement>;

    const { registerTooltip } = useTooltips();

    const selectedSettings = props.selectedSettings ?? '';

    const getToolRecordFromId = (id: string): ToolMapRecord | undefined => {
        if (id?.startsWith("bar")) {
            const code = parseInt(id.substring(3)) as ToolCodeEnum;
            return getToolMapRecord(code);
        }
    }
    React.useEffect(() => {
        const divs = toolbarRef.current.getElementsByTagName('div');
        for (let i = 0; i < divs.length; i++) {
            const record = getToolRecordFromId(divs[i].id);
            if (record) {
                const tip = record.tooltip;
                if (tip) {
                    registerTooltip(divs[i], tip);
                }
            }
        }
    }, []);

    React.useEffect(() => {
        if (selectedSettings && settingsDropdownRef.current) {
            settingsDropdownRef.current.value = selectedSettings;
        }
    }, [selectedSettings]);

    const fontSelected = (callout: FontCalloutRecord | null) => {
        if (callout) {
            const mapRecord = getToolMapRecord(ToolCodeEnum.fontFamily)!;
            props.toolClicked(initStyleRecord(mapRecord!.styles![0], calloutRecordToStyleValue(callout)), mapRecord);
        }
    }

    const editSettingsClicked = () => {
        const mapRecord = getToolMapRecord(ToolCodeEnum.settings)!;
        props.toolClicked({}, mapRecord);       // empty styles record means edit settings
    }
    const settingsDropdownChanged = (e: React.ChangeEvent) => {
        const mapRecord = getToolMapRecord(ToolCodeEnum.settings)!;
        props.toolClicked(initStyleRecord(mapRecord!.styles![0], (e.target as HTMLSelectElement).value), mapRecord);       // "settings" prop is the new settings value
    }

    const colorSubmitted = (color: CssColor | null) => {
        setShowModalDialog(undefined);
        if (color) {
            const mapRecord = getToolMapRecord(ToolCodeEnum.color)!;
            props.toolClicked(initStyleRecord(mapRecord!.styles![0], color.toRgbString()), mapRecord);
        }
    }

    const pasteToolToChar = (pasteTool: ToolCodeEnum): string => {
        let result;
        if (pasteTool === ToolCodeEnum.pasteFormatted) {
            result = 'F';
        } else if (pasteTool === ToolCodeEnum.pasteLiteral) {
            result = 'L';
        } else {
            result = 'U';
        }
        return result;
    }

    const submenuClicked = (mapRecord: ToolMapRecord, submenuCode: ToolCodeEnum) => {
        setShowSubmenu(undefined);
        if (mapRecord.toolCode === ToolCodeEnum.paste) {
            props.getOrSetPasteTool(submenuCode);
        } else {
            props.toolClicked(initStyleRecord("clean", ''), getToolMapRecord(submenuCode)!);
        }
    }

    // use this for simple emphasis and alignment (not font-family, font-size or color)
    const toolClicked = (e: React.MouseEvent<HTMLDivElement>, toolRecord: ToolMapRecord) => {
        console.log("Toolbar.TOOLCLICKED:", { e, toolRecord });
        e.stopPropagation();
        if (toolRecord.displayType === ToolbarDisplayType.submenu) {
            const submenu: ContextMenuItem[] = [];
            for (const item of toolRecord.submenu!) {
                submenu.push({ caption: item.label, onClick: userData => submenuClicked(toolRecord, userData), userData: item.code });
            }
            setShowSubmenu(new ContextMenuInfo({ x: e.clientX, y: e.clientY }, submenu));
        } else if (toolRecord.displayType === ToolbarDisplayType.modalDialog) {
            setShowModalDialog(toolRecord.toolCode);
        } else if (toolRecord.displayType === ToolbarDisplayType.toolbarRow) {
            if (toolRecord.toolCode === ToolCodeEnum.settings) {
                setShowSettingsRow(!showSettingsRow);
            }
        } else if ((toolRecord.options & ToolbarCommandOptions.isEmphasis) !== 0) {
            // clicked an emphasis tool so pass the opposite of current style
            const style = toolRecord.styles![0];
            const value = toolRecord.setValue === props.selectedStyles[style] ? toolRecord.clearValue : toolRecord.setValue;
            props.toolClicked(initStyleRecord(style, value!), toolRecord);
        } else if ((toolRecord.options & ToolbarCommandOptions.passThrough) !== 0) {
            props.toolClicked({}, toolRecord);
        } else {
            // not a toggle so pass the tool's style and  set value
            props.toolClicked(initStyleRecord(toolRecord.styles![0], toolRecord.setValue!), toolRecord);
        }
    }

    /* example usage:
    const [contextMenuInfo, setContextMenuInfo] = React.useState();
    in component to handle right click: onContextMenu={contextMenuInvoked} (this is a React event)
        const contextMenuInvoked = e => {
        let contextMenu = [];
        if (props.allowInsert) {
            contextMenu.push(new ContextMenuItem("Insert new row here", handleInsertRow, row));
            contextMenu.push(new ContextMenuItem("Add row to end", handleInsertRow, -1));
        }
        if (contextMenu.length) {
            e.preventDefault();
            setContextMenuInfo(new ContextMenuInfo({ x: e.clientX, y: e.clientY }, contextMenu));
        }
    }
    in render:
        {contextMenuInfo && <ContextMenu info={contextMenuInfo} closePopup={() => setContextMenuInfo(null)} />}
    */

    const fontSpacingSubmitted = (values: VerticalSpacingRecord | null) => {
        const setValueIfPresent = (style: string, spacingValues: VerticalSpacingRecord, styles: StyleRecord) => {
            if (style in spacingValues) {
                styles[InfoPageStyles.unCamelCase(style)] = spacingValues[style] + "px";
            }
        }
        setShowModalDialog(undefined);
        if (values) {
            const record = {} as VerticalSpacingRecord;
            if (values.fontSize !== record.fontSize) {
                record.fontSize = values.fontSize;
            }
            if (values.lineHeight !== record.lineHeight) {
                record.lineHeight = values.lineHeight;
            }
            if (Object.keys(values).length) {
                const styles = {} as StyleRecord;
                setValueIfPresent("fontSize", values, styles);
                setValueIfPresent("lineHeight", values, styles);
                props.toolClicked(styles, getToolMapRecord(ToolCodeEnum.fontSpacing)!);
            }
        }
    }
    const paragraphSpacingSubmitted = (values: VerticalSpacingRecord | null) => {
        setShowModalDialog(undefined);
        if (values) {
            if (values.paragraphSpacing !== values.paragraphSpacing) {
                const styles = {} as StyleRecord;
                const mapRecord = getToolMapRecord(ToolCodeEnum.paragraphSpacing)!;
                styles[mapRecord!.styles![0]] = values.paragraphSpacing + "px";
                props.toolClicked(styles, mapRecord);
            }
        }
    }


    // const selectedStyles = props.domEditor.selectedStyles ?? {};
    // console.log("toolbar: selectedStyles:", selectedStyles);
    const currFont = (props.selectedStyles["font-family"]) ? styleValueToCalloutRecord(props.selectedStyles["font-family"]) : { family: '' } as FontCalloutRecord;
    //   console.log("RENDERING TOOLBAR: paste type=" + props.pasteType)
    return (
        <ToolbarContainer ref={toolbarRef}>
            <ToolbarRow>
                {toolBarMap.map(entry => {
                    return (
                        !props.excluded?.includes(entry.toolCode) &&
                        <>
                            {entry.icon ? (
                                <ToolContainer key={entry.toolCode} id={"bar" + entry.toolCode}
                                    $selected={!!entry.setValue && !!props.selectedStyles[entry.styles![0]] && props.selectedStyles[entry.styles![0]].startsWith(entry.setValue)}
                                    onClick={(e: React.MouseEvent<HTMLDivElement>) => toolClicked(e, entry)}
                                >
                                    <i className={entry.icon} />
                                    {entry.toolCode === ToolCodeEnum.paste && <ToolOverlay>{pasteToolToChar(props.getOrSetPasteTool())}</ToolOverlay>}
                                </ToolContainer>
                            ) : (
                                entry.toolCode === ToolCodeEnum.fontFamily && (
                                    <FontPicker id={"bar" + ToolCodeEnum.fontFamily} selectedCallout={currFont}
                                        fontSubmitted={fontSelected} />
                                )
                            )}
                        </>
                    )
                })}
            </ToolbarRow>
            {showSettingsRow && props.settingsNames &&
                <ToolbarRow>
                    <label>Active settings:</label>
                    <select ref={settingsDropdownRef} onChange={settingsDropdownChanged}>
                        {props.settingsNames.map(name => {
                            return (
                                <option>{name}</option>
                            )
                        })}
                    </select>
                    <IconButton caption="Edit settings" onClick={editSettingsClicked} />
                </ToolbarRow>
            }
            {showSubmenu && <ContextMenu info={showSubmenu} closePopup={() => setShowSubmenu(undefined)} />}
            {showModalDialog === ToolCodeEnum.color && <ColorfulPicker color={props.selectedStyles["color"]} colorSubmitted={colorSubmitted} />}
            {showModalDialog === ToolCodeEnum.fontSpacing && <VerticalSpacingModal selectedStyles={props.selectedStyles} toolCode={showModalDialog}
                onSubmit={fontSpacingSubmitted} />}
            {showModalDialog === ToolCodeEnum.paragraphSpacing && <VerticalSpacingModal selectedStyles={props.selectedStyles} toolCode={showModalDialog}
                onSubmit={paragraphSpacingSubmitted} />}
        </ToolbarContainer>
    )
}
//-----------------------------------------------------------------------
/* to support viewing spacing properties upon hover
const VerticalSpacingPopupContainer = styled.div<{ $left: number; $top: number }>`
            z-index: 2000;
            padding: 16px;
            font-size: 14px;
            position: fixed;
            font-family: ${app.themes.sansFonts};
            left: ${props => props.$left}px;
            top: ${props => props.$top}px;
            background-color: ${app.themes.backColor50};
            display: flex;
            flex-direction: column;
            p {
                margin: 4px;
    }
            `
*/
const VerticalSpacingModalContainer = styled.div`
    background-color: ${app.themes.backColor50};
    font-family: ${app.themes.sansFonts};
    display: flex;
    flex-direction: column;
    p {
        font - weight: bold;
        text-align: center;
        margin-bottom: 4px;
    }
`
interface VerticalSpacingModalProps {
    selectedStyles: StyleRecord;
    toolCode: ToolCodeEnum;         // fontSpacing or paragraphSpacing
    onSubmit: (values: VerticalSpacingRecord | null) => void;
}
const VerticalSpacingModal: React.FC<VerticalSpacingModalProps> = (props) => {
    const { setContext } = useGlobalContext();

    const formId = "verticalSpacing";

    React.useEffect(() => {
        // clear forms cache in case we've shown this before
        const forms = new FormMgr(setContext);
        forms.clearForms();
    }, []);

    const fontSize = parsePixelValueFromStyle(props.selectedStyles["font-size"]);
    const initialValues = {
        fontSize,
        lineHeight: parsePixelValueFromStyle(props.selectedStyles["line-height"]!) / fontSize,
        paragraphSpacing: parsePixelValueFromStyle(props.selectedStyles["margin-bottom"]!) / fontSize
    };

    const fontFields: FormFieldRecord[] = [
        { name: "fontSize", label: "Font size (pixels):", type: FormFieldType.int, size: { widthPx: 30 } },
        { name: "lineHeight", label: "Linespacing (lines):", type: FormFieldType.decimal, size: { widthPx: 50 } }
    ];
    const paragraphFields: FormFieldRecord[] = [
        { name: "paragraphSpacing", label: "Add space after paragraph (lines):", type: FormFieldType.decimal, size: { widthPx: 50 } }
    ];

    const modalSubmitted = (values: Record<string, any> | null, id: string) => {
        if (values) {
            props.onSubmit({
                fontSize: values.fontSize,
                lineHeight: values.lineHeight * values.fontSize,
                paragraphSpacing: values.paragraphSpacing * values.fontSize
            });
        } else {
            props.onSubmit(null);
        }
    }

    return (
        <SamModal minWidth={300} maxWidth={300}>
            <VerticalSpacingModalContainer>
                <p>{(props.toolCode === ToolCodeEnum.fontSpacing ? "Font" : "Paragraph") + " spacing:"}</p>
                <SamForm id={formId} fields={props.toolCode === ToolCodeEnum.fontSpacing ? fontFields : paragraphFields} minWidth={280} isVertical={true}
                    initialValues={initialValues} createSubmitButton={true} createCancelButton={true}
                    verticalCaptionsWidth={200} handleSubmit={modalSubmitted}
                />
            </VerticalSpacingModalContainer>
        </SamModal>
    )

    /* support for hover popup
    return (
        <VerticalSpacingPopupContainer $left={props.location.x} $top={props.location.y} onMouseLeave={handleMouseLeave}>
            <p>Font size:&nbsp;{initialValues.fontSize}&nbsp;pixels</p>
            <p>Linespacing:&nbsp;{initialValues.lineHeight}&nbsp;lines</p>
            <p>Space after paragraph:&nbsp;{initialValues.paragraphSpacing}&nbsp;lines</p>
            <ButtonsRow height={34} marginTop={0} marginBottom={0}>
                <IconButton caption="Change vertical spacing" onClick={() => setShowModal(true)} />
            </ButtonsRow>
        </VerticalSpacingPopupContainer>
    )
    */
}

export default HtmlDomEditorToolbar;