import { insert } from '../libSupport';

import app from '../../appData';
import { RowState } from '../../interfaces/lib-api-interfaces';

export const skuLongToSku = (sku: number): string => {
    //    console.log("value=" + value + ", parsed=" + parseFloat(value))
    //    const sku = parseFloat(value);
    const prod = Math.floor(sku / 100000);
    let text = prod + '';
    const spec1 = Math.floor((sku - prod * 100000) / 100);
    if (spec1) {
        text += '-' + spec1;
    }
    const spec2 = sku - (prod * 100000) - (spec1 * 100);
    if (spec2) {
        text += '-' + spec2;
    }
    return text;
}
export const skuToSkuLong = (sku: string): number => {
    const parts = sku.split('-');
    let skuLong = parseInt(parts[0]) * 100000;
    if (parts.length > 1) {
        skuLong += parseInt(parts[1]) * 100;
    }
    if (parts.length > 2) {
        skuLong += parseInt(parts[2]);
    }
    return isNaN(skuLong) ? 0 : skuLong;
}
export function reorgArray<T>(list: T[], source: number, target: number, renumber: boolean, setRowState: boolean = false): T[] {
    const newList = [] as T[];
    if (source === target) {
        return newList;
    }
    let index = 0;
    for (const item of list) {
        if (index === source && target !== list.length) {
            newList.push({ ...list[target] });
        } else if (index === target) {
            newList.push({ ...list[source] });
        } else {
            newList.push({ ...list[index] });
        }
        index++;
    }
    if (target === list.length) {
        newList.push({ ...list[source] });
    }
    if (renumber || setRowState) {
        for (const item of newList) {
            const itemAny = item as any;
            if (renumber) {
                itemAny.display_order = index;
            }
            if (setRowState) {
                itemAny.rowState |= RowState.modified;
            }
        }
    }
 //   console.log("after reorg:", newList)
    return newList;
}
export function isDirtyArray<T>(list: T[]): boolean {
    return !!list.find((item: any) => item.rowState !== RowState.unchanged);
}

