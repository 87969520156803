import InfoPageStyles, { MediaType } from '../../InfoPageStyles';
import { standardizeColorStyle } from '../../forms/ColorfulPicker';
import DomFormatter from './DomFormatter';

import { InfoPageStylesRecord, StyleRecord } from '../../../interfaces/lib-api-interfaces';
import { NodeTypeEnum } from './DomEditor';

export enum ToolCodeEnum {
    none = 1,
    bold, italic, underline,                  // these 3 correspond to tags intentionally
    left, center, right, justify,            // paragraph level
    color, fontFamily, link, paragraphSpacing,       // these require a value to be passed
    image, clean, help,                     // clean invokes clean submenu
    paste,                                // paste menu
    settings,                            // invokes second toolbar row showing current settings and offering to edit them
    fontSpacing,                // invokes popup
    codeMode,
    globalStyles, imageOptions, cleanAll, cleanColors, cleanAllExceptLinks, cleanFontFamily, pasteLiteral, pasteUnformatted, pasteFormatted               // submenus
}

// export enum ToolbarCommandType { emphasis, paragraph, valueNeeded, toggle, submenu, popup };
export enum ToolbarCommandOptions {
    none = 0,
    isEmphasis = 1,
    isParagraphLevel = 2,
    isDefaultable = 4,
    isPseudoStyle = 8,
    passThrough = 16
    //    isValueNeeded = 64,
}

export enum ToolbarDisplayType { none, submenu, modalDialog, toolbarRow, regular }

interface SubMenuItem {
    label: string;
    code: ToolCodeEnum;
}
export interface ToolMapRecord {
    toolCode: ToolCodeEnum;
    styles?: string[];               // these determine supported styles
    icon?: string;
    displayType: ToolbarDisplayType;
    tooltip?: string;
    options: ToolbarCommandOptions;
    setValue?: string;
    clearValue?: string;
    tags?: string[];         // emphasis tags that might be found in html
    submenu?: SubMenuItem[];
}
// styles not found here are not supported
// on toggle buttons style is the code corresponding to "toggles" state variable
export const toolBarMap: ToolMapRecord[] = [
    {
        toolCode: ToolCodeEnum.fontFamily, styles: ["font-family"], tooltip: "Font family", displayType: ToolbarDisplayType.modalDialog,   // this doesn't matter since only toolCode is used here
        options: ToolbarCommandOptions.isDefaultable
    },
    {
        toolCode: ToolCodeEnum.fontSpacing, styles: ["font-size", "line-height"], icon: "bi bi-distribute-vertical", tooltip: "Font size and spacing", 
        displayType: ToolbarDisplayType.modalDialog,
        options: ToolbarCommandOptions.isDefaultable
    },
    {
        toolCode: ToolCodeEnum.paragraphSpacing, styles: ["margin-bottom"], icon: "bi bi-paragraph", tooltip: "Paragraph spacing",
        displayType: ToolbarDisplayType.modalDialog,
        options: ToolbarCommandOptions.isDefaultable | ToolbarCommandOptions.isParagraphLevel
    },
    {
        toolCode: ToolCodeEnum.bold, styles: ["font-weight"], icon: "bi bi-type-bold", tooltip: "Bold", setValue: "bold", clearValue: "normal", tags: ['b', 'strong'],
        displayType: ToolbarDisplayType.regular,
        options: ToolbarCommandOptions.isDefaultable | ToolbarCommandOptions.isEmphasis
    },
    {
        toolCode: ToolCodeEnum.italic, styles: ["font-style"], icon: "bi bi-type-italic", tooltip: "Italic", setValue: "italic", clearValue: "normal", tags: ['i', 'em'],
        displayType: ToolbarDisplayType.regular,
        options: ToolbarCommandOptions.isDefaultable | ToolbarCommandOptions.isEmphasis
    },
    {
        toolCode: ToolCodeEnum.underline, styles: ["text-decoration"], icon: "bi bi-type-underline", tooltip: "Underline", setValue: "underline", clearValue: "none", tags: ['u'],
        displayType: ToolbarDisplayType.regular,
        options: ToolbarCommandOptions.isDefaultable | ToolbarCommandOptions.isEmphasis
    },
    // note that 'link' and '1' are pseudo values for showing when cursor is in a link
    {
        toolCode: ToolCodeEnum.link, styles: ["link"], icon: "bi bi-link-45deg", tooltip: "Link", setValue: '1',
        displayType: ToolbarDisplayType.regular,
        options: ToolbarCommandOptions.isPseudoStyle
    },
    {
        toolCode: ToolCodeEnum.left, styles: ["text-align"], icon: "bi bi-text-left", tooltip: "Left", setValue: "left",
        displayType: ToolbarDisplayType.regular,
        options: ToolbarCommandOptions.isParagraphLevel
    },
    {
        toolCode: ToolCodeEnum.center, styles: ["text-align"], icon: "bi bi-text-center", tooltip: "Center", setValue: "center",
        displayType: ToolbarDisplayType.regular,
        options: ToolbarCommandOptions.isParagraphLevel
    },
    {
        toolCode: ToolCodeEnum.right, styles: ["text-align"], icon: "bi bi-text-right", tooltip: "Right", setValue: "right",
        displayType: ToolbarDisplayType.regular,
        options: ToolbarCommandOptions.isParagraphLevel
    },
    {
        toolCode: ToolCodeEnum.justify, styles: ["text-align"], icon: "bi bi-justify", tooltip: "Justify", setValue: "justify",
        displayType: ToolbarDisplayType.regular,
        options: ToolbarCommandOptions.isParagraphLevel
    },

    // 'figure' and '1' are pseudo for showing when cursor is inside a <figure> (img or video)
    {
        toolCode: ToolCodeEnum.image, styles: ["figure"], icon: "bi bi-image", tooltip: "Insert image/video", setValue: '1',
        displayType: ToolbarDisplayType.regular,
        options: ToolbarCommandOptions.isPseudoStyle
    },
    {
        toolCode: ToolCodeEnum.color, styles: ["color"], icon: "bi-palette", tooltip: "Text color",
        displayType: ToolbarDisplayType.modalDialog,
        options: ToolbarCommandOptions.isDefaultable,
    },
    {
        toolCode: ToolCodeEnum.clean, icon: "fa fa-ban", tooltip: "Clean text",
        displayType: ToolbarDisplayType.submenu,
        options: ToolbarCommandOptions.none,
        submenu: [
                { label: "Remove all formatting", code: ToolCodeEnum.cleanAll },
                { label: "Remove formatting, keep links", code: ToolCodeEnum.cleanAllExceptLinks },
                { label: "Remove color formatting", code: ToolCodeEnum.cleanColors },
                { label: "Remove font-family formatting", code: ToolCodeEnum.cleanFontFamily }
            ]
    },
    // pseudo codes for clean so tool click handler can distinguish them from passed record
    { toolCode: ToolCodeEnum.cleanAll, displayType: ToolbarDisplayType.none, options: ToolbarCommandOptions.isPseudoStyle },
    { toolCode: ToolCodeEnum.cleanAllExceptLinks, displayType: ToolbarDisplayType.none, options: ToolbarCommandOptions.isPseudoStyle },
    { toolCode: ToolCodeEnum.cleanColors, displayType: ToolbarDisplayType.none, options: ToolbarCommandOptions.isPseudoStyle },
    { toolCode: ToolCodeEnum.cleanFontFamily, displayType: ToolbarDisplayType.none, options: ToolbarCommandOptions.isPseudoStyle },
    
    {
        toolCode: ToolCodeEnum.paste, icon: "bi bi-clipboard", tooltip: "Set paste type",
        displayType: ToolbarDisplayType.submenu,
        options: ToolbarCommandOptions.none,
        submenu: [
            { label: "Paste unformatted text (U)", code: ToolCodeEnum.pasteUnformatted },
            { label: "Paste formatted text (F)", code: ToolCodeEnum.pasteFormatted },
            { label: "Paste literal text or source code (L)", code: ToolCodeEnum.pasteLiteral }
        ]
    },
    {
        toolCode: ToolCodeEnum.codeMode, icon: "bi bi-code-slash", tooltip: "Code mode",
        displayType: ToolbarDisplayType.regular,
        options: ToolbarCommandOptions.passThrough,
    }

    //   new ToolMapRecord(ToolCodeEnum.help, "bi bi-question", "Help"),
];

// following returns all tools that have icons
export const getDisplayableTools = (): ToolCodeEnum[] => {
    const result = [ToolCodeEnum.fontFamily];
    for (const entry of toolBarMap) {
        if (entry.icon) {
            result.push(entry.toolCode);
        }
    }
    return result;
}

export const parsePixelValueFromStyle = (value?: string): number => {
    let size = 0;
    if (value) {
        size = parseInt(value.slice(0, -2));
    }
    if (size == 0 || isNaN(size)) {
        return 14;
    }
    return size;
}

export const getToolMapRecord = (codeOrStyle: ToolCodeEnum | string): ToolMapRecord | undefined => {
    if (typeof codeOrStyle === "number") {
        // assume it's the tool code
        return toolBarMap.find(entry => entry.toolCode === codeOrStyle);
    } else {
        return toolBarMap.find(entry => entry.styles && entry.styles.includes(codeOrStyle));
    }
}
export const initStyleRecord = (style: string, value: string): StyleRecord => {
    const styles = {} as StyleRecord;
    styles[style] = value;
    return styles;
}
export const addToStyleRecord = (style: string, value: string, styles: StyleRecord) => {
    styles[style] = value;
}

// setGlobalStyles() must be called after instantiation
class StyleMgr {
    editorDiv: HTMLDivElement;
    supportedStyles: string[];
    infoPageStyles: InfoPageStyles;
    editorStyles: StyleRecord;

    constructor(editorDiv: HTMLDivElement) {
        this.editorDiv = editorDiv;
        this.supportedStyles = this._getSupportedStyles();
        this.infoPageStyles = new InfoPageStyles(this.editorDiv);
        this.editorStyles = this._getComputedStyles(this.editorDiv);
    }

    /*
    export interface InfoPageStylesRecord {
    fontFamily: string;
    fontSize: number;
    lineHeight: number;
    paragraphSpacing: number;
    captionFontSize: number;
    captionItalics: boolean;
    captionAlign: string;
    headerFontSize: number;
    linkColor: string;
    linkUnderline: boolean;
    linkItalics: boolean;
    linkBold: boolean;
    }
    */
    // this must be called whenever global styles change (when new infopage loaded or when styles changed from editor)
    // setGlobalStyles = (globalStyles: InfoPageStylesRecord) => {
    //     this.globalStyles = this.infoPageStyles.buildGlobalStyles(globalStyles);
    // }

    setElementStyles = (elem: HTMLElement, styles: StyleRecord) => {
        for (const style in styles) {
            elem.style.setProperty(style, styles[style]);
        }
    }

    updateGlobalStyles = () => {
        this.editorStyles = this._getComputedStyles(this.editorDiv);
    }

    emphasisTagToToolCode = (tag: string): ToolCodeEnum | null => {
        const entry = toolBarMap.find(entry => entry.tags && entry.tags.includes(tag));
        return entry?.toolCode ?? null;
    }
    emphasisTagToMapRecord = (tag: string): ToolMapRecord | undefined => {
        return toolBarMap.find(entry => entry.tags && entry.tags.includes(tag));
    }

    // return distinct list of all styles, block and span
    // if textOnly false all styles returned
    _getSupportedStyles = (): string[] => {
        const styles: string[] = [];
        for (const entry of toolBarMap) {
            if (entry.styles) {
                for (const style of entry.styles) {
                    if (!styles.includes(style)) {
                        styles.push(style);
                    }
                }
            }
        }
        return styles;
    }


    // return ALL styles, standardized (for use by toolbar where we want to see ALL styles)
    getCursorStyles = (node: Node): StyleRecord => {
        const elem = DomFormatter.findNearestElement(node, this.editorDiv);
        if (!elem) {
            return {};
        }
        return this._getComputedStyles(elem);   // standardized, not filtered
    }
/*
    // return standardized styles with globals filtered out (for use when mapping where we don't want globals to pollute the object)
    getFilteredStyles = (node: Node): StyleRecord => {
        const elem = DomFormatter.findNearestElement(node, this.editorDiv);
        if (!elem) {
            return {};
        }
        const styles = this._getComputedStyles(elem);   // standardized, not filtered
        this._filterStyles(styles, elem.tagName);
        return styles;
    }
*/
    getComputedStyles = (node: Node): StyleRecord => {
        let elem = node as HTMLElement;
        while (elem && elem !== this.editorDiv && elem.nodeType !== NodeTypeEnum.block) {
            elem = elem.parentElement!;
        }
        if (!elem) {
            return {};
        }
        return this._getComputedStyles(elem);   // standardized, not filtered
    }

    // the following adds 2 pseudo styles to record: 'link'='1' if cursor inside <a> element; 'figure'='1' if cursor inside <figure> element
    // styles targeting the given element are EXCLUDED (to avoid embedding them in final html text)
    _getComputedStyles = (elem: HTMLElement): StyleRecord => {
        const wrkStyles = this._getRawComputedStyles(elem);
        const styles = this._standardizeStyles(wrkStyles);
        const targeted = this.infoPageStyles.getGlobalStylesWithTargetsAndMedia(MediaType.default, elem.tagName.toLowerCase());
        for (const prop in targeted) {
            if (prop in styles) {
                delete styles[prop];
            }
        }
        // const graf = DomFormatter.findNearestParagraphElement(elem, this.editorDiv);
        // if (graf) {
        //     const grafStyles = this._getRawComputedStyles(graf);
        //     if ("margin-bottom" in grafStyles) {
        //         styles["margin-bottom"] = grafStyles["margin-bottom"];
        //     }
        // }
        //     console.log("getComputedStyles: raw:", wrkStyles, "; standardized:", styles, "; elem:", elem)
        if (DomFormatter.getAncestorElement(elem, 'a', this.editorDiv)) {
            styles.link = '1';
        }
        if (DomFormatter.getAncestorElement(elem, 'figure', this.editorDiv)) {
            styles.figure = '1';
        }
        //        console.log("computed:", styles)
        return styles;
    }

    // return ALL styles, not standardized or filtered
    _getRawComputedStyles = (element: HTMLElement): StyleRecord => {
        const cursorStyle = window.getComputedStyle(element);
        const wrkStyles: StyleRecord = {};
        for (const style of this.supportedStyles) {
            const computed = cursorStyle.getPropertyValue(style);
            if (computed) {
                wrkStyles[style] = computed;
            }
        }
        //    console.log("raw computer:", wrkStyles)
        return wrkStyles;
    }

    /* return copy of styles modifed as follows:
     font-weight to "bold" or remove
     text-align to "left" if "start"
     font-style to "regular" if "normal"
     colors:
        if text-decoration is a color remove it
        convert hex colors to rgb(), change simple string colors to rgb (e.g., "red", "yellow", "blue")
 
     filtering rules:
         on paragraphs/div/h1 etc. delete non-paragraph styles
         on non-paragraphs delete paragraph styles
         delete all styles whose values agree with defaults as set from global styles
     */
    _standardizeStyles = (rawStyles: StyleRecord): StyleRecord => {
        const styles = { ...rawStyles };
        if ("font-weight" in styles) {
            const val = styles["font-weight"];
            if (val !== "bold" && val !== "normal" && val !== "regular") {
                styles["font-weight"] = (val === "700" ? "bold" : "normal");
            }
        }
        if (styles["text-align"] === "start") {
            styles["text-align"] = "left";
        }
        if (styles["font-style"] === "regular") {
            styles["font-style"] = "normal";
        }
        if (styles["text-decoration"] && styles["text-decoration"].startsWith("rgb")) {
            styles["color"] = styles["text-decoration"]
            delete styles["text-decoration"];
        }
        if ("color" in styles) {
            styles.color = standardizeColorStyle(styles.color);
        }

        return styles;
    }
/*
    // remove styles which don't apply to this type of element (operates in place)
    _filterStyles = (styles: StyleRecord, tag: string) => {
        const orgStyles = {...styles};
        tag = tag.toLowerCase();
        this.filterStylesByParagraph(styles, DomFormatter.isParagraphTag(tag));

        // remove styles which agree with defaults
        for (const style in this.editorStyles) {
            // style must exist in globals and be the same value as in cursor styles
            if (styles[style] === this.editorStyles[style]) {
                console.log("filtering: deleting " + styles[style])
                delete styles[style];
            }
        }
        console.log("_filterStyles:", orgStyles, "-->", styles);

    }

    filterStylesByParagraph = (allStyles: StyleRecord, isParagraph: boolean) => {
        for (const mapRecord of toolBarMap) {
            const isParagraphRecord = (mapRecord.options & ToolbarCommandOptions.isParagraphLevel) !== 0;
            if ((isParagraphRecord && !isParagraph)
                || (!isParagraphRecord && isParagraph)) {
                for (const style of mapRecord.styles ?? []) {
                    delete allStyles[style];
                }
            }
        }
    }
*/
    // following 3 functions require style record to be standardized per standardizeStyles
    // return copy of styles with give style toggled
    toggleStyleInRecord = (styles: StyleRecord, style: string): StyleRecord => {
        const newStyles = { ...styles };
        newStyles[style] = this.toggleStyle(style, newStyles[style]);
        return newStyles;
    }
    toggleStyle = (style: string, value: string): string => {
        const mapRecord = getToolMapRecord(style)!;
        return value === mapRecord.setValue ? mapRecord.clearValue! : mapRecord.setValue!;
    }
    // return record of all styles where child differs from parent; styles in parent but not in child are ignored
    diffStyles = (parent: StyleRecord, child: StyleRecord): StyleRecord | null => {
        const diff: StyleRecord = {};
        for (const style in child) {
            if (!parent[style] || parent[style] !== child[style]) {
                diff[style] = child[style];
            }
        }
        return Object.keys(diff).length ? diff : null;
    }
    isStyleRecordEqual = (x: StyleRecord, y: StyleRecord, stylesToCheck?: string[]): boolean => {
        for (const style in x) {
            if (x[style] !== y[style]) {
                if (!stylesToCheck || stylesToCheck.includes(style)) {
                    return false;
                }
            }
        }
        for (const style in y) {
            if (x[style] !== y[style]) {
                if (!stylesToCheck || stylesToCheck.includes(style)) {
                    return false;
                }
            }
        }
        return true;
    }

}
export default StyleMgr;