// the following applies to all cdn based graphics:
// set ImageUploadOptions.targetDomain to this plus domain name
// ImageFileOptions.graphicsSubfolder (if any) will be appended to the full uri

// e.g.: graphics are located at "https:/blog-cdn.cyou/cdn/karinalandriver"; append "blog" or "home" or whatever to that
export const cdnDomain = "blog-cdn.cyou";               // currently the domain where we keep all cdn files
//export const cdnUri = "https://" + cdnDomain + "/cdn";    // access cdn files over web; append domain and subfolder (if any)

/* login/register status codes (return success but not registered or logged in):
    ALL:
        201: customer, zip or email not found (WS.register, WS.login, RTL.login)
        202: invalid password (RTL.login, RTL.register, WS.login)
        203: need to register in new system (RTL.login)
        204: email already exists (attempt to register only)
        205: handle exists (attempt to register, blog only)
        
*/
export enum RowState { unchanged = 1, added = 2, modified = 4, deleted = 8 };
export enum LoginError { noError = 200, custNotFound = 201, invalidPassword = 202, reRegisterRequired = 203, duplicateEmail = 204, duplicateHandle = 205 }
export enum CreditCardType { none = "N", amex = "3", visa = "4", masterCard = "5", discover = "6" }
export enum DivisionEnum { jmc = 'J', htm = 'H', fg = 'F', none = 'N', belmont = 'B', berkeley = 'S', wholesale = 'W' }
export enum GraphicDimensionType { width = 'w', height = 'h', longest = 'l' }
export enum GraphicFloatType { none = 'N', right = 'R', left = 'L' }
export enum ImageSizeEnum { full = 'f', magnified = 'm' };
export enum VerifyUrlResult { valid = 200, invalid = 201 }
export enum CouponStatus { valid = 200, invalidCode = 202, expired = 203 }
export enum VideoStreamSource { youtube = 'Y', vimeo = 'V', none = 'N' }

export type StyleRecord = Record<string, string>;

/* userData can contain a visible tag as follows:
        "rtlOnly" -- visible if visitor not logged in
        "wsOnly" -- visible only if visitor is logged in
        "adminOnly" -- visible only if visitor is logged in as admin
    these are handled by MasterPage and not directly by NavBar
*/
export interface TokenRecord {
    userId: number | string,    // custId for WS or retail, domain for web site admin, handle for blog subscriber
    token: string
}
export interface ApiLoginRecord {
    user_name: string;
    password: string;
}

export interface MenuItemData {
    caption: string;
    href?: string;              // can be omitted if popupItems are given
    popupItems?: MenuItemData[];   // not on popupItems or SiteMapData
    userData?: any;                 // not on popupItems or SiteMapData
    categoryId?: number;            // used by dashboard
    alias?: number;                 // category id that this entry represents
}
export const ImageDragType = "imagelist";

export interface BuyBoxProductRecord {
    bullet_points?: string;
    images: string[];
    caption: string;
    videos?: ImageRecord[];
}

// basic all purpose image record for dashboard and app
/* Image notes:
    dimension of height has limited use: the height in pixels is in the size field, width and size_pct are ignored and the image may be cropped on small screens
    any float value except "none" overrides dimension and uses width as dimension
    size_pct is % of width of parent container, with a maximum of size pixels
    size is maximum width, in pixels; size omitted or zero converts to "100%"
*/
export interface ImageRecord {
    caption?: string | null;
    filename?: string;
    stream_id?: string;    // for youtube or vimeo
    stream_source?: VideoStreamSource;      // omit if this is a static image
    blob?: string;
    url?: string;       // link
    tag?: string;       // displayed in bold (useful for SKU #)
    // following valid if image has been dragged or chosen from local filesystem
    file?: any;     // any is for Node; should be cast to File for React code 
    display_order?: number;
    size?: number;          // this is MAX size
    size_pct?: number;      // % of parent width to display image (default to 100)
    dimension?: GraphicDimensionType; 
    float?: GraphicFloatType;   // currently used only for main image in info pages (about us needs to be wrapped right)
    rowState?: RowState;    // for ImageListHandler; caller should pass this as unchanged
    stream_thumb_src?: string;    // temp field for displaying video thumbnail while in dashboard
    alt?: string;
}
export enum CaptionOptionsEnum { allow = 'A', disallow = 'D', readOnly = 'R' }
export interface ImageEditorOptions {
    editOptions?: ImageAttributesEditOptions;      // images are disallowed if this is not given
    fileOptions?: ImageFileOptions;                // ditto
 //   [key: string]: any    add " as keyof ImageEditorOptions" to prop
}
export interface ImageAttributesEditOptions {
    verifyUrlApiUrl?: string; 
    captions: CaptionOptionsEnum;
    //   captionFormat?: CaptionFormatEnum;
    allowResize?: boolean;
    allowFloat?: boolean;       // aka "wrap"
    allowVideo?: boolean;
    allowLink?: boolean;
    allowDimensionChange?: boolean;     // caution: if allowFloat true dimension will set to width if float enabled
}
// following used to display images, also for uploading
export interface ImageFileOptions {
    graphicsSubfolder?: string;      // folder under "graphics": "other", "blog", etc.
    isCdn?: boolean;                // overrides target domain to save file on https://artthisdesign.com/cdn; domain becomes the folder under cdn
    sizeDesignator?: ImageSizeEnum;     // full or magnified if image is stored in 2 versions
    size?: number;                   // default; overridden by size prop in ImageRecord; default to 500
    dimension?: GraphicDimensionType;    // ditto; default to width
}
export interface ImageUploadOptions {
    // following must be passed to enable choosing new image from file system; if not passed new images will not be allowed
    targetDomain: string;           // if isCdn true this is the directory below artthisdesign.com/cdn; else "/graphics" is appended to this domain name
    uploadImageApiUrl: string;
}
export interface UploadImageFromUrlResultRecord {
    url: string;
    filename?: string;       // should be the last part of url; omitted if error is valid
    error?: string;
}

// following is stored in userData field of MenuItemData for category editor
export interface CategoryEditorUserDataRecord {
    categoryId: number;
    isExpanded: boolean;
    isChecked: boolean;
    isIndented: boolean;        // in this one there is only one level of indentation
    parentId: number;              // applies only to popup items
    displayOrder: number;       // from table; for inserting new categories
    subcategoryChecked: boolean;    // used only inside category editor
}
// use alpha values since this can be stored in website content table (type=record)
// used chars are in caps: ABCDEFgHIJkLMNOPqRSTUVXyz$
export enum FormFieldType { 
    // multiLine should specify inputHeight in field or it will be one line tall
    text = 'T', multiLine = 'U', combo = 'C', link = 'L', checkbox = 'X', button = 'B', fontFamily = 'F', // special dropdown with selections shown in font
    // masked
    phone = 'P', date = 'D', 
    // /specialized input
    reactPaymentInputs = 'V',   // must pass getProps to SamForm
     digitsOnly = 'N',          // requires length to be in field.validator.maxLength
     creditCard = 'A',          // for cleave.js -- can be deleted if cleave not being used
     color = 'E',
    // numeric
    int = 'I', decimal = '$', fixedPoint = 'F',     // decimal is 2 dec pts, fixedPoint is 4 dec pts
    // grids only
    image = 'J', selector = 'S', radioList = 'R', staticImage = 'M', icon = 'O',
    // control fields
    header = 'h', break = 'b'
}; 
export enum FormFieldAlignType { left = 'L', center = 'C', right = 'R' }
export interface FormFieldButtonRecord {
    caption: string;
    icon?: string;
    style?: Record<string, any>;
}
export interface FormFieldSizingRecord {
    height?: number;        // in px
    widthFromLabel?: boolean;   // true to set field to label width
    widthPct?: number;
    widthPx?: number;
    marginLeft?: number;   // overrides padding
    marginRight?: number;  // ditto
    marginTop?: number;    // space between this field and the one above it
}
export type FormFieldGetInputPropsType = (fieldName: string, options: Record<string, any>) => Record<string, any>;

export interface MediaValueRecord {
    default: number;
    ipad: number;
    mobile: number;
}
export interface FormFieldRecord {
    name?: string;      // omit if control field
    label?: string;         // could be omitted on grid with no captions
    boldLabel?: boolean;    // used when following 2 fields are for media sizes
    type?: FormFieldType;       // default to text
    initialValue?: any;     // media object if isMedia true
    placeholder?: string;
    size?: FormFieldSizingRecord;
    width?: number;         // now size.widthPct; for backward compatibility
    fixedWidth?: number;    // now size.widthPx: for backward compatibility
    validator?: ValidatorRecord;
    comboSource?: FormComboFieldRecord[];
    button?: FormFieldButtonRecord;
    visible?: boolean;              // default to true
    multiLineBackColor?: string;        // if not passed use app.thems.backColor25
    extraText?: string;     // placed to right of input field on vertical forms
    // can't use the following when storing fields in session store
    // onClick?: (id: string) => void;
    // hideField?: (values: Record<string, any>) => boolean;
    // getProps?: (options: Record<string, any>) => Record<string, any>;

    // grid use only:
    allowEditing?: boolean;
    align?: FormFieldAlignType;     // default to center
    staticImageUrl?: string;    // required on field type staticImage
    icon?: string;              // required on field type icon
    iconFontSize?: number;      // icon usually needs to be in bigger font
    cursor?: string;            // usually "pointer" or omit
    customFormatRequired?: boolean;     // if not true the custom formatter will not be called; if true formatter MUST return string
}
export interface FormComboFieldRecord {
    caption?: string;       // omit caption to use value as caption
    value: string;
}
export interface ValidatorRecord {
    required?: boolean;
    maxLength?: number;     // REQUIRED if FormFieldType is digitsOnly
    minLength?: number;

    isAllUpper?: boolean; // (handled at keystroke level; no errors)
    isAllNumeric?: boolean;     // ditto
    isPassword?: boolean; // (shows stars instead of chars)
    min?: number; // (numeric only)
    max?: number; // (numeric only)

}
// return null if valid, error if not valid
export interface CustomValidatorCallback {
    (fieldName: string, values: Record<string, any>): string | null;
}
export interface CustomFormatterCallback {
    (fieldName: string, value: string): string;
}

export enum AddressType { ship = 'S', bill = 'B', all = 'A' };
export interface AddressRecord {
    email: string;
    fname?: string;     // fname and lname for fg only
    lname?: string;
    contact_name?: string;  // ws only
    company: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    country: string;
    attn?: string;
    province?: string;
    address_id: number;
    storefront?: boolean;
    res_del?: boolean;
    is_default?: boolean;
  //  [key: string]: any        USE as keyof AddressRecord
}
export interface AddressRecordWithPassword extends AddressRecord {
    password: string;
}
export interface CreditCardRecord {
    cardNumber?: string;
    expiryDate?: string;         // mmyy or mm/yy
    cvc?: string;            // 3 or 4 digits
    zip?: string;            // 5 digits
    cardHolder?: string;
    saveCard?: boolean;
}
export interface CreditCardSummaryRecord {
    last4: string,
    card_type: CreditCardType,
    cardTypeDesc: string
}
export interface SchemaRow {
    column_name: string,
    data_type: string,      // note, this is verbose type; must be converted to sql for use in ParameterRecord
    max_length: number,
    isPrimaryKey: boolean,
    isNullable: boolean,
    isIdentity: boolean
}

// for passing to text editor, and saving blog content
export interface TextOrImageRecord {
    seq_num?: number;           // for dashboard
    content_type: InfoContentTypeEnum;
    text?: string;          // required for text content
    image?: ImageRecord;    // required for image content
    // url can have one of 3 formats: "https://..." is an external link; "part1/part2..." is an internal link; "video://YOUTUBE-ID" is a video link
    url?: string;           // required for link content (text is the underlined part, url is the href part, caption is urlencoded and appended to url after slash)
}

//-------- INFO RECORDS (to be deprecated) ---------------
export enum InfoContentTypeEnum { text = 'T', newParagraph = 'P', link = 'L', image = 'I' }
export enum InfoSectionTypeEnum { artists = 'A', info = 'I' }       // someday product info or whatever or "Good To Know"
export interface InfoIndexRecord {
    division: DivisionEnum;     // fg or wholesale
    info_id: number;
    url: string;
    graphics_subfolder: string;
    title: string;
    subtitle?: string;
    section: InfoSectionTypeEnum;
    image?: ImageRecord;
    display_order: number;         // only needed for dashboard
    summary: string;
    isLive?: boolean;       // for dashboard only
}
// returned by get content api
export interface InfoContentRecord {
    indexRecord: InfoIndexRecord;
    contents: TextOrImageRecord[];
}
export interface SetInfoDisplayOrderRecord {
    info_id: number;
    display_order: number;
}
//----------- NEW INFO RECORD -----------------------------------
export interface CommonInfoRecord {
    division: DivisionEnum; 
    url: string;
    title?: string;
    text: string;
}
//-------== NEWER INFO RECORD -----------------------------------
export const domainDefaultsRecordName = "domain-defaults";
export enum InfoPageRecordType { settings = 'S', content = 'C' }
export interface InfoPageApiRecord {
    name?: string;          // required for passing to/from api; HtmlEditor doesn't care
    domain?: string;         // ditto
    settings: InfoPageSettingsRecord;     // domain and settings_name omitted
    title?: string;
    content: string;
}
// pass name OR page
export interface InfoPageEditorRouterState {
    name?: string;
    page?: InfoPageApiRecord;
}
// stored in JSON as content field in record named as above
export interface InfoPageSettingsRecord {
    domain?: string;                // first 2 for api access; not required internally
    name?: string;              // joins to settings_name in InfoPageRecord
    styles?: InfoPageStylesRecord;  // defaulted if not passed
    allowImages: boolean; 
    image_options?: ImageEditorOptions;     // required if allowImages true
}
export interface InfoPageRecord {
    domain: string;
    name: string;
    title?: string;
    content: string;
    settings_name?: string;      // css styles and image options
}
export interface InfoPageStylesRecord {
    fontFamily: string;
    color: string;
    fontSize: MediaValueRecord;
    lineHeight: MediaValueRecord
    paragraphSpacing: MediaValueRecord
    captionFontSize: MediaValueRecord
    captionItalics: boolean;
    captionAlign: string;
    headerFontFamily: string;
    headerFontSize: MediaValueRecord
    linkColor: string;
    linkUnderline: boolean;
    linkItalics: boolean;
    linkBold: boolean;
}

