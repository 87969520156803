import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// routes
import Dashboard from './components/Dashboard';

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard/:page" element={<Dashboard />} />
            </Routes>
        </BrowserRouter>
    );
}
export default AppRoutes;

