import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import deepcopy from 'deepcopy';

import { useGlobalContext, useSessionStore, useTokens } from '../libs/SamState';
import NavBar from '../libs/NavBarV5';
import { StyledErrorText, mergeOptionsRecords } from '../libs/libSupport';
import { usePostApi, useFetchApi, genericApiError } from '../libs/useDataApiV2';
import SamForm from '../libs/forms/SamFormV5';
import IconButton, { ButtonsRow } from '../libs/IconButtonV2';
import HtmlDomEditor from '../libs/dashboard/HtmlDomEditor/HtmlDomEditor';
import SamImageGrid from '../libs/dashboard/SamImageGrid';
import InfoPageStyles from '../libs/InfoPageStyles';

import { FormFieldRecord, FormFieldType, FormFieldAlignType, TokenRecord, ImageRecord, RowState, CaptionOptionsEnum, ImageEditorOptions, InfoPageStylesRecord, GraphicDimensionType, InfoPageSettingsRecord, ImageFileOptions, VideoStreamSource } from '../interfaces/lib-api-interfaces';
import {
    WebsiteContentType, WebsitePhotoContentType,
    WebsiteRecordContentRecord, WebsiteTagRecord, WebsiteTextContentType, WebsiteTextListContentType, WebsiteTextPairContentType
} from '../interfaces/lib-websites-interfaces';

import '../App.css';
import app from '../appData';
import api from '../api-url';
import FormMgr from '../libs/forms/FormMgr';
import Spinner from '../libs/Spinner';
import SimpleHzForm from '../libs/forms/SimpleHzForm';
import { flattenImageEditorOptions, imageEditorOptionsFields, splitImageEditorOptions } from '../libs/dashboard/SamImageV3';
import AgGrid, { AgGridApi } from '../libs/dashboard/AgGrid';

// on blogs and sites with "info" text the following are stored in the dbo.info_table
// on this site we can hard code them right here:
const globalStyles = (): InfoPageStylesRecord => {
    return mergeOptionsRecords([InfoPageStyles.globalStylesDefaultValues(), {
        fontFamily: app.themes.sansFonts!,
        color: app.themes.color!,
        fontSize: { default: 14, ipad: 13, mobile: 12 },
        lineHeight: { default: 22, ipad: 20, mobile: 16 },
        paragraphSpacing: { default: 8, ipad: 7, mobile: 6 }
    }]) as InfoPageStylesRecord;
}
/* press
        "max_items: null,
        "size: 560,
        "dimension: null,
        "is_fixed_size: false,
        "graphics_subfolder: "other",
        "allow_videos: true,
        "allow_captions: true,
        "allow_filesystem: true
**** wholesale
        "max_items: 1,
        "size: 400,
        "dimension: null,
        "is_fixed_size: false,
        "graphics_subfolder: "other",
        "allow_videos: false,
        "allow_captions: false,
        "allow_filesystem: true
*** gallery
        "max_items: null,
        "size: 600,
        "dimension: "h",
        "is_fixed_size: true,
        "graphics_subfolder: "gallery",
        "allow_videos: false,
        "allow_captions: false,
        "allow_filesystem: true
*/


const MasterContainer = styled.div`
    font-family: ${app.themes.sansFonts};
    color: ${app.themes.color};
    h1 {
        text-align: center;
        font-size: 36px;
        margin-top: 24px;
        margin-bottom: 16px;
        font-weight: bold;
    }
    h2 {
        text-align: center;
        font-size: 28px;
        margin-top: 24px;
        margin-bottom: 4px;
        font-weight: bold;
    }
`
const ChangesCanceledText = styled.button`
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 36px;
    background-color: ${app.themes.backColor10};
`

const editorWidth = 600;
const Dashboard: React.FC = () => {
    const [currPage, setCurrPage] = React.useState<string>();
    const { getToken } = useTokens();
    const params = useParams();

    React.useEffect(() => {
        if (params.page) {
            setCurrPage(params.page);
        }
    }, [params.page]);

    if (!getToken()) {
        return <DashLogin />
    }
    return (
        <MasterContainer>
            <h1>Laurainbo Crystals Dashboard</h1>
            <NavBar menuItems={app.menuItems} />

            {(() => {
                switch (currPage) {
                    case 'settings':
                        return <EditSettings />
                    case 'password':
                        return <DashChangePassword />
                    // case 'fix-media-temp':
                    //     return <FixMediaTemp />
                    default:
                        return <DashboardPage page={currPage ?? "home"} />
                }
            })()}
        </MasterContainer>
    )
}
/*
const FixMediaTemp: React.FC = () => {
    const { fetch } = useFetchApi();
    const { getToken } = useTokens();
    const navigate = useNavigate();

    React.useEffect(() => {
        fetch("/api/fixWebsitesMediaValues", getToken()!.token, () => { alert("DB updated"); navigate("/"); }, () => alert(genericApiError) );
    }, []);

    return (
        <div></div>
    )
}
*/
//-----------------------------------------------
/* manage all text styles and image options
    text styles are same for every text block and are stored in content field as follows:
        text: (html content)
        styles: InfoPageStylesRecord
    image options vary by page and are stored as follows:
        image: ImageRecord[]
        image_options: ImageEditorOptions (consist of editOptions and fileOptions)
    pages containing image options can be targeted by tag field as follows:
        page: photo-gallery, tag: photos
        page: press, tag: clippings
        page: wholesale, tag: photo
*/
const StyledSelect = styled.select`
    height: 32px;
    border: 1px solid #ccc;
`
const TitleAndFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

interface PageMap {
    caption: string;
    tags: string[];
}
const EditSettings: React.FC = () => {
    const [selectedRecord, setSelectedRecord] = React.useState<WebsiteTagRecord>();
    const [allRecords, setAllRecords] = React.useState<WebsiteTagRecord[]>();
    const [loading, setLoading] = React.useState(false);

    const { fetch } = useFetchApi();
    const { post } = usePostApi();
    const { getToken } = useTokens();
    const navigate = useNavigate();
    const forms = new FormMgr(useGlobalContext().setContext);

    const pages: PageMap[] = [
        { caption: "All text", tags: ["copy", "above", "below"] },
        { caption: "Photo gallery images", tags: ["photos"] },
        { caption: "Press images", tags: ["clippings"] },
        { caption: "Wholesale page image", tags: ["photo"] }
    ];

    const isEditingStyles = (): boolean => {
        return selectedRecord?.content_type === WebsiteContentType.text;
    }

    const recordToSelectCaption = (record: WebsiteTagRecord): string => {
        return pages.find(page => page.tags.includes(record.tag))!.caption;
    }
    const selectCaptionToRecord = (caption: string): WebsiteTagRecord => {
        const tag = pages.find(page => page.caption === caption)!.tags[0];
        return allRecords!.find(record => record.tag === tag)!;
    }

    React.useEffect(() => {
        setLoading(true);
        fetch(api.fetchContent + app.domain + "/*/0", getToken()!.token,
            (result: WebsiteTagRecord[]) => {
                // following loop can go away after records updated
                setLoading(false);
                setAllRecords(result);
                setSelectedRecord(result.find(rec => rec.content_type === WebsiteContentType.text));
            },
            () => alert(genericApiError));
    }, []);

    const pageSelected = (e: React.ChangeEvent) => {
        const selected = selectCaptionToRecord((e.target as HTMLSelectElement).value);
        try {
            if (selected.content_type === WebsiteContentType.text) {
                forms.setFormValues("styles", (selected.content as WebsiteTextContentType).styles);     // setFormValues will expand stored MediaValueRecords into 3 values each
            } else {
                forms.setFormValues("imageOptions", flattenImageEditorOptions((selected.content as WebsitePhotoContentType).imageOptions));
            }
        } catch (err) {
            console.log(err);
        }
        console.log("setSelectedRecord:", selected)
        setSelectedRecord(selected);
    }

    const handleSubmit = () => {
        const postedRecords: (WebsiteTextContentType | WebsitePhotoContentType)[] = [];
        if (isEditingStyles()) {
            const styles = forms.getFormValuesWithMedia("styles") as InfoPageStylesRecord;
            for (const rec of allRecords!) {
                if (rec.content_type === WebsiteContentType.text) {
                    const newRec = deepcopy(rec);
                    newRec.styles = styles;
                    postedRecords.push(newRec);
                }
            }
        } else {
            const image_options = splitImageEditorOptions(forms.getFormValues("imageOptions"));
            const newRec = deepcopy(selectedRecord!) as WebsitePhotoContentType;
            newRec.imageOptions = image_options;
            postedRecords.push(newRec);
        }
        setLoading(true);
        post(api.postContent, postedRecords,
            () => {
                setLoading(false);
                alert("Content posted");
            }, () => alert(genericApiError), getToken()!.token);
    }

    return (
        <div>
            {loading && <Spinner />}
            {allRecords && selectedRecord &&
                <>
                    <ButtonsRow>
                        <StyledSelect value={recordToSelectCaption(selectedRecord)} onChange={pageSelected}>
                            {pages.map((page) => {
                                return (
                                    <option key={page.caption} value={page.caption}>{page.caption}</option>
                                );
                            })}
                        </StyledSelect>
                        <IconButton caption="Save" icon="fas fa-check" onClick={handleSubmit} />
                        <IconButton caption="Cancel" icon="fas fa-ban" onClick={() => navigate("/")} />
                    </ButtonsRow>
                    {isEditingStyles() ? (
                        <TitleAndFormContainer>
                            <h1>Styles</h1>
                            <SimpleHzForm id={"styles"} fields={InfoPageStyles.globalStylesFields}
                                initialValues={(selectedRecord.content as WebsiteTextContentType).styles} />
                        </TitleAndFormContainer>
                    ) : (
                        <TitleAndFormContainer>
                            <h1>Image Options</h1>
                            <SimpleHzForm id={"imageOptions"} fields={imageEditorOptionsFields}
                                initialValues={flattenImageEditorOptions((selectedRecord.content as WebsitePhotoContentType).imageOptions!)} />
                        </TitleAndFormContainer>
                    )}
                </>}
        </div>
    )

}
//-----------------------------------------------
/*
const Reformat2023: React.FC = () => {
    const [data, setData] = React.useState<WebsiteTagRecord[]>();
    const [msg, setMsg] = React.useState('');

    const { fetch } = useFetchApi();
    const { post } = usePostApi();
    const { getToken } = useTokens();
    const navigate = useNavigate();

    React.useEffect(() => {
        fetch(api.fetchContent + app.domain, getToken()!.token, (data: WebsiteTagRecord[]) => { console.log("setting data:", data); setData(data) }, 
        () => setMsg(genericApiError));
    }, []);
-------------------------------------
        domain: string;         // the website domain
    is_dashboard: boolean;  // true if this is not yet in production
    page: string;                // e.g. "home" or "press"
    tag: string;                // a page can have multiple records, each with a unique tag (e.g.: page="story", tag="above" or "below")
    content_type: WebsiteContentType;  // determines the record layout per extensions below; it is stored as JSON and returns json on intermediate api calls
    caption: string;            // title for dashboard editor
    content: WebsiteContentRecord | string;
--------------------------------------------
    React.useEffect(() => {
        if (data) {
            console.log("got data:", data)
            const newData: WebsiteTagRecord[] = [];
            for (const entry of data) {
                const newEntry = deepcopy(entry) as WebsiteTagRecord;
                if (newEntry.content_type === WebsiteContentType.photo) {
                    newEntry.content = { images: deepcopy((entry.content as any).content), imageOptions: getImageOptions(entry.page) };
                } else if (newEntry.content_type === WebsiteContentType.text) {
                    newEntry.content = { text: entry.content, styles: globalStyles() } as WebsiteTextContentType;
                }
         //       console.log("pushing:", newEntry)
                newData.push(newEntry);
            }
         //   console.log("posting:", newData)
            post(api.postContent, newData, () => {
                fetch(api.approveContent + "*", getToken()!.token, () => {
                    alert("Changes are now live on web site");
                    navigate("/");
                }, () => alert(genericApiError));
            }, () => setMsg(genericApiError), getToken()!.token);
        }
    }, [data]);

    return (
        <p>{msg}</p>
    )
}
*/
//-------------------------------------------------------
const ButtonsContainer = styled.div`
    display: flex;
    width: ${editorWidth}px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
`
const ButtonStyle = {
    height: "32px",
    fontSize: "14px"
};
const DisabledButtonStyle = { ...ButtonStyle, color: "gray" };

interface DashboardPageProps {
    page: string;
}
const DashboardPage: React.FC<DashboardPageProps> = (props) => {
    const { post, isPostLoading } = usePostApi();
    const { getToken } = useTokens();       // do not persist login
    const { fetch, isFetchLoading } = useFetchApi();
    const [tagContent, setTagContent] = React.useState<WebsiteTagRecord[]>();       // the content field of contentV2 record (editable portion)
    const [reloadContent, setReloadContent] = React.useState<boolean>(false);    // set when user cancels changes
    const [isViewing, setIsViewing] = React.useState<boolean>(false);    // set when user hits "Preview in browser"
    const [changesCanceled, setChangesCanceled] = React.useState<boolean>(false);    // true to show "changes canceled" (for 2 seconds);
    const [isDirty, setIsDirty] = React.useState<boolean>(false);
    const [suppressNavButtons, setSuppressNavButtons] = React.useState<boolean>(false);  // photo editor can call this when inside a modal dialog
    const [alertMsg, setAlertMsg] = React.useState<string>();

    const token = getToken()!.token;

    const serverError = () => {
        alert("The server appears to be down right now");
    }

    React.useEffect(() => {
        setReloadContent(true);
    }, [props.page]);

    // React.useEffect(() => {
    //     setReloadContent(true);
    // }, []);
    React.useEffect(() => {
        if (reloadContent) {
            fetch(api.fetchContent + app.domain + "/" + props.page + "/1", token,
                (data: WebsiteTagRecord[]) => {
                    let converted = false;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].content_type === WebsiteContentType.text) {
                            const newTextContent = convertTextRecord(data[i].content as string | WebsiteTextContentType);
                            if (newTextContent) {
                                converted = true;
                                data[i].content = newTextContent;
                            }
                        } else if (data[i].content_type === WebsiteContentType.photo) {
                            const newPhotoContent = convertPhotoContentRecord(data[i].content as Record<string, any>, data[i].page);
                            if (newPhotoContent) {
                                converted = true;
                                data[i].content = newPhotoContent;
                            }
                        }
                    }
                    setTagContent(data);
                    setReloadContent(false);
                    setIsDirty(converted || data[0].is_dashboard);    // true if records were previously saved to dashboard (have been previewed/saved for later)
                },
                () => serverError());
        }
    }, [reloadContent]);
    React.useEffect(() => {
        if (alertMsg) {
            alert(alertMsg);
            setAlertMsg(undefined);
        }
    }, [alertMsg]);

    const updateContent = (newContent: WebsiteTagRecord[]) => {
        setIsDirty(true);
        setTagContent(newContent);
        console.log("content has been updated")
    }

    const acceptChangesInDashboardTable = () => {
        fetch(api.approveContent + props.page, token, () => {
            setIsDirty(false);
            setAlertMsg("Changes are now live on web site");
        }, () => serverError());
    }
    const handleSave = () => {
        if (isDirty) {
            setIsViewing(false);
            // post changes as dashboard records, then copy them into production site
            if (tagContent![0].content_type === WebsiteContentType.record &&
                (tagContent![0].content as WebsiteRecordContentRecord).content.some(record => record.rowState === RowState.deleted)) {
                const recordContent = (tagContent![0].content as WebsiteRecordContentRecord);
                // this is record type content and there is at least one deleted record (i.e., store near you with a store deleted)
                const newContent: Record<string, any>[] = [];
                for (const record of recordContent.content) {
                    if (record.rowState !== RowState.deleted) {
                        newContent.push(record);
                    }
                }
                recordContent.content = newContent;
            }
            post(api.postContent + "?push=y", tagContent, () => acceptChangesInDashboardTable(), () => serverError(), token);
        }
    }
    const handleCancel = () => {
        if (isDirty) {
            window.location.reload();
        }
    }
    const handleView = () => {
        if (isViewing) {
            setIsViewing(false);
            return;
        }
        post(api.postContent, tagContent, () => {
            setIsViewing(true);
        }, () => serverError(), token);
    }
    const handleSaveForLater = () => {
        if (isDirty) {
            post(api.postContent, tagContent, () => {
                setAlertMsg("Changes saved");
            }, () => serverError(), token);
        }
    }
    const editorChanged = (editedContent: WebsiteTagRecord) => {
        const newContent: WebsiteTagRecord[] = deepcopy(tagContent);
        for (let i = 0; i < newContent.length; i++) {
            if (newContent[i].tag === editedContent.tag) {
                newContent[i] = editedContent;
                break;
            }
        }
        updateContent(newContent);
    }

    if (!tagContent) {
        return null;
    }
    const viewInBrowserSrc = window.location.hostname === "localhost" ? "http://localhost:3001" : ("https://" + app.domain);
    console.log("isDirty=" + isDirty)
    return (
        <React.Fragment>
            {(!alertMsg && (isFetchLoading() || isPostLoading())) &&
                <i className="fa fa-spinner fa-spin spinner48"></i>}
            <h2>{tagContent[0].caption}</h2>
            {!suppressNavButtons &&
                <ButtonsContainer>
                    <IconButton style={ButtonStyle} onClick={handleView} caption={isViewing ? "Close preview" : "Preview in browser"} />
                    <IconButton style={isDirty ? ButtonStyle : DisabledButtonStyle} onClick={handleSave} caption="Approve changes" />
                    <IconButton style={isDirty ? ButtonStyle : DisabledButtonStyle} onClick={handleCancel} caption="Cancel changes" />
                    <IconButton style={isDirty ? ButtonStyle : DisabledButtonStyle} onClick={handleSaveForLater} caption="Save for later" />
                </ButtonsContainer>
            }
            {changesCanceled && (
                <ChangesCanceledText>Changes have been canceled</ChangesCanceledText>
            )}
            {isViewing ? (
                <iframe src={viewInBrowserSrc + "/" + props.page + "?dashboard=1"} style={{ width: "100%", height: "1000px", marginTop: "16px" }} />
            ) : (
                tagContent.map(element => {
                    return (
                        <DashboardEditor key={element.tag} element={element} showTag={tagContent.length > 1} setSuppressNavButtons={setSuppressNavButtons}
                            page={props.page} handleChange={editorChanged} />
                    )
                })
            )}
        </React.Fragment>
    )
}
//-------------------------------------------------------
const convertTextRecord = (record: WebsiteTextContentType | string): WebsiteTextContentType | null => {
    if (typeof record === "string") {
        // this is old format
        return {
            text: record,
            styles: {
                fontFamily: "Palatino Linotype",
                color: "#29228f",
                fontSize: {
                    default: 14,
                    ipad: 12,
                    mobile: 10
                },
                headerFontFamily: "Arial",
                headerFontSize: {
                    default: 32,
                    ipad: 28,
                    mobile: 24
                },
                paragraphSpacing: {
                    default: 8,
                    ipad: 7,
                    mobile: 6
                },
                lineHeight: {
                    default: 22,
                    ipad: 19,
                    mobile: 16
                },
                linkColor: "#00e",
                linkUnderline: true,
                linkItalics: false,
                linkBold: false,
                captionFontSize: {
                    default: 14,
                    ipad: 12,
                    mobile: 10
                },
                captionItalics: true,
                captionAlign: "center"
            }
        };
    } else {
        return null;
    }
}
// there are 3 photo list records: press, wholesale and gallery
const getImageOptions = (page: string): ImageEditorOptions => {
    // setting gallery as the default
    const options = {
        editOptions: {
            captions: CaptionOptionsEnum.disallow,
            allowFloat: false,
            allowLink: false,
            allowResize: false,
            allowVideo: false
        },
        fileOptions: {
            graphicsSubfolder: "gallery",
            size: 600,
            dimension: GraphicDimensionType.height
        }
    };
    if (page === "press") {
        options.editOptions.captions = CaptionOptionsEnum.allow;
        options.fileOptions.size = 560;
        options.editOptions.allowResize = true;
        options.editOptions.allowVideo = true;
        options.fileOptions.graphicsSubfolder = "press";
        options.fileOptions.dimension = GraphicDimensionType.width;
    } else if (page === "wholesale") {
        options.fileOptions.size = 400;
        options.fileOptions.dimension = GraphicDimensionType.width;
        options.editOptions.allowResize = true;
        options.fileOptions.graphicsSubfolder = "other";
    }
    return options;
}

const convertPhotoContentRecord = (record: WebsitePhotoContentType | Record<string, any>, page: string): WebsitePhotoContentType | null => {
    let dirty = false;
    let newRecord: WebsitePhotoContentType;
    if ("defaults" in record) {
        dirty = true;
        newRecord = { images: record.content, imageOptions: getImageOptions(page) };
    } else {
        newRecord = record as WebsitePhotoContentType;
    }
    for (let i = 0; i < newRecord.images.length; i++) {
        if ((newRecord.images[i] as Record<string, any>).youtube_id) {
            dirty = true;
            newRecord.images[i].stream_source = VideoStreamSource.youtube;
            newRecord.images[i].stream_id = (newRecord.images[i] as Record<string, any>).youtube_id;
            delete (newRecord.images[i] as Record<string, any>).youtube_id;
        }
    }
    return dirty ? newRecord : null;
}
const DashboardEditorContainer = styled.div`
    margin-top: 16px;
    h3 {
        font-size: 22px;
        text-align: center;
        margin: 4px;
    }
`
interface DashboardEditorProps {
    showTag: boolean;
    page: string;
    element: WebsiteTagRecord;
    handleChange: (editedContent: WebsiteTagRecord) => void;
    setSuppressNavButtons: (suppress: boolean) => void;
}
const DashboardEditor: React.FC<DashboardEditorProps> = (props) => {
    console.log("DashboardEditor props.element:", props.element)
    const handleGridChange = (record: Record<string, any>[]) => {
        console.log("handleGridChange:", record)
        const newContent = { ...(props.element.content as WebsiteRecordContentRecord), content: record };
        console.log("newContent:", newContent);
        console.log("inserting above data into:", props.element)
        props.handleChange({ ...props.element, content: newContent });
    }
    const handleTextEditorChange = (text: string) => {
        console.log("HANDLETEXTEEDITORCHANGE:", text);
        const newElement = deepcopy(props.element) as WebsiteTagRecord;
        (newElement.content as WebsiteTextContentType).text = text;
        console.log("CALLING PROPS.HandleChange:", props.handleChange, " with ", newElement)
        props.handleChange(newElement);
        console.log("EXITING HANDLETEXTEDITORCHANGE")
    }
    const handleEditorChange = (data: WebsiteTextListContentType | WebsiteTextPairContentType) => {
        const newElement = deepcopy(props.element) as WebsiteTagRecord;
        newElement.content = data;
        props.handleChange(newElement);
    }
    const handlePhotosChanged = (images: ImageRecord[]) => {
        const newElement = deepcopy(props.element) as WebsiteTagRecord;
        (newElement.content as WebsitePhotoContentType).images = images;
        props.handleChange(newElement);
    }

    /*
    // convert { name, label } column def records into FormFieldRecords for grid display on content_type=record
    const formatColumnDefs = (recordDefs: Record<string, any>[]): FormFieldRecord[] => {
        const defs = [] as FormFieldRecord[];
        recordDefs.forEach(def => {
            defs.push({ name: def.name, label: def.label, visible: true, allowEditing: true, align: FormFieldAlignType.left });
        });
        return defs;
    }
    */
    /*
        id: string;                 // must be unique if more than one set of images on page
     images: ImageRecord[];      // filenames should not have _f or _m inserted; it is inserted based on fileOptions.sizeDesignator
     // following is height of each ROW or width of each COLUMN
     maxDisplayImageSize?: number;      // defaults to 150px; if direction is column this is width of component; if direction is row this is width of each element
     maxEditorSize?: number;     // width or height of image+attributes, default to 600px
     imageOptions: ImageEditorOptions;
     uploadOptions?: ImageUploadOptions;
     direction?: string;  // row or column (default row)
     allowAddNewVideo?: boolean;             // see uploadOptions
     style?: Record<string, any>;
     fontSize?: number;  // defaults to 13px
     onChange: (images: ImageRecord[], id: string) => void;
     renameOnUpload?: (file: File) => string;
 */
    console.log("content:", props.element.content)
    return (
        <DashboardEditorContainer>
            {props.showTag && <h3>[{props.element.tag}]</h3>}
            {(() => {
                switch (props.element.content_type) {
                    case WebsiteContentType.text:
                        return <HtmlDomEditor infoPage={
                            {
                                content: (props.element.content as WebsiteTextContentType).text,
                                settings: { styles: (props.element.content as WebsiteTextContentType).styles, allowImages: false }
                            }
                        }
                            width={editorWidth} contentChanged={handleTextEditorChange}
                        />
                    case WebsiteContentType.textList:
                        return <TextListEditor tag={props.element.tag} content={props.element.content as WebsiteTextListContentType}
                            width={editorWidth} onChange={handleEditorChange} />
                    case WebsiteContentType.textPair:
                        return <TextPairEditor tag={props.element.tag} content={props.element.content as WebsiteTextPairContentType} onChange={handleEditorChange} />
                    case WebsiteContentType.photo:
                        const photoContent = props.element.content as WebsitePhotoContentType;
                        return (
                            <SamImageGrid
                                id={props.element.tag} maxDisplayImageSize={300} direction="row"
                                images={photoContent.images}
                                maxImages={props.page === "wholesale" ? 1 : 0}
                                onChange={handlePhotosChanged}
                                imageOptions={photoContent.imageOptions}
                                uploadOptions={
                                    {
                                        targetDomain: app.domain,
                                        uploadImageApiUrl: api.uploadImage
                                    }
                                }
                            />);
                    case WebsiteContentType.record:
                        const record = props.element.content as WebsiteRecordContentRecord;
                        return <AgGrid width="1200px" height="600px" dataSource={record.content} fields={record.column_defs}
                            allowDelete={true} allowInsert={true} allowEditing={true} gridChanged={handleGridChange} />
                    default:
                        return null;
                }
            })()}
        </DashboardEditorContainer>
    )
}
//-------------------------------------------------------
// hideAttributes={!controlRecord.allow_captions}
interface TextListEditorProps {
    content: WebsiteTextListContentType;       // string[]
    tag: string;
    width: number;
    onChange: (editedContent: WebsiteTextListContentType) => void;
}
const TextListEditor: React.FC<TextListEditorProps> = (props) => {
    const [dataSource, setDataSource] = React.useState<Record<string, any>[]>();

    //   console.log("TextListEditor:", props)
    React.useEffect(() => {
        const data = [] as Record<string, any>[];
        for (const item of props.content) {
            data.push({ item });
        }
        setDataSource(data);
    }, []);
    const columnDefs: FormFieldRecord[] = [{ type: FormFieldType.text, name: "item", align: FormFieldAlignType.center, allowEditing: true }];
    const handleGridChange = (data: Record<string, any>[]) => {
        const list = [] as string[];
        for (const record of data) {
            list.push(record.item);
        }
        props.onChange(list);
    }
    return (
        <>
            {dataSource &&
                <AgGrid width="500px" height="500px" dataSource={dataSource} fields={columnDefs} allowDelete={true} allowInsert={true} allowEditing={true}
                    gridChanged={handleGridChange}
                />
            }
        </>
    )
}
//-------------------------------------------------------
interface TextPairEditorProps {
    content: { label: string; text: string }[];
    tag: string;
    onChange: (editedContent: WebsiteTextPairContentType) => void;
}
const TextPairEditor: React.FC<TextPairEditorProps> = (props) => {
    const columnDefs: FormFieldRecord[] = [
        { type: FormFieldType.text, name: "label", label: "Label", allowEditing: true },
        { type: FormFieldType.text, name: "text", label: "Text", allowEditing: true }
    ];
    return (
        <AgGrid width="800px" height="600px" dataSource={props.content} fields={columnDefs} allowDelete={true} allowInsert={true} allowEditing={true}
            gridChanged={data => props.onChange(data as WebsiteTextPairContentType)}
        />
    )
}
//-------------------------------------------------------
const LoginContainer = styled.div`
    text-align: center;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
`
const DashLogin: React.FC = () => {
    const { setToken } = useTokens();
    const { post, isPostLoading } = usePostApi();
    const { setSessionStore } = useSessionStore();

    const [errorMsg, setErrorMsg] = React.useState<string | null>(null);

    const successfulPost = (result: TokenRecord, status: number | undefined) => {
        // api returns result.token and result.order
        if (status === 201) {
            setErrorMsg("User name and password not found");
        } else {
            setToken(result, false);       // do not persist login
            console.log("history.push(/dashboard/home")
            window.location.href = "/dashboard/home";
        }
    }
    const failedPost = () => {
        setErrorMsg(genericApiError);
    }
    const handleSubmit = (inputs: Record<string, any> | null) => {
        if (inputs) {
            post(api.login + app.domain, inputs, successfulPost, failedPost);
        }
    }
    const formData: FormFieldRecord[] = [
        { name: "userName", label: "Your user name", width: 48, validator: { required: true, maxLength: 50 } },
        { name: "password", label: "Your password", width: 48, validator: { isPassword: true, required: true, maxLength: 50 } },
    ];
    return (
        <LoginContainer>
            <h2>Sign in</h2>
            {isPostLoading(api.login + app.domain) && <i className="fa fa-spinner fa-spin spinner48"></i>}
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            <SamForm id="login" fields={formData} submitButtons={[{ id: "submit", caption: "Sign in", icon: "fas fa-check" }]}
                handleSubmit={handleSubmit} />
        </LoginContainer>
    );
}
//-------------------------------------------------------
const ChangePasswordContainer = styled.div`
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`

const DashChangePassword: React.FC = () => {
    const { getToken } = useTokens();
    const { post, isPostLoading } = usePostApi();
    const { setSessionStore } = useSessionStore();

    const [errorMsg, setErrorMsg] = React.useState<string | null>(null);

    const token = getToken()!.token;

    const successfulPost = () => {
        // api returns ok on success
        alert("User name and password have been changed");
    }
    const failedPost = () => {
        setErrorMsg(genericApiError);
    }
    const handleSubmit = (inputs: Record<string, any> | null) => {
        if (inputs) {
            post(api.changePassword, inputs, successfulPost, failedPost, token);
        }
    }
    const formData: FormFieldRecord[] = [
        { name: "newUserName", label: "New user name", width: 48, validator: { required: true, maxLength: 50 } },
        { name: "newPassword", label: "New password", width: 48, validator: { isPassword: true, required: true, maxLength: 50 } },
    ];
    return (
        <ChangePasswordContainer>
            <h2>Change User Name and Password</h2>
            {isPostLoading(api.changePassword) && <i className="fa fa-spinner fa-spin spinner48"></i>}
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            <SamForm id="changePassword" fields={formData} submitButtons={[{ id: "submit", caption: "Submit", icon: "fas fa-download" }]}
                handleSubmit={handleSubmit} />
        </ChangePasswordContainer>
    );
}

export default Dashboard;
